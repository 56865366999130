import { css } from '@emotion/css/macro';
import { useToggle } from '../hooks';
import { darkgrey } from '../../ui/Core/stylesheets/colors';
import { fade } from '../../ui/Core/stylesheets/keyframes';

const hiddenClass = css`
	position: absolute;
	z-index: 9999;
	background: white;
	border: 2px solid ${darkgrey};
	box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
	padding: 0.5rem;
	animation: ${fade} 0.2s;
	min-width: 150px;
`;

type HiddenDropdownProps = { className?: string; presentationElement: any; hiddenElement: any };

export default function HiddenDropdown({
	presentationElement,
	hiddenElement,
	className,
}: HiddenDropdownProps) {
	const { toggle, isOn } = useToggle();

	return (
		<div className={className}>
			{/*ugly, but should work*/}
			<div role="presentation" onClick={toggle}>
				{presentationElement}
			</div>
			{isOn && <div className={hiddenClass}>{hiddenElement}</div>}
		</div>
	);
}
