import React from 'react';
import { Edit } from '@mui/icons-material';
import { useAPI } from '../core/hooks';
import { IconButton } from '../core/components';
import ContactForm from '../contact/components/ContactForm';
import { useReportContext } from './context/ReportContext';
import MaterialFlyOut from '../flyout/components/MaterialFlyOut';
import { useAllowedMethods } from '../auth/hooks';

function InterviewedPersonEdit({ id, refresh, projectId, clientId, on, setOn }: any) {
	const { initialFetch, state } = useAPI({
		props: { query: 'Contact/View', qs: { projectId, contactId: id } },
	});
	const contact = state?.autodata?.data;

	const toggleOn = React.useCallback(() => {
		if (!contact) initialFetch();
		setOn(true);
	}, [contact, initialFetch, setOn]);

	const onSuccess = React.useCallback(() => {
		setOn(false);
		refresh();
	}, [refresh, setOn]);

	return (
		<>
			<IconButton onClick={toggleOn}>
				<Edit />
			</IconButton>
			<MaterialFlyOut open={on} onClose={onSuccess} title="Edit row">
				{!!contact && (
					<ContactForm
						projectId={projectId}
						clientId={clientId}
						contact={contact}
						onSuccess={onSuccess}
					/>
				)}
			</MaterialFlyOut>
		</>
	);
}

export default function InterviewedPersonEditWrapper({ id }: any) {
	const { 'Contact/UpdateContact': canEditInterviewAttendee } = useAllowedMethods();
	const { projectId, refresh, projectInfo } = useReportContext();
	const { clientId } = projectInfo;
	const [on, setOn] = React.useState(false);

	if (!projectId || !canEditInterviewAttendee) return null;

	return (
		<>
			<InterviewedPersonEdit
				id={id}
				refresh={refresh}
				projectId={projectId}
				clientId={clientId}
				on={on}
				setOn={setOn}
			/>
		</>
	);
}
