import React from 'react';
import { FormikConfig } from 'formik';
import { useDispatch } from 'react-redux';
import FormBase, { FormBaseProps } from './FormBase';
import { useAPI } from '../../../core/hooks';

export type FormaggioSubmitFn = FormikConfig<any>['onSubmit'];

export type FormaggioProps = FormBaseProps & {
	fetchConfig?: any;
	children: React.ReactNode;
	validate?: any;
	hasCustomErrors?: boolean;
	useQueryString?: boolean; //TODO: do something with fields instead of whole query
};

/**
 * Sends "application/json"
 * @constructor
 */
export default function BasicForm({
	onSubmit = () => undefined,
	children,
	fetchConfig,
	additional,
	noResetOnSuccess,
	hasCustomErrors,
	useQueryString,
	transformValues,
	...rest
}: FormaggioProps) {
	const dispatch = useDispatch();
	const { initialFetch: perform } = useAPI();

	const submit = React.useCallback<FormaggioSubmitFn>(
		(values, bag) => {
			if (hasCustomErrors) return;

			const allValues = { ...values, ...additional };
			const transformedValues = transformValues ? transformValues(allValues) : allValues;

			if (onSubmit) onSubmit(transformedValues, bag);
			if (fetchConfig)
				perform({
					...fetchConfig,
					dispatch,
					onSuccess: (result: any) => {
						if (fetchConfig.handleValuesOnSuccess)
							fetchConfig.handleValuesOnSuccess(transformedValues);
						if (fetchConfig.onSuccess) fetchConfig.onSuccess(result);
						if (!noResetOnSuccess) bag.resetForm();
					},
					[useQueryString ? 'qs' : 'params']: transformedValues,
				});
		},
		[
			onSubmit,
			fetchConfig,
			dispatch,
			additional,
			noResetOnSuccess,
			hasCustomErrors,
			useQueryString,
			perform,
			transformValues,
		],
	);

	return (
		<FormBase {...rest} onSubmit={submit}>
			{children}
		</FormBase>
	);
}
