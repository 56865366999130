export enum ImplementationStatus {
	Implemented = 1,
	PartiallyImplemented = 2,
	Planned = 4,
	AlternativeImplementation = 8,
	NotApplicable = 16,
}

export enum ControlOrigination {
	ServiceProviderCorporate = 1,
	ServiceProviderSystemSpecific = 2,
	ServiceProviderHybrid = 4,
	ConfiguredByCustomer = 8,
	ProvidedByCustomer = 16,
	Shared = 32,
	InheritedFromExistingFedRampAuthorization = 64,
}
