import { css } from '@emotion/css/macro';
import { Chip } from '@mui/material';
import OptionalControls from './OptionalControls';
import { useReportContext } from './context/ReportContext';
import { useItemContext } from './context/ItemContext';

const rootClass = css`
	position: absolute;
	top: 0px;
	right: 0px;
	display: flex;
	z-index: 1;
`;

type OverlayPanelProps = { isOn?: boolean };

export default function OverlayPanel({ isOn }: OverlayPanelProps) {
	const { filteredItemIds, showCopyLink, projectId, refresh } = useReportContext();
	const { itemId } = useItemContext();

	if (!itemId) return null;

	return (
		<div className={rootClass}>
			<OptionalControls
				showCopyLink={showCopyLink}
				modelId={projectId}
				itemId={itemId}
				show={isOn}
				refresh={refresh}
			/>
			{filteredItemIds?.includes(itemId) && (
				<Chip size="small" color="primary" label="Filter Match" />
			)}
		</div>
	);
}
