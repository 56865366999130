import React from 'react';

export function useKeyListener(key: string, listener: any) {
	React.useEffect(() => {
		window.addEventListener(key, listener);

		return () => {
			window.removeEventListener(key, listener);
		};
	}, [key, listener]);
}
