import { stringify } from 'query-string';
import { DownloadState } from './constants';

type DownloadRequestProps = {
	query: string;
	setDownloadState: (state: DownloadState) => void;
	authTokenKey: string;
	replaceEndpoint?: (path: string) => string;
	fileName?: string;
	qs?: any;
};

function getFileNameFromHeader(disposition: string) {
	if (disposition && disposition.indexOf('attachment') !== -1) {
		const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
		const matches = filenameRegex.exec(disposition);
		if (matches != null && matches[1]) {
			return matches[1].replace(/['"]/g, '');
		}
	}

	return 'TurboQsa_filedownload';
}

export function downloadRestrictedFile({
	query,
	fileName,
	setDownloadState,
	authTokenKey,
	qs,
}: DownloadRequestProps) {
	setDownloadState(DownloadState.Downloading);
	const path = `api/${query}`;
	fetch(path + (qs ? '?' + stringify(qs) : ''), {
		method: 'GET',
		headers: { Authorization: `Bearer ${localStorage.getItem(authTokenKey)}` },
	})
		.then((response) => {
			let downloadFilename = fileName;

			if (!downloadFilename) {
				const disposition = response.headers.get('Content-Disposition');
				downloadFilename = getFileNameFromHeader(disposition);
			}

			if (response.status !== 200) {
				response.text().then((responseText) => {
					// eslint-disable-next-line no-alert
					alert(
						`Unable to download file - Error ${response.status} - ${response.statusText}\n${responseText}`,
					);
					setDownloadState(DownloadState.Error);
				});
				return;
			}

			response.blob().then((blob) => {
				const a = document.createElement('a');
				a.href = window.URL.createObjectURL(blob);
				a.download = downloadFilename;
				a.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
				setDownloadState(DownloadState.Idle);
			});
		})
		.catch(() => {
			setDownloadState(DownloadState.Error);
		});
}
