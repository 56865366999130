import React from 'react';
import { useQuery } from '@apollo/client';
import { useSearch } from '../search/hooks';
import { userSearchInitialValues } from './components/UserSearchFilter';
import {
	GetUsersOfProjectQuery,
	GetUsersOfProjectQueryVariables,
	InterviewAttendeeModel,
} from '../../graphql/typescript';
import { GET_USERS_OF_PROJECT_QUERY } from '../../graphql/domains/project/queryList';
import { InterviewAttendeeType } from '../core/types/api';

export function useUserSearch() {
	const { state, ...rest } = useSearch(
		{
			query: 'Account/ViewUsers',
		},
		userSearchInitialValues,
	);

	return {
		list: state?.data?.data,
		pagesCount: state?.data?.pagesCount,
		...rest,
	};
}

export function useContactAndUserList({
	projectId,
	responsibilityArea,
	isResponsibilityAreaOn,
}: {
	projectId?: string;
	responsibilityArea?: number;
	isResponsibilityAreaOn?: boolean;
}) {
	const { data: userData, refetch } = useQuery<
		GetUsersOfProjectQuery,
		GetUsersOfProjectQueryVariables
	>(GET_USERS_OF_PROJECT_QUERY, {
		variables: {
			projectId,
			includeContacts: true,
			area: responsibilityArea && isResponsibilityAreaOn ? responsibilityArea : undefined,
		},
	});
	const allList: InterviewAttendeeModel[] | undefined = userData?.list?.data;
	const list = React.useMemo(() => {
		if (!allList) return null;

		const defList = allList.map((u) => ({
			...u,
			isUser: u.attendeeType === InterviewAttendeeType.User,
		}));

		return defList.sort((a, b) => a.name.localeCompare(b.name));
	}, [allList]);

	return { list, refetchContacts: refetch };
}
