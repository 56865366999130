import gql from 'graphql-tag';

export default gql`
	query getClientUsersOfProject($projectId: String!) {
		list: get_api_Client_ViewClientUsersOfProject_projectId(projectId: $projectId)
			@rest(path: "Client/ViewClientUsersOfProject/{args.projectId}") {
			data {
				id
				firstName
				lastName
			}
		}
	}
`;
