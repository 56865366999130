import React from 'react';
import { connect } from 'formik';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	FormLabel,
} from '@mui/material';
import { ErrorMessage } from '../../core/components';
import { FormaggioInputProps } from '../types';
import { hasFlag } from '../../core/helpers';

type CheckboxGroupFieldProps = FormaggioInputProps & {
	changeValue?: (next: number) => void;
	onChange?: any;
	options: { label: string | React.ReactNode; value: number; disabled?: boolean }[];
	value?: number;
	onBlur?: any;
	setFieldValue?: any;
};

export function PureCheckboxGroupField({
	name,
	onChange,
	value,
	label,
	helperText,
	errorMessage,
	options,
	onBlur,
	changeValue,
}: CheckboxGroupFieldProps) {
	const handleChange = React.useCallback(
		(e) => {
			const newValue = (value || 0) + e.target.value * (e.target.checked ? 1 : -1);

			if (changeValue) changeValue(newValue);
			if (onChange)
				onChange({
					...e,
					target: {
						...e.target,
						name,
						value: newValue,
					},
				});
		},
		[changeValue, name, onChange, value],
	);

	const optionsRender = React.useMemo(
		() =>
			options &&
			options.map((o) => (
				<FormControlLabel
					control={
						<Checkbox name={name} inputProps={{ 'data-testid': `${name}-${o.value}` } as any} />
					}
					label={o.label}
					checked={hasFlag(value, o.value)}
					key={o.value}
					disabled={o.disabled}
					value={o.value}
				/>
			)),
		[name, options, value],
	);

	return (
		<FormControl error={!!errorMessage} onBlur={onBlur}>
			{label && <FormLabel>{label}</FormLabel>}
			<FormGroup onChange={handleChange}>{optionsRender}</FormGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			<ErrorMessage message={errorMessage} />
		</FormControl>
	);
}

function CheckboxGroupField({ formik, name, ...rest }: Omit<CheckboxGroupFieldProps, 'onChange'>) {
	return (
		<PureCheckboxGroupField
			onChange={formik.handleChange}
			storage={formik.values}
			onBlur={formik.handleBlur}
			errorMessage={formik.errors[name]}
			name={name}
			value={formik.values[name]}
			{...rest}
		/>
	);
}

export default connect(CheckboxGroupField);
