import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GraphQLClient } from './apollo';

type ApolloClientProviderProps = { children: React.ReactNode };

export default function ApolloClientProvider({ children }: ApolloClientProviderProps) {
	const dispatch = useDispatch();

	const client = React.useMemo(() => GraphQLClient(dispatch), [dispatch]);

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
