export {
	RocWarningType,
	SearchEntities,
	AssessmentDocumentType,
	ItemStatus,
	ReportGroup as CreateReportGroup,
	ModelType as ProjectType,
} from '../core/types/api';

export enum DownloadFileType {
	Word = 'w',
	Excel = 'x',
	Zip = 'z',
}

export enum CreateReportStatus {
	NotStarted,
	Processing,
	Failed,
	Succeeded,
}

export enum RocWarningLevel {
	Suggestion = 0,
	Warning = 1,
	Error = 2,
	Critical = 4,
}
