import React from 'react';
import { SelectProps } from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { css } from '@emotion/css/macro';
import { SelectOption } from './Select';
import { searchTextFilter } from '../../../shared/helpers';

const emptyOption = { value: '', title: '' };

function renderOption(props: any, { value, title }: any) {
	return (
		<li {...props} key={value}>
			{title}
		</li>
	);
}

function getOptionLabel({ title }: any) {
	return title || '';
}

function filterOptions(options: any[], state: any) {
	return options.filter(
		({ title }) => title !== emptyOption.title && searchTextFilter(title, state.inputValue),
	);
}

const inputClass = css`
	min-width: 100px !important;
	padding-bottom: 5px !important;
`;

export type NewSelectProps = SelectProps & {
	options: SelectOption[];
	noEmpty?: boolean;
	onChange: any;
};

export default function SelectSearchable({
	options = [],
	multiple,
	onChange,
	id,
	label,
	name,
	value,
	className,
	noEmpty,
}: NewSelectProps) {
	const processedOptions = React.useMemo(() => [emptyOption, ...options], [options]);

	const handleChange = React.useCallback(
		(_, value: any) => {
			const newValue = value?.value || '';
			onChange({ target: { name, value: newValue } });
		},
		[name, onChange],
	);

	const inputValue = React.useMemo(
		() => processedOptions.filter((option) => option.value === value)[0] || emptyOption,
		[processedOptions, value],
	);

	if (options.length === 0) return null;

	return (
		<Autocomplete
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					InputProps={
						{
							...params.InputProps,
							'data-testid': id,
							className: inputClass,
							type: 'search',
						} as any
					}
				/>
			)}
			renderOption={renderOption}
			getOptionLabel={getOptionLabel}
			options={processedOptions}
			multiple={multiple}
			onChange={handleChange}
			value={inputValue}
			className={className}
			disableClearable={noEmpty}
			openOnFocus
			filterOptions={filterOptions}
		/>
	);
}
