import gql from 'graphql-tag';

export default gql`
	query getChannelsList($rocModelId: String!) {
		list: get_api_Channels_view_rocModelId(rocModelId: $rocModelId)
			@rest(path: "Channels/view/{args.rocModelId}") {
			data {
				id
				name
				description
				type
				modelType
				saq {
					basedOn
					notApplicableReason
					customSaqId
				}
			}
		}
	}
`;
