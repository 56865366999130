import { stringify } from 'query-string';
import { addMonths, addYears, format } from 'date-fns';
import { toSelectOptions } from '../formaggio';
import { ITEM_HIGHLIGHT_QS_KEY } from '../rocTemplates/LinkButton';
import { Channel, ReportType } from '../core/types/api';
import { ProjectSaqInfo } from './types';
import {
	defaultProjectFilter,
	PROJECT_FILTER_KEY,
	ProjectDateFilter,
	projectModelTypes,
} from './constants';
import { TIME_FORMAT } from '../core/constants/time';
import { ReportFilterType } from '../rocTemplates/filtering/reportFilterStorage';
import { ProjectFilterSharedProps } from './components';
import { AssessmentDocumentType } from './enums';
import { isFedRampVersion, isPciDssVersion } from '../rocTemplates/helpers';

export function projectsToSelectOptions(projects: { id: string; name: string }[]) {
	return toSelectOptions(projects, 'id', 'name');
}

/**
 * Since this helper is for internal navigation, use it only with RouterLink
 */
export function idsToProjectPath(projectId = '', containerId?: string, params?: string | object) {
	const query =
		typeof params === 'object' ? stringify(params) : stringify({ [ITEM_HIGHLIGHT_QS_KEY]: params });
	const containerIdPath = containerId ? `/${containerId}` : '';

	return `/project/${projectId}${containerIdPath}?${query}`;
}

export function projectSaqInfo(paymentChannels: Channel[] = []): ProjectSaqInfo {
	const saq = paymentChannels.reduce(
		(memo: number[], { saq = { basedOn: 0 } }: Channel) =>
			saq?.customSaqId || memo.indexOf(saq?.basedOn || 0) >= 0
				? memo
				: [...memo, saq?.basedOn || 0],
		[],
	);

	const customSaq =
		paymentChannels
			.map(({ saq }: Channel) => saq?.customSaqId || '')
			.filter((customSaqId) => !!customSaqId) || [];

	return {
		saq,
		customSaq,
		hasAny: saq.indexOf(0) < 0 && (saq.length > 0 || customSaq.length > 0),
	};
}

// Project date filters

export const projectCreatedLastQuarter = format(
	addMonths(new Date(), -3),
	TIME_FORMAT.STANDARD_DATE_ONLY,
);

export const projectCreatedLastYear = format(
	addYears(new Date(), -1),
	TIME_FORMAT.STANDARD_DATE_ONLY,
);

export function getDateFromProjectDateFilter(value: ProjectDateFilter) {
	switch (value) {
		case ProjectDateFilter.LastQuarter:
			return projectCreatedLastQuarter;
		case ProjectDateFilter.LastYear:
			return projectCreatedLastYear;
		default:
			return undefined;
	}
}

export function setStoredProjectFilter(value: any) {
	localStorage.setItem(PROJECT_FILTER_KEY, JSON.stringify(value));
}

export function getStoredProjectFilter() {
	const filter = localStorage.getItem(PROJECT_FILTER_KEY);

	const parsedFilter = filter ? JSON.parse(filter) : defaultProjectFilter;

	parsedFilter.createdAfter = getDateFromProjectDateFilter(parsedFilter.dateFilter);

	return parsedFilter;
}

export function isReportFilterNotActive({
	evidenceArea,
	interviewArea,
	saqEnabled,
	isWithUnexpiredRequests,
	isWithRequestsTargetedAtMe,
	SAQExists,
	itemStatus,
	dateFrom,
	dateUntil,
	isWithoutInput,
	isWithCCW,
	isWithIncompleteCCW,
	isWithCustomApproach,
	summary,
	statusQS,
	warningQS,
	clientResponsibilities,
}: ReportFilterType & ProjectFilterSharedProps) {
	return (
		!evidenceArea &&
		!interviewArea &&
		(!SAQExists || !saqEnabled) &&
		!itemStatus &&
		!isWithUnexpiredRequests &&
		!isWithRequestsTargetedAtMe &&
		!dateFrom &&
		!dateUntil &&
		!isWithoutInput &&
		!isWithCCW &&
		!isWithIncompleteCCW &&
		!isWithCustomApproach &&
		summary === undefined &&
		!statusQS &&
		!warningQS &&
		!clientResponsibilities
	);
}

export function getDefaultReportType(version: ReportType): AssessmentDocumentType {
	if (isPciDssVersion(version))
		// PCI DSS 3.2.1 or 4.0
		return AssessmentDocumentType.ReportOnCompliance;
	if (isFedRampVersion(version))
		// FedRAMP v5
		return AssessmentDocumentType.InitialAuthorizationPackageChecklist;
	return AssessmentDocumentType.ReportOnCompliance; // Return ROC if unsure (will fail)
}

export function getProjectModelType(version: ReportType) {
	return projectModelTypes[version];
}
