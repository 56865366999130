export type ConfigType = {
	aspnetcoreEnvironment?: string;
	buildInfo?: string;
	version?: string;
};

export const CONFIG: ConfigType = Object.freeze(window.MQSA_CONFIG || {});

export const isTesting = CONFIG.aspnetcoreEnvironment === 'Testing';
export const isDevelopment = CONFIG.aspnetcoreEnvironment === 'Development';
export const isStaging = CONFIG.aspnetcoreEnvironment === 'Staging';
export const isProduction = CONFIG.aspnetcoreEnvironment === 'Production';
