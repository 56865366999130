import React from 'react';
import { css } from '@emotion/css/macro';
import TextField from '@material-ui/core/TextField';
import { wrapTextWithText } from './helpers';

const buttonClass = css`
	margin: 0 0.25rem 0 0;
`;

function ControlButton({ children, ...rest }: JSX.IntrinsicElements['button']) {
	return (
		<button type="button" className={buttonClass} {...rest}>
			{children}
		</button>
	);
}

const panelClass = css`
	padding: 0.25rem 0;
`;

type HTMLFieldProps = { onChange: any; name: string; storage: any }; //later

export default function HTMLField({ onChange, name, storage }: HTMLFieldProps) {
	const ref = React.useRef<HTMLTextAreaElement>(null);

	const change = React.useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			onChange({ name, value: e.target.value });
		},
		[name, onChange],
	);

	const wrap = React.useCallback(
		(startElem, endElem) => {
			if (ref.current) {
				onChange({ name, value: wrapTextWithText(ref.current, startElem, endElem) });
			}
		},
		[onChange, name],
	);

	const wrapBold = React.useCallback(() => wrap('<b>', '</b>'), [wrap]);
	const wrapItalic = React.useCallback(() => wrap('<em>', '</em>'), [wrap]);
	const wrapUnderline = React.useCallback(() => wrap('<u>', '</u>'), [wrap]);
	const wrapParagraph = React.useCallback(() => wrap('<p>', '</p>'), [wrap]);
	const wrapUl = React.useCallback(() => wrap('<ul>', '</ul>'), [wrap]);
	const wrapDashedUl = React.useCallback(() => wrap('<ul class="rocDashList">', '</ul>'), [wrap]);
	const wrapLi = React.useCallback(() => wrap('<li>', '</li>'), [wrap]);
	const wrapNote = React.useCallback(() => wrap('<div class="rocNote">', '</div>'), [wrap]);

	return (
		<>
			<div className={panelClass}>
				<ControlButton onClick={wrapBold}>
					<b>B</b>
				</ControlButton>
				<ControlButton onClick={wrapItalic}>
					<em>I</em>
				</ControlButton>
				<ControlButton onClick={wrapUnderline}>
					<u>U</u>
				</ControlButton>
				<ControlButton onClick={wrapParagraph}>p</ControlButton>
				<ControlButton onClick={wrapUl}>ul</ControlButton>
				<ControlButton onClick={wrapDashedUl}>dashed ul</ControlButton>
				<ControlButton onClick={wrapLi}>li</ControlButton>
				<ControlButton onClick={wrapNote}>note</ControlButton>
			</div>
			<TextField
				fullWidth
				multiline
				variant="outlined"
				inputRef={ref}
				onChange={change}
				value={storage ? storage[name] || '' : ''}
				name={name}
			/>
		</>
	);
}
