import React from 'react';
import { Star } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import { highlight } from '../../ui/Core/stylesheets/colors';

export default function PrimaryContactStar({
	children,
	isTrue,
}: {
	children?: React.ReactNode;
	isTrue?: boolean;
}) {
	return isTrue ? (
		<Box display="flex" alignItems="center">
			<Tooltip title="Executive / Primary">
				<Star htmlColor={highlight} style={{ marginRight: '8px' }} />
			</Tooltip>
			{children}
		</Box>
	) : (
		<>{children}</>
	);
}
