import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useApolloClient } from '@apollo/client';
import { css, cx } from '@emotion/css/macro';
import MaterialDialog from '../flyout/components/MaterialDialog';
import { Button } from '../core/components';
import { useAPI } from '../core/hooks';
import { error, mainDark } from '../ui/Core/stylesheets/colors';
import { GET_COMPENSATING_CONTROL_QUERY } from '../../graphql/domains/compensatingControl/queryList';
import { SimpleTable } from '../../shared/components';
import { useReportContext } from './context/ReportContext';
import { useItemContext } from './context/ItemContext';
import { ItemStatus } from '../project/enums';

type CompensatingControlKeys =
	| 'constraints'
	| 'objective'
	| 'identifiedRisk'
	| 'definition'
	| 'validation'
	| 'maintenance'
	| 'disclosureSummary';

type CompensatingControl = Partial<Record<CompensatingControlKeys, string>>;

type CompensatingControlRowType = {
	name: string;
	key: CompensatingControlKeys;
	infoRequired: string;
};

const headers = ['', 'Information Required', 'Explanation'];

const rows = [
	{
		name: 'Disclosure Summary',
		key: 'disclosureSummary',
		infoRequired: 'Summary of the issue (legal obligation, etc.)',
	},
	{
		name: 'Constraints',
		key: 'constraints',
		infoRequired: 'List constraints precluding compliance with the original requirement.',
	},
	{
		name: 'Objective',
		key: 'objective',
		infoRequired:
			'Define the objective of the original control; identify the objective met by the compensating control.',
	},
	{
		name: 'Identified Risk',
		key: 'identifiedRisk',
		infoRequired: 'Identify any additional risk posed by the lack of the original control.',
	},
	{
		name: 'Definition of Compensating Controls',
		key: 'definition',
		infoRequired:
			'Define the compensating controls and explain how they address the objectives of the original control and the increased risk, if any.',
	},
	{
		name: 'Validation of Compensating Controls',
		key: 'validation',
		infoRequired: 'Define how the compensating controls were validated and tested.',
	},
	{
		name: 'Maintenance',
		key: 'maintenance',
		infoRequired: 'Define process and controls in place to maintain compensating controls.',
	},
];

const defaultCC: CompensatingControl = {
	disclosureSummary: '',
	constraints: '',
	objective: '',
	identifiedRisk: '',
	definition: '',
	validation: '',
	maintenance: '',
};

const headerBackground = css`
	color: white !important;
	background: ${mainDark};
`;

const headerWidth = css`
	width: 25%;
`;

const headerClasses = [
	cx(headerBackground, headerWidth),
	cx(headerBackground, headerWidth),
	cx(headerBackground),
];

type CompensatingControlTableProps = { lastColumn: any };

export function CompensatingControlTable({ lastColumn }: CompensatingControlTableProps) {
	const cols = React.useMemo(() => ['name', 'infoRequired', lastColumn], [lastColumn]);

	return (
		<SimpleTable
			rows={rows}
			cols={cols}
			keyValue="key"
			headers={headers}
			headerClassNames={headerClasses}
		/>
	);
}

type CompensatingControlProps = {
	isOpen: boolean;
	onClose: () => void;
	isEdit?: boolean;
	initialCC?: Record<string, unknown>;
	refetchCC?: () => void;
	ccwOn: () => void;
	ccwOff?: () => void;
	setDisclosureValue: any;
};

export function CompensatingControlDialog({
	isOpen,
	onClose,
	isEdit,
	initialCC = defaultCC,
	refetchCC,
	ccwOn,
	ccwOff,
	setDisclosureValue,
}: CompensatingControlProps) {
	const { projectId, refresh } = useReportContext();
	const { itemId, status } = useItemContext();
	const { fetchAPI } = useAPI({});

	const apollo = useApolloClient();

	const [CC, setCC] = React.useState<CompensatingControl>(initialCC);

	const changeField = React.useCallback(
		(e) => {
			e.persist();
			setCC((prevCC: Record<string, unknown>) => ({
				...prevCC,
				[e.target.name]: String(e.target.value),
			}));
		},
		[setCC],
	);

	const editableColumn = ({ key }: CompensatingControlRowType) => {
		const value = CC[key] || '';
		return (
			<TextField
				value={value}
				error={!value}
				style={{ minWidth: '200px' }}
				name={key}
				onChange={changeField}
				variant="outlined"
				fullWidth
				multiline
			/>
		);
	};

	const submit = () => {
		fetchAPI({
			query: `CompensatingControls/${
				isEdit ? 'edit' : 'create'
			}CompensatingControl/${projectId}/${itemId}`,
			method: 'POST',
			params: CC,
			onSuccess: () => {
				apollo.writeQuery({
					query: GET_COMPENSATING_CONTROL_QUERY,
					variables: { modelId: projectId, rocItemId: itemId },
					data: { control: { data: CC } },
				});
				onClose();
				ccwOn();
				setDisclosureValue(CC.disclosureSummary);
				if (refetchCC) refetchCC();
				if (refresh) refresh();
			},
		});
	};

	const remove = React.useCallback(
		() =>
			fetchAPI({
				query: `CompensatingControls/deleteCompensatingControl/${projectId}/${itemId}`,
				method: 'POST',
				confirmation: true,
				onSuccess: () => {
					onClose();
					if (ccwOff) ccwOff();
					if (refresh) refresh();
				},
			}),
		[fetchAPI, projectId, itemId, onClose, ccwOff, refresh],
	);

	return (
		<MaterialDialog
			isOpen={isOpen}
			title={`${isEdit ? 'View' : 'Create'} Compensating Control`}
			actions={
				<>
					{isEdit && status !== ItemStatus.Approved && (
						<Button onClick={remove} style={{ background: error, color: 'white' }}>
							Remove
						</Button>
					)}
					{status !== ItemStatus.Approved && (
						<Button onClick={submit} color="primary">
							{isEdit ? 'Save' : 'Submit'}
						</Button>
					)}
					<Button onClick={onClose} color="secondary">
						Cancel
					</Button>
				</>
			}
			onClose={onClose}
		>
			<CompensatingControlTable lastColumn={editableColumn} />
		</MaterialDialog>
	);
}
