import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Box } from '@mui/material';
import Data from '../UserData';
import { AocSummaryOfRequirementsState } from '../enums';
import { retrieveSummaryValue } from './helper';

function SummaryOfRequirementsInput({ value }: any) {
	const summaryValue = value?.value;

	return (
		<Box display="flex" flexDirection="row" justifyContent="space-evenly">
			<FormControlLabel
				disabled
				control={<Checkbox checked={summaryValue === AocSummaryOfRequirementsState.Compliant} />}
				label="Yes"
			/>
			<FormControlLabel
				disabled
				control={<Checkbox checked={summaryValue === AocSummaryOfRequirementsState.NotCompliant} />}
				label="No"
			/>
		</Box>
	);
}

export default function SummaryOfRequirements({ ...rest }: any) {
	return (
		<Box>
			<Data
				retrievedValue={retrieveSummaryValue}
				inputElement={SummaryOfRequirementsInput}
				instantSave
				{...rest}
			/>
		</Box>
	);
}
