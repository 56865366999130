import React from 'react';

export function useBackdropClickHandler(onClose: (event, reason) => any) {
	return React.useCallback(
		(event, reason) => {
			if (reason !== 'backdropClick') onClose(event, reason);
		},
		[onClose],
	);
}
