import AttachmentInput from './AttachmentInput';
import ProjectObservationEvidence from '../../../attachments/observationEvidence/ProjectObservationEvidence';
import ObservationEvidenceData from '../../../attachments/observationEvidence/ObservationEvidenceData';

type ObservationEvidenceInputProps = any;

export default function ObservationEvidenceInput({ ...props }: ObservationEvidenceInputProps) {
	return (
		<AttachmentInput
			ProjectAttachmentsComponent={ProjectObservationEvidence}
			AttachmentDataComponent={ObservationEvidenceData}
			attachmentName="observation"
			attachmentPluralName="observations"
			{...props}
		/>
	);
}
