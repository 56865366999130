import gql from 'graphql-tag';

export default gql`
	query searchByNumbering($projectId: String!, $numbering: String!) {
		list: get_api_RocItemControllerNew_searchItem_rocModelId(
			rocModelId: $projectId
			numbering: $numbering
		) @rest(path: "RocItemControllerNew/searchItem/{args.rocModelId}?numbering={args.numbering}") {
			data {
				itemId
				numbering
				name
				chapterId
			}
		}
	}
`;
