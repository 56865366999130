import { SvgIconComponent } from '@mui/icons-material';
import { rocQaColorCoding } from '../project/rocQa';
import { RocWarningLevel } from '../project/enums';
import Counter from '../core/components/Counter';

export type RocWarningStatusIconProps = {
	Icon: SvgIconComponent;
	warningLevel: RocWarningLevel;
	warningsCount: number;
};

export default function RocWarningStatusIcon({
	Icon,
	warningLevel,
	warningsCount,
}: RocWarningStatusIconProps) {
	return (
		<Counter
			color={rocQaColorCoding[warningLevel].color}
			count={warningsCount}
			marginLeft="4px"
			counterProps={{ marginLeft: '4px' }}
		>
			<Icon fontSize="small" htmlColor={rocQaColorCoding[warningLevel].color} />
		</Counter>
	);
}
