import asyncRequest, { RequestProps } from './asyncRequest';
import { setLoading } from '../../loading/reducers';
import { ReduxDispatch } from './types';
import globalFetchHandler from './globalFetchHandler';
import { ConfirmationParams } from '../../confirmation/context';

type ConfirmationResult = ConfirmationParams | boolean;

export type FetchOptions = Partial<{
	successMessage: boolean;
	confirmation: ConfirmationResult | ((state: any) => ConfirmationResult);
	errorMessage: boolean;
	autoCloseError: boolean;
	onResponse: (args: any) => void;
	onSuccess: (args: any) => void;
	onError: (args: object) => void;
	url?: string;
}>;

export type FetchAPIProps<PT = any, QST = any> = {
	queryId?: string;
	setState?: (args: object) => void;
	dispatch: ReduxDispatch;
} & FetchOptions &
	Partial<RequestProps<PT, QST>>;

export type FetchAPI = (args: Partial<FetchAPIProps>) => void;

export default async function fetchAPI({
	query = '',
	method,
	params,
	qs,
	useFormData,
	queryId = '',
	onResponse = () => undefined,
	onSuccess = () => undefined,
	onError = () => undefined,
	successMessage,
	errorMessage = true,
	setState,
	dispatch,
}: FetchAPIProps) {
	if (setState) setState({ loading: true });
	dispatch(setLoading(true));
	const result = await asyncRequest({ query, method, params, qs, useFormData });
	if (!result) return null;
	if (
		!globalFetchHandler({
			result,
			dispatch,
			fetchOptions: { onResponse, onSuccess, onError, errorMessage, successMessage, url: query },
		}).continueRequest
	)
		return null;
	if (setState) {
		setState({ loading: false });
		if (!result.errors)
			setState({
				[`${queryId}data`]: result,
			});
		else
			setState({
				[`${queryId}errors`]: result.errors,
			});
	}
	return null;
}
