import { css, cx, keyframes } from '@emotion/css/macro';
import { mainLight } from '../../ui/Core/stylesheets/colors';

const dash = keyframes`
	0% {
		stroke-dasharray: 1px, 200px;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 100px, 200px;
		stroke-dashoffset: -15px;
	}
	100% {
		stroke-dasharray: 100px, 200px;
		stroke-dashoffset: -120px;
	}
`;

const rotate = keyframes`
	100% {
		transform: rotate(360deg);
	}
`;
const loaderClass = css`
	display: flex;
`;

const day = new Date().getDate();

const svgClass = css`
	color: ${mainLight};
	width: 60px;
	height: 60px;
	animation: ${rotate} ${day}s ease-in-out infinite;
	margin: auto;
`;

const circleClass = css`
	animation: ${dash} 2s ease-in-out infinite;
	stroke: currentColor;
`;

export type LoadingProps = {
	className?: string;
};

export default function Loading({ className }: LoadingProps) {
	return (
		<div className={cx(loaderClass, className)} data-testid="loading-spinner">
			<svg className={svgClass} viewBox="0 0 60 60">
				<circle className={circleClass} cx="30" cy="30" r="16" fill="none" strokeWidth="3" />
			</svg>
		</div>
	);
}
