export enum UploadStates {
	Waiting,
	Uploading,
	Complete,
	Error,
}

export enum DownloadState {
	Idle,
	Downloading,
	Error,
}
