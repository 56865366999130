import { stringify } from 'query-string';
// TODO mocks should be loaded dynamically so that they are'nt present in production code
//import mocks from '../utils/mocks';
import { AUTH_TOKEN_KEY } from '../../auth/constants';
import { objectToFormData } from '../../../shared/helpers/formDataConverter';

const timeoutResponse = { response: null, status: 408 };
const CONNECTION_TIMEOUT = 10 * 60000;

export type RequestProps<PT = any, QST = any> = {
	query: string;
	method?: 'PUT' | 'POST' | 'GET' | 'DELETE';
	params?: PT;
	qs?: QST;
	useFormData?: boolean;
};

function fetchPolyfill({ query = '', method = 'GET', params, qs, useFormData }: RequestProps) {
	return new Promise((resolve) => {
		const req = new XMLHttpRequest();
		const url = `api/${query}${qs ? `?${stringify(qs)}` : ''}`;
		req.open(method, url);
		if (!useFormData) req.setRequestHeader('Content-Type', 'application/json');
		const auth = localStorage.getItem(AUTH_TOKEN_KEY);
		if (auth) req.setRequestHeader('Authorization', `Bearer ${auth}`);
		req.onabort = () => resolve(timeoutResponse);
		req.onload = () => resolve(req);
		req.ontimeout = () => resolve(timeoutResponse);
		setTimeout(() => resolve(timeoutResponse), CONNECTION_TIMEOUT);

		let convertedParams;
		if (params) convertedParams = useFormData ? objectToFormData(params) : JSON.stringify(params);

		req.send(convertedParams);
	});
}

export type StatusResult = {
	status: 200 | 401 | 403 | 408 | 500;
	errors?: {
		[k: string]: string;
	};
	success?: boolean;
};

export type SuccessResultList<T extends any[] = any> = StatusResult & {
	data: T;
};

export type SuccessResultObject<T = any> = StatusResult & {
	data: T;
};

export type SuccessResult<T> = T extends any[] ? SuccessResultList<T> : SuccessResultObject<T>;

export default async function asyncRequest<T = any>({
	query,
	method,
	params,
	qs,
	useFormData,
}: RequestProps): Promise<SuccessResult<T>> {
	/*let cleanQuery = query;
	if (query && query[0] === '/') {
		cleanQuery = query.substr(1);
	}
	if (process.env.STORYBOOK_MOCK) {
		if (typeof mocks[cleanQuery] === 'function') {
			const data: any = mocks[cleanQuery]() || {};
			const mockResult: SuccessResult<T> = { ...data, status: 200 };
			return mockResult;
		}
	}*/
	let result: any;
	result = await fetchPolyfill({ query, method, params, qs, useFormData });
	const { response, status } = result;
	let json = {};
	if (response) {
		try {
			json = JSON.parse(response);
		} catch (e) {
			json = {
				status: 500,
				success: false,
				errors: { '': 'Incorrect response from server. ' + e },
			};
		}
	}
	result = { status, ...json };
	return result;
}
