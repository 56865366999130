import React from 'react';
import { useFormikContext } from 'formik';
import { Check, DeleteForever, Edit } from '@mui/icons-material';
import { css } from '@emotion/css/macro';
import { Button } from '@mui/material';
import { useReportAccess } from '../auth/hooks';
import { useAPI } from '../core/hooks';
import { ALL_PAYMENT_CHANNELS } from '../rocTemplates/UserData';
import { IconButton } from '../core/components';
import ItemLink from '../rocTemplates/ItemLink';
import { TableStickyCell } from '../../shared/components';
import { error } from '../ui/Core/stylesheets/colors';
import { AttachmentSharedProps } from './types';
import { useReportContext, useReportInfoContext } from '../rocTemplates/context/ReportContext';
import { useItemContext } from '../rocTemplates/context/ItemContext';
import { RocDataType } from '../core/types/api';

export type AttachmentRowProps = Omit<AttachmentSharedProps, 'prefix'> & {
	attachment: any;
	refreshProjectAttachments: () => void;
};

const controlsClass = css`
	display: flex;
	align-items: center;
`;

export default function AttachmentRow({
	AttachmentComponent,
	getRowSpan = () => 1,
	PCId,
	refreshProjectAttachments,
	attachment,
	onEditStart,
	isForReference,
	isItemApproved,
	highlight,
	AdditionalRowControls,
	type,
	attachmentName,
}: AttachmentRowProps) {
	const { isAQSA } = useReportAccess();
	const { projectId, refresh: refreshReport } = useReportContext();
	const { version } = useReportInfoContext();
	const { itemId: iid } = useItemContext();
	const { fetchAPI } = useAPI();

	const rowSpan = getRowSpan(attachment);

	const [selected, setSelected] = React.useState(false);
	const [errorSelecting, setErrorSelecting] = React.useState(false);

	const { setFieldValue, values = {} } = useFormikContext<any>() || {};
	const { sampleSets = [] } = values;
	const clickButton = () => {
		// In ROC v4 we can only attach a sample set as part of system evidence, which can then be referred on a control
		if (version && type === RocDataType.SampleSet) {
			setFieldValue('sampleSets', [...sampleSets, attachment]);
			setSelected(true);
			//if (onSelected) onSelected();
		}
		// While in v3 we can do so on sample set controls, which is basically the same,
		// but entity relation is simpler - there's no system evidence layer inbetween
		else
			fetchAPI({
				query: `RocItemControllerNew/report/setValue/${projectId}/${iid}/0`,
				method: 'POST',
				params: {
					paymentChannelId: PCId === ALL_PAYMENT_CHANNELS ? undefined : PCId,
					rocDataType: type,
					attachmentId: attachment?.id,
				},
				onSuccess: () => setSelected(true),
				onError: () => setErrorSelecting(true),
			});
	};

	const startEdit = () => {
		if (onEditStart) onEditStart({ ...attachment });
	};

	const onDelete = () =>
		fetchAPI({
			query: `Attachments/Delete/${projectId}/${attachment?.id}`,
			method: 'DELETE',
			onSuccess: () => {
				if (refreshProjectAttachments) refreshProjectAttachments();
				if (refreshReport) refreshReport();
			},
			confirmation: {
				title: `Are you sure you want to completely remove this ${attachmentName}?`,
				message: 'Including from all related controls',
			},
		});

	const renderReferences = React.useMemo(
		() =>
			attachment?.referenceItems?.map(
				({ itemId: refItemId, chapterId, numbering }: any, idx: number) => (
					<ItemLink
						key={idx}
						projectId={projectId}
						chapter={numbering}
						chapterId={chapterId}
						rocItemId={refItemId}
					/>
				),
			),
		[projectId, attachment?.referenceItems],
	);

	return (
		<>
			<AttachmentComponent highlighted={highlight === attachment.id} {...attachment}>
				<TableStickyCell rowSpan={rowSpan} stickyContainerClassName={controlsClass}>
					{isAQSA && (
						<>
							<IconButton onClick={startEdit}>
								<Edit color="primary" />
							</IconButton>
							{!isItemApproved && !isForReference && (
								<IconButton onClick={onDelete}>
									<DeleteForever htmlColor={error} />
								</IconButton>
							)}
							{isForReference && (
								<Button
									startIcon={<Check />}
									color={errorSelecting ? 'error' : 'primary'}
									onClick={clickButton}
									disabled={selected || errorSelecting}
									size="small"
									variant="outlined"
								>
									{errorSelecting ? 'Already selected' : <>{selected ? 'Selected' : 'Select'}</>}
								</Button>
							)}
						</>
					)}
				</TableStickyCell>
				<TableStickyCell rowSpan={rowSpan}>{renderReferences}</TableStickyCell>
				{!!AdditionalRowControls && (
					<AdditionalRowControls
						attachment={attachment}
						refreshProjectAttachments={refreshProjectAttachments}
					/>
				)}
			</AttachmentComponent>
		</>
	);
}
