import React from 'react';
import { get } from 'lodash';
import { connect } from 'formik';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { css } from '@emotion/css/macro';
import Select, { SelectOption, NewSelectProps } from '../../core/components/Select';
import { FormaggioInputProps } from '../types';
import { ErrorMessage, SelectSearchable } from '../../core/components';
import { fieldBaseClass } from '../stylesheets';

type SearchableSelectProps = { searchable?: boolean };

export type SelectFieldProps = NewSelectProps &
	FormaggioInputProps &
	SearchableSelectProps & { customChangeHandler?: any };

type PureSelectFieldProps = Omit<SelectFieldProps, 'name'> &
	SearchableSelectProps & {
		name?: string;
	};

const selectClass = css`
	min-width: 250px;
`;

export function PureSelectField({
	id,
	label,
	helperText,
	errorMessage,
	customChangeHandler,
	onChange,
	options,
	searchable,
	...rest
}: PureSelectFieldProps) {
	const handleChange = React.useCallback(
		(e) => {
			if (onChange) onChange(e);
			if (customChangeHandler) customChangeHandler(e);
		},
		[onChange, customChangeHandler],
	);

	const Component = React.useMemo(() => (searchable ? SelectSearchable : Select), [searchable]);

	return (
		<FormControl classes={{ root: fieldBaseClass }}>
			{label && !searchable && <InputLabel htmlFor={id}>{label}</InputLabel>}
			<Component
				onChange={handleChange}
				error={!!errorMessage}
				{...rest}
				id={id}
				options={options}
				label={searchable ? label : undefined}
				className={selectClass}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			<ErrorMessage message={errorMessage} />
		</FormControl>
	);
}

function SelectFieldConnected({ formik, name = '', ...rest }: SelectFieldProps) {
	return (
		<PureSelectField
			customChangeHandler={formik.handleChange}
			onBlur={formik.handleBlur}
			errorMessage={formik.errors[name]}
			name={name}
			value={get(formik.values, name, '')}
			{...rest}
		/>
	);
}

export default connect(SelectFieldConnected);

export function toSelectOptions(
	array: object[] = [],
	valueKey: string,
	titleKey: ((item: any) => string) | string,
): SelectOption[] {
	return array.map((item: any) => ({
		value: item[valueKey],
		title: typeof titleKey === 'string' ? item[titleKey] : titleKey(item),
	}));
}

export function recordToSelectOptions(
	record: Record<any, any>,
	keys: any[] | undefined,
	titleKey: ((item: any) => string) | string,
	labelName = 'title',
): any[] {
	const stringKeys = keys ? keys.map(String) : undefined;

	return Object.keys(record)
		.filter((k) => (stringKeys ? stringKeys.indexOf(k) >= 0 : true))
		.map((k) => ({
			value: k,
			[labelName]: typeof titleKey === 'string' ? record[k][titleKey] : titleKey(record[k]),
		}));
}
