import { givenRolesToSelectOptions } from './helpers';
import { ProjectSubRoles, Roles } from '../core/types/api';

export const AUTH_TOKEN_KEY = 'authToken';
export const LAST_PATH_KEY = 'return';
export const PRELOGIN_KEY = 'userName';

export const roleTitles: Partial<Record<Roles, string>> = {
	[Roles.GlobalAdministrator]: '🧙',
	[Roles.MasterAdministrator]: 'Master Administrator',
	//4: 'Client Administrator', to be merged with MA
	[Roles.ProjectManager]: 'Project Manager',
	[Roles.Qsa]: 'QSA',
	[Roles.AssociateQsa]: 'Associate QSA',
	[Roles.TechnicalEditor]: 'Technical Editor',
	[Roles.Client]: 'Client',
	[Roles.Contact]: 'Contact',
	[Roles.Auditor]: 'Auditor',
	[Roles.ExecutiveClientRepresentative]: 'Executive Client Representative',
};

export const subRoleDetails: Record<ProjectSubRoles, { title: string }> = {
	[ProjectSubRoles.Lead]: { title: 'Lead' },
	[ProjectSubRoles.Reviewer]: { title: 'Reviewer' },
	[ProjectSubRoles.QA]: { title: 'QA' },
};

export const MAX_ROLE_VALUE = Number.MAX_SAFE_INTEGER;

export const allRolesFlags = Object.keys(roleTitles)
	.map(Number)
	.filter((t) => t !== 256);

export const allRolesWithoutCEMOptions = givenRolesToSelectOptions(
	allRolesFlags.filter((t) => t !== 1024),
);
