import React from 'react';
import { ReportAccessContext, reportAccessInitial } from './hooks';
import { MAX_ROLE_VALUE } from './constants';
import { Channel, CustomSaq, WorkflowRole } from '../core/types/api';
import { projectSaqInfo } from '../project/helpers';
import { RolesEnum } from './enums';
import { ReportFilterType } from '../rocTemplates/filtering/reportFilterStorage';
import { stringifyReportFilter } from '../rocTemplates/filtering/helpers';

type ReportAccessWrapperProps = {
	children: React.ReactNode;
	projectRole?: number;
	workflowRole: WorkflowRole;
	paymentChannels: Channel[];
	customSaqList: CustomSaq[];
	reportFilter: ReportFilterType;
};

export function ReportAccessWrapper({
	children,
	projectRole,
	workflowRole,
	paymentChannels,
	customSaqList,
	reportFilter,
}: ReportAccessWrapperProps) {
	const [access, setAccess] = React.useState(reportAccessInitial);

	const stringFilter = stringifyReportFilter(reportFilter);
	React.useEffect(() => {
		const role = projectRole || MAX_ROLE_VALUE;

		setAccess({
			isGlobalAdmin: role === RolesEnum.GlobalAdministrator,
			isAdmin: role <= RolesEnum.ClientAdministrator,
			isQSA: role <= RolesEnum.Qsa,
			isAQSA: role <= RolesEnum.AssociateQsa,
			isClient: role <= RolesEnum.Client || role === RolesEnum.ExecutiveClientRepresentative,
			role,
			paymentChannels,
			customSaqList,
			projectSaqInfo: projectSaqInfo(paymentChannels),
			reportFilter: JSON.parse(stringFilter),
			workflowRole,
		});
	}, [projectRole, paymentChannels, customSaqList, stringFilter, workflowRole]);

	return <ReportAccessContext.Provider value={access}>{children}</ReportAccessContext.Provider>;
}
