import React from 'react';
import { css } from '@emotion/css/macro';
import { isPast } from 'date-fns';
import { Timer } from '@mui/icons-material';
import { Box } from '@mui/material';
import { ReportContact, ReportUser, Request, RequestEmailModel } from '../../core/types/api';
import { darkgrey } from '../../ui/Core/stylesheets/colors';
import { convertToHumanTime } from '../../core/helpers';
import { WorkflowAuthorsProps } from './WorkflowList';
import { Heading } from '../../core/components';

type RequestTargetProps = { user?: ReportUser; contact?: ReportContact; email?: RequestEmailModel };

function WorkflowRequestTarget({ user, contact, email }: RequestTargetProps) {
	if (user)
		return (
			<div>
				- {user.firstName} {user.lastName} (Client)
			</div>
		);

	if (contact)
		return (
			<div>
				- {contact.name} {contact.jobTitle && `(${contact.jobTitle})`} (Contact)
			</div>
		);

	return <div>- {email?.email}</div>;
}

const rootClass = css`
	padding: 8px;
	border: 1px solid ${darkgrey};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
`;

const iconClass = css`
	margin-right: 4px;
`;

type WorkflowRequestEntryProps = Request & WorkflowAuthorsProps;

export default function WorkflowRequestEntry({
	validUntil,
	targets,
	users,
	contacts,
}: WorkflowRequestEntryProps) {
	const renderAddressees = React.useMemo(
		() =>
			targets?.map(({ id, userId, contactId, email }) => {
				const user = userId ? users?.filter(({ id }) => id === userId)[0] : undefined;
				const contact = contactId ? contacts?.filter(({ id }) => id === contactId)[0] : undefined;
				return <WorkflowRequestTarget user={user} contact={contact} key={id} email={email} />;
			}),
		[targets, users, contacts],
	);

	const renderTokenDate = React.useMemo(
		() => (
			<Box display="flex" alignItems="center">
				<Timer htmlColor={darkgrey} className={iconClass} />
				<Heading variant="body2" color="textSecondary">
					{isPast(new Date(validUntil || '')) ? (
						<>Token expired</>
					) : (
						<>Token valid until {convertToHumanTime(validUntil)}</>
					)}
				</Heading>
			</Box>
		),
		[validUntil],
	);

	return (
		<div className={rootClass}>
			{renderTokenDate}
			{renderAddressees}
		</div>
	);
}
