import React from 'react';
import { Access, Resource } from '../enums';

function humanizeAction(action?: Access) {
	if (!action) return '';

	const actionEnum = Access[action];
	switch (action) {
		case Access.Create:
			return 'Added';
		case Access.Delete:
		case Access.Invite:
		case Access.Approve:
			return `${actionEnum}d`;
		default:
			break;
	}
	return `${actionEnum}ed`;
}

function humanizeResource(resource?: Resource) {
	if (!resource) return '';

	const resourceEnum = Resource[resource];
	switch (resource) {
		case Resource.Workflow:
			return 'Comment';
		default:
			break;
	}
	return resourceEnum;
}

type ResourceActionProps = { resource?: Resource; action?: Access };

export default function ResourceAction({ resource, action }: ResourceActionProps) {
	const humanized = React.useMemo(
		() => ({ action: humanizeAction(action), resource: humanizeResource(resource) }),
		[action, resource],
	);
	return (
		<>
			{humanized.action} {humanized.resource}
		</>
	);
}
