import Divider from '@material-ui/core/Divider';
import { Box, Chip } from '@mui/material';
import { css } from '@emotion/css/macro';

const titleClass = css`
	margin-right: 8px;
`;

function ImplementationLevel({ level }: any) {
	return (
		<Chip
			size="small"
			label={`Implementation level:  ${level ? 'System' : 'Organization'}`}
			color={level ? 'info' : 'warning'}
		/>
	);
}

type FedRampControlProps = any;

export default function FedRampControl({
	label,
	title,
	statementTemplate,
	contributesToAssurance,
	implementationLevel,
	children = [],
}: FedRampControlProps) {
	return (
		<>
			<Box marginBottom="16px">
				<Box display="flex" alignItems="center">
					<h3 className={titleClass}>
						{label}
						{title && <>: {title}</>}
					</h3>
					{contributesToAssurance && (
						<Chip
							size="small"
							label="Contributes to assurance"
							color="primary"
							className={titleClass}
						/>
					)}
					<ImplementationLevel level={implementationLevel} />
				</Box>
				{/* eslint-disable-next-line react/no-danger -- WIP */}
				<div dangerouslySetInnerHTML={{ __html: statementTemplate }} />
				{children}
			</Box>
			<Divider />
		</>
	);
}
