import { css, cx } from '@emotion/css/macro';
import React from 'react';
import { error, middlegrey } from '../../ui/Core/stylesheets/colors';

function SimpleConditionalInput({ isTextArea, inputRef, ...rest }: any) {
	return isTextArea ? <textarea {...rest} ref={inputRef} /> : <input {...rest} ref={inputRef} />;
}

const simpleTextFieldClass = css`
	padding: 8px;
	border-radius: 5px;
	border: 1px solid ${middlegrey};
	font: inherit;
	width: 100%;

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}
`;

const errorFieldClass = css`
	border-color: ${error};
`;

type SimplifiedTextFieldProps = any;

function SimplifiedTextField({
	label,
	className,
	isTextArea,
	error,
	...rest
}: SimplifiedTextFieldProps) {
	const rows = React.useMemo(
		() => (isTextArea ? (rest.value || '').split('\n').length || 5 : undefined),
		[isTextArea, rest.value],
	);

	return (
		<div>
			{label && (
				<small>
					{label}:
					<br />
				</small>
			)}
			<SimpleConditionalInput
				{...rest}
				isTextArea={isTextArea}
				className={cx(simpleTextFieldClass, error && errorFieldClass, className)}
				rows={rows}
			/>
		</div>
	);
}

export default React.memo(SimplifiedTextField);
