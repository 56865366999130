import React from 'react';

export type ContextValue = Readonly<{
	currentId: string;
	setCurrentId: (newId: string) => void;
	isArrayRow?: boolean;
}>;

export const EditableRowContext = React.createContext<ContextValue>({
	currentId: null,
	setCurrentId: () => undefined,
	isArrayRow: false,
});

export function useEditableRowContext(): ContextValue {
	return React.useContext(EditableRowContext);
}
