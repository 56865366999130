import { Box } from '@mui/material';
import { AppliedScopeInfo } from '../../core/types/api';

type ScopeItemInfoProps = { appliedScopeInfo?: AppliedScopeInfo };

export default function ScopeItemInfo({ appliedScopeInfo }: ScopeItemInfoProps) {
	if (!appliedScopeInfo) return null;

	return (
		<Box fontSize="13px" my="2px">
			From scope &quot;{appliedScopeInfo.name}&quot;
		</Box>
	);
}
