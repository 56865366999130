import React from 'react';
import SelectField, {
	SelectFieldProps,
	toSelectOptions,
} from '../formaggio/components/SelectField';
import { useMyClients } from './hooks';

type ClientSelectProps = {
	onSelect?: SelectFieldProps['onChange'];
	name?: string;
	id?: string;
	label?: string;
};

export default function ClientSelect({
	onSelect,
	name = 'clientId',
	id = name,
	label = 'Client',
}: ClientSelectProps) {
	const { list = [] } = useMyClients();
	const options = React.useMemo(() => {
		const result: { value: string; title: string }[] = [];
		return [...result, ...toSelectOptions(list, 'id', 'name')];
	}, [list]);

	if (options.length === 0) return null;

	return (
		<SelectField
			fullWidth
			label={label}
			onChange={onSelect}
			id={id}
			name={name}
			options={options}
			searchable
		/>
	);
}
