import React from 'react';
import Table, { TableProps } from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import { css, cx } from '@emotion/css/macro';

type SimpleTableProps = {
	headers?: any;
	rows: Record<any, any>[];
	cols: any[];
	keyValue: string;
	className?: string;
	headerClassNames?: string[];
	dense?: boolean;
	noneText?: string | React.ReactNode;
	nonStickyCells?: boolean;
} & TableProps;

export function SimpleTable({
	headers,
	rows,
	cols,
	keyValue,
	className,
	headerClassNames,
	noneText,
	nonStickyCells,
	...rest
}: SimpleTableProps) {
	const renderRows = React.useCallback(
		(props, rowIdx: number) => {
			const renderCols = (col: any, colIdx: number) => {
				const CellComponent = nonStickyCells ? TableCell : TableStickyCell;

				switch (typeof col) {
					case 'string':
						return <CellComponent key={colIdx}>{props[col]}</CellComponent>;
					case 'function':
						return <CellComponent key={colIdx}>{col(props, rowIdx, colIdx)}</CellComponent>;
					default:
						return <CellComponent key={colIdx}>{col}</CellComponent>;
				}
			};
			return <TableRow key={props[keyValue] || rowIdx}>{cols.map(renderCols)}</TableRow>;
		},

		[cols, keyValue, nonStickyCells],
	);

	const renderedHeaders = React.useMemo(
		() =>
			headers?.map((title: string, idx: number) => (
				<TableCell className={headerClassNames && headerClassNames[idx]} key={idx}>
					{title}
				</TableCell>
			)),
		[headers, headerClassNames],
	);

	if (rows.length === 0 && noneText) return <>{noneText}</>;

	return (
		<Table className={className} {...rest}>
			{headers && (
				<TableHead>
					<TableRow>{renderedHeaders}</TableRow>
				</TableHead>
			)}
			<TableBody>{rows.map(renderRows)}</TableBody>
		</Table>
	);
}

const stickyClass = css`
	position: sticky;
	top: 0;
`;

const stickyCellClass = css`
	vertical-align: top !important;
`;

type TableStickyCellProps = TableCellProps & { stickyContainerClassName?: string };

export const TableStickyCell = React.forwardRef(
	({ children, className, stickyContainerClassName, ...rest }: TableStickyCellProps, ref) => (
		<TableCell {...rest} className={cx(stickyCellClass, className)} ref={ref}>
			<div className={cx(stickyClass, stickyContainerClassName)}>{children}</div>
		</TableCell>
	),
);
