import { Download, UploadFile } from '@mui/icons-material';
import React from 'react';
import { css } from '@emotion/css/macro';
import { Button } from '../../../core/components';
import { DownloadState } from '../../../../shared/modules/files/constants';
import { downloadFile } from '../../../files/helpers';
import { useReportContext } from '../../context/ReportContext';
import { useToggle } from '../../../core/hooks';
import MaterialFlyOut from '../../../flyout/components/MaterialFlyOut';
import { FileField, FileForm } from '../../../formaggio';
import { MAX_SPREADSHEET_IMPORT_SIZE_MB } from '../../../evidence/constants';
import SimplifiedButtonGroup from '../../simplifiedControls/SimplifiedButtonGroup';
import SimplifiedButton from '../../simplifiedControls/SimplifiedButton';
import { useHubState } from '../../../hubs/hooks';
import { useAllowedMethods } from '../../../auth/hooks';

export function useArrayImportStatus({ id, isImporting, importStatus }: any) {
	const { projectId, refresh } = useReportContext();
	const [hubState] = useHubState();
	const { arrayImportStatuses } = hubState;
	const projectArrayImportStatus = arrayImportStatuses[projectId] || {};
	const arrayImportStatus = projectArrayImportStatus[id];

	const [needRefresh, setNeedRefresh] = React.useState(false);
	React.useEffect(() => {
		if (arrayImportStatus && !arrayImportStatus.isImporting) setNeedRefresh(true);
	}, [arrayImportStatus]);
	React.useEffect(() => {
		if (needRefresh) {
			setNeedRefresh(false);
			refresh();
		}
	}, [needRefresh, refresh]);

	return arrayImportStatus || { isImporting, status: importStatus };
}

const submitBtnClass = css`
	margin-top: 5px !important;
`;

export default function ArrayImport({ arrayId }: any) {
	const { projectId, refresh } = useReportContext();
	const { ArrayItems: canGet, 'ArrayItems/Import': canPut } = useAllowedMethods();

	const [downloadArrayItemsState, setDownloadArrayItemsState] = React.useState<DownloadState>(
		DownloadState.Idle,
	);

	const { isOn, toggleOff, toggleOn } = useToggle();

	const generateArrayItemsSheet = React.useCallback(
		() =>
			downloadFile({
				setDownloadState: setDownloadArrayItemsState,
				query: `ArrayItems/${projectId}/${arrayId}`,
			}),
		[projectId, arrayId, setDownloadArrayItemsState],
	);

	if (!canGet && !canPut) return null;

	return (
		<>
			<tr>
				<td colSpan={Number.MAX_SAFE_INTEGER}>
					<SimplifiedButtonGroup>
						{canGet && (
							<SimplifiedButton
								startIcon={<Download />}
								disabled={downloadArrayItemsState === DownloadState.Downloading}
								onClick={generateArrayItemsSheet}
							>
								{downloadArrayItemsState === DownloadState.Downloading
									? 'Downloading...'
									: 'Download Template'}
							</SimplifiedButton>
						)}
						{canPut && (
							<SimplifiedButton startIcon={<UploadFile />} onClick={toggleOn}>
								Import
							</SimplifiedButton>
						)}
					</SimplifiedButtonGroup>
				</td>
			</tr>
			<MaterialFlyOut open={isOn} onClose={toggleOff} title="Table Import">
				<FileForm
					onUploadCompleted={() => {
						toggleOff();
						refresh();
					}}
					url="ArrayItems/Import"
					initialValues={{
						projectId,
						arrayId,
					}}
					maxUploadSizeMb={MAX_SPREADSHEET_IMPORT_SIZE_MB}
				>
					<FileField
						noDragInstruction
						name="files"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
						instruction="Excel file only, 5MB max"
					/>
					<Button type="submit" color="primary" className={submitBtnClass}>
						Submit
					</Button>
				</FileForm>
			</MaterialFlyOut>
		</>
	);
}
