import React from 'react';
import { css } from '@emotion/css/macro';
import { Button, FieldSet, ThreeStateThumbButton } from '../../core/components';
import { useReportAccess } from '../../auth/hooks';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { ItemStatus } from '../../project/enums';
import { useAPI, useToggle } from '../../core/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import MaterialDialog from '../../flyout/components/MaterialDialog';
import { PureTextField } from '../../formaggio';
import { useFieldHandler } from '../../core/helpers/stateActionHelpers';
import { useAuthContext } from '../../auth/context';
import { WorkflowRole } from '../../core/types/api';
import { useItemStatus } from '../../rocTemplates/hooks';

function getApprovedDecision(workflowRole: WorkflowRole, isQAEnabled: boolean): ItemStatus {
	if (workflowRole === WorkflowRole.Qsa) return ItemStatus.ReadyForPeerReview;
	if (workflowRole === WorkflowRole.QA) return ItemStatus.Approved;

	//If peer reviewer
	return isQAEnabled ? ItemStatus.ReadyForQA : ItemStatus.Approved;
}

const controlsClass = css`
	display: flex;
`;

export default function WorkflowQuickApprove() {
	const { globalOptions = {} } = useAuthContext();
	const { workflowRole } = useReportAccess();
	const { projectId } = useReportContext();
	const { itemId } = useItemContext();
	const status = useItemStatus();
	const { initialFetch } = useAPI({
		props: { query: 'Workflow/Create', method: 'POST', useFormData: true },
	});
	const [decision, setDecision] = React.useState<boolean | undefined>();
	const roleDecision = React.useMemo(() => {
		const by = {
			qsa: workflowRole === WorkflowRole.Qsa && status === ItemStatus.WaitingForQsa,
			peerReviewer:
				workflowRole === WorkflowRole.PeerReviewer &&
				(status === ItemStatus.ReadyForPeerReview ||
					(!globalOptions.workflowQAEnabled && status === ItemStatus.ReadyForQA)),
			qa: workflowRole === WorkflowRole.QA && status === ItemStatus.ReadyForQA,
		};

		let label = '';
		if (by.qsa) label = 'Ready for peer review?';
		if (by.peerReviewer) label = globalOptions.workflowQAEnabled ? 'Ready for QA?' : 'Approve?';
		if (by.qa) label = 'Approve?';

		return {
			by,
			label,
			approveStatus: getApprovedDecision(workflowRole, globalOptions.workflowQAEnabled),
		};
	}, [globalOptions.workflowQAEnabled, status, workflowRole]);

	const decide = React.useCallback(
		(decision: boolean, message?: string) => {
			initialFetch({
				params: {
					projectId,
					itemId,
					status: decision ? roleDecision.approveStatus : ItemStatus.ChangesRequested,
					message,
					visibleToCustomers: false,
				},
				onSuccess: () => setDecision(decision),
			});
		},
		[initialFetch, projectId, itemId, roleDecision.approveStatus],
	);

	const { isOn, toggleOn, toggleOff } = useToggle();
	const [reason, setReason] = React.useState('');
	const handleReason = useFieldHandler(setReason);
	const decline = React.useCallback(() => {
		decide(false, reason);
		toggleOff();
	}, [decide, reason, toggleOff]);

	const onClick = React.useCallback(
		(decision: boolean) => (decision ? decide(true) : toggleOn()),
		[decide, toggleOn],
	);

	if (roleDecision.by.qsa || roleDecision.by.peerReviewer || roleDecision.by.qa)
		return (
			<>
				<FieldSet legend={roleDecision.label}>
					<div className={controlsClass}>
						<ThreeStateThumbButton
							disabled={decision !== undefined}
							onClick={onClick}
							value={decision}
							disableDecline={roleDecision.by.qsa}
						/>
					</div>
				</FieldSet>
				<MaterialDialog
					isOpen={isOn}
					actions={
						<>
							<Button color="primary" onClick={decline}>
								Submit
							</Button>
							<Button color="secondary" onClick={toggleOff}>
								Cancel
							</Button>
						</>
					}
					onClose={toggleOff}
				>
					Enter reason on why this item was declined
					<PureTextField
						fullWidth
						multiline
						variant="outlined"
						value={reason}
						onChange={handleReason}
					/>
				</MaterialDialog>
			</>
		);

	return null;
}
