import { BRAND } from '../../../branding/constants';

export const darkness = '#1a1a1a';
export const lightgrey = '#f5f5f6';
export const middlegrey = '#dddedd';
export const grass = '#0c6752';
export const darkgrass = '#1a4e4d';
export const darkgrey = '#ccc';
export const kveld = 'rgb(7, 71, 166)';
export const skyblue = '#1f9bcf';

// const grey10 = '#f5f5f5';
// const grey20 = '#eee';
// const grey30 = '#ddd';
// const grey40 = '#ccc';
// const grey50 = '#999';

export const { secondary, success, warning, error, highlight, mainLight, mainDark } = BRAND.colors;
