import { createTheme } from '@material-ui/core';
import { mainLight, mainDark, secondary, warning, error, success } from './colors';

export default createTheme({
	typography: {
		htmlFontSize: 17,
		fontFamily:
			'-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
	},
	overrides: {
		MuiSelect: {
			select: {
				minWidth: 120,
			},
		},
		MuiButton: {
			root: {
				padding: '8px 16px',
				textTransform: 'capitalize',
			},
		},
		MuiIconButton: {
			root: {
				'&$disabled': {
					opacity: 0.5,
				},
			},
		},
		MuiAppBar: {
			root: {
				zIndex: 10,
			},
		},
		MuiBadge: {
			badge: {
				margin: '0.3rem 0.3rem 0 0',
			},
			colorPrimary: {
				backgroundColor: warning,
			},
			colorSecondary: {
				backgroundColor: mainDark,
			},
		},
		MuiTooltip: {
			tooltip: {
				fontSize: '14px',
				fontWeight: 400,
			},
		},
		MuiAccordionSummary: {
			root: {
				fontSize: '1rem',
			},
		},
	},
	props: {
		MuiIconButton: {
			size: 'small',
		},
		MuiButton: {
			variant: 'contained',
			color: 'primary',
		},
		MuiLink: {
			variant: 'body2',
		},
		MuiTooltip: {
			enterDelay: 0,
		},
	},
	palette: {
		primary: {
			main: mainLight,
		},
		secondary: {
			main: secondary,
		},
		error: {
			main: error,
		},
		warning: {
			main: warning,
		},
		success: {
			main: success,
		},
	},
});
