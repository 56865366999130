import { Dispatch } from 'redux';
import asyncRequest, { StatusResult } from '../core/actions/asyncRequest';
import { AuthState, AuthAction } from './types';
import { AUTH_TOKEN_KEY } from './constants';
import { removePersistency } from '../apollo/persistency';

export function checkAuth() {
	return async (dispatch: Dispatch) => {
		dispatch({ type: 'AUTH_REQUEST' });
		asyncRequest({
			query: 'account/GetUserInfo',
			qs: { clientTimeZoneOffset: new Date().getTimezoneOffset() },
		}).then((result: any) => {
			const { success, ...rest } = result;
			if (!success) localStorage.removeItem(AUTH_TOKEN_KEY);
			return dispatch({
				type: success ? 'AUTH_SUCCESS' : 'AUTH_FAILURE',
				payload: {
					userInfo: { ...rest },
				},
			});
		});
	};
}

export function signOut() {
	return async (dispatch: Dispatch) => {
		dispatch({ type: 'AUTH_REQUEST' });
		asyncRequest({ query: 'account/Logout', method: 'POST' }).then((result: StatusResult) => {
			const { success } = result;
			removePersistency();
			localStorage.removeItem(AUTH_TOKEN_KEY);
			dispatch({
				type: success ? 'AUTH_SUCCESS' : 'AUTH_FAILURE',
			});
		});
	};
}

export default (state: AuthState, action: AuthAction) => {
	switch (action.type) {
		case 'AUTH_REQUEST':
			return { loading: true };
		case 'AUTH_SUCCESS':
			return { loading: false, ...action.payload };
		case 'AUTH_FAILURE':
			return { loading: false };
		default:
			return state || {};
	}
};
