import gql from 'graphql-tag';

export default gql`
	query getResponsibilityFilter($projectId: String!) {
		data: get_api_ResponsibilityArea_GetFilters_projectId(projectId: $projectId)
			@rest(path: "ResponsibilityArea/GetFilters/{args.projectId}") {
			data {
				evidenceResponsibilities
				interviewResponsibilities
			}
		}
	}
`;
