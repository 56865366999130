import React from 'react';
import { css } from '@emotion/css/macro';
import FolderTile from './FolderTile';
import { Heading } from '../../core/components';
import { ModelItemIdProps } from '../../rocTemplates/types';
import { MediaFile } from '../../../graphql/typescript';

const rootClass = css`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
`;

type EvidenceListProps = {
	attachments?: MediaFile[];
	refresh: () => void;
	additionalControls?: React.ComponentType<any>;
} & ModelItemIdProps;

export default function EvidenceList({
	attachments,
	modelId,
	refresh,
	additionalControls: AdditionalControls,
}: EvidenceListProps) {
	const renderEvidence = React.useCallback(
		({ id, assetId, ...evidence }: any, idx: number) => (
			<FolderTile
				itemId={assetId?.itemId}
				{...evidence}
				refresh={refresh}
				key={id || idx}
				id={id}
				modelId={modelId}
				additionalControls={AdditionalControls ? <AdditionalControls id={id} /> : null}
			/>
		),
		[modelId, refresh, AdditionalControls],
	);

	if (!attachments) return null;

	if (attachments.length === 0) return <Heading size={2}>No files</Heading>;

	return <div className={rootClass}>{attachments.map(renderEvidence)}</div>;
}
