import React from 'react';
import { useDispatch } from 'react-redux';
import { popUpShow, popUpClose } from '../reducers';
import { PopUpProps } from '../components/PopUp';

export default function usePopup(initialProps: PopUpProps = {}, initialAutoClose = false) {
	const dispatch = useDispatch();

	const showPopup = React.useCallback(
		(popupProps?: PopUpProps, autoClose = false) => {
			dispatch(
				popUpShow({
					state: { ...initialProps, ...popupProps },
					autoClose: initialAutoClose || autoClose,
				}) as any,
			);
		},
		[dispatch, initialProps, initialAutoClose],
	);

	const hidePopup = React.useCallback(() => {
		dispatch(popUpClose() as any);
	}, [dispatch]);

	return {
		showPopup,
		hidePopup,
	};
}
