import React from 'react';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import {
	InterviewAttendeeType,
	InterviewModel,
	ReportContact,
	ReportUser,
} from '../../core/types/api';
import { SimpleTable } from '../../../shared/components';

function reduceItemAttendees(
	itemInterviews: InterviewModel[] = [],
	contacts: ReportContact[] = [],
	users: ReportUser[] = [],
) {
	const interviewsContactIds: string[] = itemInterviews.reduce(
		(memo: string[], { contactsIds = [] }) => [...memo, ...contactsIds],
		[],
	);
	const itemContacts: Attendee[] = contacts.reduce((memo: Attendee[], { id = '', ...rest }) => {
		if (interviewsContactIds?.indexOf(id) < 0 || memo.some(({ id: contactId }) => id === contactId))
			return memo;

		return [
			...memo,
			{
				id,
				type: InterviewAttendeeType.Contact,
				...rest,
			},
		];
	}, []);

	const interviewsUserIds: string[] = itemInterviews.reduce(
		(memo: string[], { userIds = [] }) => [...memo, ...userIds],
		[],
	);
	const itemUsers: Attendee[] = users.reduce(
		(memo: Attendee[], { id = '', firstName, lastName, phoneNumber, ...rest }: ReportUser) => {
			if (interviewsUserIds?.indexOf(id) < 0 || memo.some(({ id: userId }) => id === userId))
				return memo;

			return [
				...memo,
				{
					id,
					name: `${firstName} ${lastName}`,
					type: InterviewAttendeeType.User,
					phone: phoneNumber,
					jobTitle: 'Client',
					...rest,
				},
			];
		},
		[],
	);

	return [...itemContacts, ...itemUsers];
}

const cols = ['name', 'jobTitle', 'email', 'phone', 'organization'];

type Attendee = any;

export default function ItemAttendeesList() {
	const { contacts, users, interviews } = useReportContext();
	const { itemId } = useItemContext();

	const itemInterviews = React.useMemo(
		() => interviews?.filter(({ rocItemsIds = [] }) => rocItemsIds.indexOf(itemId) >= 0) || [],
		[interviews, itemId],
	);

	const itemAttendeesInitial: Attendee[] = React.useMemo(
		() => reduceItemAttendees(itemInterviews, contacts, users),
		[itemInterviews, contacts, users],
	);

	const [itemAttendees, setItemAttendees] = React.useState<Attendee[]>(itemAttendeesInitial);
	React.useEffect(() => {
		setItemAttendees(itemAttendeesInitial);
	}, [setItemAttendees, itemAttendeesInitial]);

	return <SimpleTable rows={itemAttendees} cols={cols} keyValue="id" />;
}
