import React from 'react';
import { css } from '@emotion/css/macro';
import Button from '@material-ui/core/Button';
import { clipboardCopy } from '../helpers';

const rootClass = css`
	display: flex;
`;

const titleRootClass = css`
	position: sticky;
	top: 0;
	min-width: 140px;
	height: 100%;
	padding-right: 16px;
`;

const titleClass = css`
	margin-bottom: 8px;
`;

const buttonClass = css`
	color: white !important;
	border-color: white !important;
`;

type InternalErrorMessageProps = { text: string };

export default function InternalErrorMessage({ text }: InternalErrorMessageProps) {
	const copyToClipboard = React.useCallback(() => clipboardCopy(text), [text]);

	return (
		<div className={rootClass}>
			<div className={titleRootClass}>
				<div className={titleClass}>Internal Server Error</div>
				<Button className={buttonClass} onClick={copyToClipboard} variant="outlined" fullWidth>
					Copy
				</Button>
			</div>
			<div>{text}</div>
		</div>
	);
}
