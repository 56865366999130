import { css, cx } from '@emotion/css/macro';
import React from 'react';
import { middlegrey, mainDark } from '../../ui/Core/stylesheets/colors';

const fieldSetClass = css`
	margin: 4px;
	padding: 8px 16px 16px 16px;
	border: 1px solid ${middlegrey};
	border-radius: 5px;
`;

const legendClass = css`
	padding-inline-start: 4px;
	padding-inline-end: 4px;
	color: ${mainDark};
`;

type FieldSetProps = { children: React.ReactNode; legend?: string; className?: string };

export default function FieldSet({ children, legend, className }: FieldSetProps) {
	return (
		<fieldset className={cx(fieldSetClass, className)}>
			{legend && <legend className={legendClass}>{legend}</legend>}
			{children}
		</fieldset>
	);
}
