import React from 'react';
import { isFuture } from 'date-fns';
import { Box } from '@mui/material';
import { ReportContact, ReportUser, Workflow } from '../../core/types/api';
import WorkflowEntry from './WorkflowEntry';
import WorkflowCreate from './WorkflowCreate';
import { ModelItemIdProps } from '../../rocTemplates/types';
import { useMultiFieldHandler } from '../../core/helpers/stateActionHelpers';
import { PureCheckboxField } from '../../formaggio';
import { useAPI } from '../../core/hooks';

export type WorkflowAuthorsProps = { users?: ReportUser[]; contacts?: ReportContact[] };

export type WorkflowSharedProps = ModelItemIdProps & {
	refresh: () => void;
	itemResponsibility?: number;
};

export type WorkflowListProps = WorkflowSharedProps & {
	availableChannelOptions?: any[];
};

function reduceRequestIds(workflows: Omit<Workflow, 'projectId'>[]) {
	return workflows
		.filter(({ request }) => (request?.validUntil ? isFuture(new Date(request.validUntil)) : false))
		.reduce((memo: any, curr) => {
			const targets =
				curr.request?.targets?.map(({ userId, contactId, email }) => {
					if (userId) return userId;
					if (contactId) return contactId;
					return email?.email || '';
				}) || [];

			const currentMemo: string[] = [...memo];

			targets.forEach((id) => {
				if (currentMemo.indexOf(id) >= 0) return;
				currentMemo.push(id);
			});

			return currentMemo;
		}, []);
}

export default function WorkflowList({
	modelId,
	itemId,
	refresh: refreshReport,
	availableChannelOptions,
	itemResponsibility,
}: WorkflowListProps) {
	const { state, initialFetch: refreshList } = useAPI({
		props: {
			query: `Workflow/List/${modelId}/${itemId}`,
			onSuccess: refreshReport,
		},
		autoLoad: true,
	});
	const [filter, setFilter] = React.useState({
		workNotes: false,
		error: '',
	});
	const changeFilter = useMultiFieldHandler(setFilter);

	const renderWorkflow = React.useMemo(
		() =>
			(state?.autodata?.data || [])
				.filter(({ isWorkPaper }: any) => !filter.workNotes || isWorkPaper)
				.map(({ id, createdById, createdByType, ...props }: any) => (
					<WorkflowEntry
						id={id}
						key={id}
						{...props}
						createdById={createdById}
						createdByType={createdByType}
						projectId={modelId}
						availableChannelOptions={availableChannelOptions}
						refresh={refreshList}
					/>
				)),
		[state?.autodata?.data, filter.workNotes, modelId, availableChannelOptions, refreshList],
	);

	const requestedIds = React.useMemo(
		() => reduceRequestIds(state?.autodata?.data || []),
		[state?.autodata?.data],
	);

	return (
		<Box minWidth="800px">
			{(renderWorkflow.length > 0 || filter.workNotes) && (
				<Box
					padding="4px"
					display="flex"
					alignItems="center"
					position="sticky"
					top={0}
					bgcolor="white"
					zIndex={1}
				>
					<Box marginRight="8px">Filter:</Box>
					<PureCheckboxField
						onChange={changeFilter}
						checked={filter.workNotes}
						name="workNotes"
						label="Worknotes"
						errorMessage={filter.error}
					/>
				</Box>
			)}
			{renderWorkflow}
			<WorkflowCreate
				modelId={modelId}
				itemId={itemId}
				refresh={refreshList}
				alreadyRequestedIds={requestedIds}
				itemResponsibility={itemResponsibility}
			/>
		</Box>
	);
}
