import React from 'react';
import { get } from 'lodash';
import { Add, Delete } from '@mui/icons-material';
import { FieldSet, IconButton } from '../../../../core/components';
import ItemGeneralFields from '../ItemGeneralFields';
import { toStringPath } from '../../helpers';
import { RocItemType } from '../../../enums';

export default function OneMultipleRowControls({ DTO, path, setDTO, onChange }: any) {
	const rowsFromDTO = React.useMemo(
		() => get(DTO, toStringPath([...path, 'secondColumnRows']), []),
		[DTO, path],
	);

	const addColumn = React.useCallback(() => {
		const newCols = [...rowsFromDTO, { type: RocItemType.TableRow }];
		onChange({ name: 'secondColumnRows', value: newCols });
	}, [onChange, rowsFromDTO]);

	const removeColumn = React.useCallback(
		(idx) => {
			const newCols = [...rowsFromDTO];
			newCols.splice(idx, 1);
			onChange({ name: 'secondColumnRows', value: newCols });
		},
		[onChange, rowsFromDTO],
	);

	const renderOneMultipleRows = React.useCallback(
		(column, idx) => {
			const remove = () => {
				removeColumn(idx);
			};

			return (
				<FieldSet key={get(column, 'id', idx)}>
					<IconButton onClick={remove}>
						<Delete />
					</IconButton>
					<ItemGeneralFields
						DTO={DTO}
						path={[...path, `secondColumnRows[${idx}]`]}
						setDTO={setDTO}
						isEditMode
					/>
				</FieldSet>
			);
		},
		[DTO, path, setDTO, removeColumn],
	);

	return (
		<>
			<FieldSet legend="1st Column">
				<ItemGeneralFields isEditMode DTO={DTO} path={[...path, 'firstColumn']} setDTO={setDTO} />
			</FieldSet>
			<FieldSet legend="2nd Column Rows">
				{rowsFromDTO.map(renderOneMultipleRows)}
				<IconButton onClick={addColumn}>
					<Add />
				</IconButton>
			</FieldSet>
		</>
	);
}
