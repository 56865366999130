import gql from 'graphql-tag';

export default gql`
	query getCompensatingControls($rocModelId: String!, $rocItemId: String!) {
		control: get_api_CompensatingControls_viewCompensatingControls_rocModelId_summaryItemId(
			rocModelId: $rocModelId
			summaryItemId: $rocItemId
		)
			@rest(
				path: "CompensatingControls/viewCompensatingControls/{args.rocModelId}/{args.summaryItemId}"
			) {
			data {
				disclosureSummary
				constraints
				objective
				identifiedRisk
				definition
				validation
				maintenance
			}
		}
	}
`;
