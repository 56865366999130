import React from 'react';
import { useFormikContext } from 'formik';
import { convertToRaw, EditorState } from 'draft-js';
import DraftField, { DraftFieldProps } from './DraftField';
import { setInitialDraftEditorState } from './helpers';

type FormDraftFieldProps = Pick<
	DraftFieldProps,
	'className' | 'mentions' | 'name' | 'placeholder' | 'editorKey' | 'readOnly'
>;

export default function FormDraftField({ name = '', ...rest }: FormDraftFieldProps) {
	// Value should be a raw JSON of draft state, which is different from EditorState, see helpers for conversion
	const { setFieldValue, values = {} } = useFormikContext<any>();

	const [touched, setTouched] = React.useState(false);

	// Use separate editor state because reconversion for form state messes it up for draft.js
	const [editorState, setEditorState] = React.useState(setInitialDraftEditorState(values[name]));
	const handlers = React.useMemo(
		() => ({
			change: (newState: EditorState) => {
				setTouched(true);

				if (typeof newState !== 'function')
					// Ignore when passing function, state will update automatically on next iteration if it is one
					setFieldValue(name, JSON.stringify(convertToRaw(newState.getCurrentContent())));
				setEditorState(newState);
			},
		}),
		[name, setFieldValue],
	);

	const value = values[name];

	// Reset both states on form reset
	React.useEffect(() => {
		if (!value && touched) {
			setEditorState(EditorState.createEmpty());
			setTouched(false);
		}
	}, [touched, value]);

	return (
		<DraftField setEditorState={handlers.change} editorState={editorState} name={name} {...rest} />
	);
}
