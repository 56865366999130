import React from 'react';
import { connect } from 'formik';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from '../../core/components';

type ResetButtonProps = {
	formik?: any;
} & ButtonProps;

function ResetButton({ className, children, formik, onClick, ...rest }: ResetButtonProps) {
	const click = React.useCallback(
		(event) => {
			if (onClick) {
				onClick(event);
			}
			formik.handleReset();
		},
		[onClick, formik],
	);

	return (
		<Button className={className} onClick={click} {...rest}>
			{children}
		</Button>
	);
}

export default connect(ResetButton);
