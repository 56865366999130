import React from 'react';
import { css } from '@emotion/css/macro';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isFuture, isPast } from 'date-fns';
import { CalendarMonth, Link, Place } from '@mui/icons-material';
import { Box } from '@mui/material';
import { InterviewModel } from '../../graphql/typescript';
import { RouterLink } from '../core/components';
import { ModelItemIdProps } from './types';
import { darkgrey, mainLight } from '../ui/Core/stylesheets/colors';
import { useMenuHandlers } from '../core/hooks';
import { convertToHumanTime } from '../core/helpers';
import SimplifiedButton from './simplifiedControls/SimplifiedButton';

const menuItemClass = css`
	display: flex;
	align-items: center;
`;

const menuSubItemClass = css`
	margin-right: 1rem;
	font-size: 14px !important;
`;

const subItemIconClass = css`
	margin-right: 0.5rem;
`;

type InterviewMenuItemProps = InterviewModel & ModelItemIdProps;

function InterviewMenuItem({ modelId, id, subject, date, location }: InterviewMenuItemProps) {
	const link = React.useMemo(
		() => `/portal/interviews/project/${modelId}/edit/${id}`,
		[modelId, id],
	);

	return (
		<RouterLink to={link}>
			<MenuItem className={menuItemClass}>
				<Box>
					<Box display="flex" alignItems="center">
						<CalendarMonth htmlColor={darkgrey} className={subItemIconClass} />
						{convertToHumanTime(date)}
					</Box>
					{location && (
						<div className={menuSubItemClass}>
							<Place htmlColor={darkgrey} className={subItemIconClass} />
							{location}
						</div>
					)}
					<div className={menuSubItemClass}>Subject: {subject}</div>
				</Box>
				<Link htmlColor={mainLight} />
			</MenuItem>
		</RouterLink>
	);
}

type InterviewsButtonMenuProps = ModelItemIdProps & { interviews?: InterviewModel[] };

export function InterviewsButtonMenu({
	modelId,
	itemId,
	interviews,
	...buttonGroupProps
}: InterviewsButtonMenuProps) {
	const {
		openMenu: openScheduledMenu,
		menuAnchor: menuScheduledAnchor,
		closeMenu: closeScheduledMenu,
	} = useMenuHandlers();
	const {
		openMenu: openPassedMenu,
		menuAnchor: menuPassedAnchor,
		closeMenu: closePassedMenu,
	} = useMenuHandlers();

	const renderInterviewMenuItems = React.useMemo(
		() => ({
			scheduled:
				interviews
					?.filter(({ date }) => isFuture(new Date(date)))
					.map((interview, idx) => (
						<InterviewMenuItem modelId={modelId} itemId={itemId} {...interview} key={idx} />
					)) || [],
			passed:
				interviews
					?.filter(({ date }) => isPast(new Date(date)))
					.map((interview, idx) => (
						<InterviewMenuItem modelId={modelId} itemId={itemId} {...interview} key={idx} />
					)) || [],
		}),
		[interviews, modelId, itemId],
	);

	if (!interviews || interviews.length === 0) return null;

	return (
		<>
			{renderInterviewMenuItems.scheduled.length > 0 && (
				<SimplifiedButton {...buttonGroupProps} onClick={openScheduledMenu}>
					Scheduled Interviews
				</SimplifiedButton>
			)}
			{renderInterviewMenuItems.passed.length > 0 && (
				<SimplifiedButton {...buttonGroupProps} onClick={openPassedMenu}>
					Past Interviews
				</SimplifiedButton>
			)}
			<Menu
				anchorEl={menuScheduledAnchor}
				keepMounted
				open={Boolean(menuScheduledAnchor)}
				onClose={closeScheduledMenu}
			>
				{renderInterviewMenuItems.scheduled}
			</Menu>
			<Menu
				anchorEl={menuPassedAnchor}
				keepMounted
				open={Boolean(menuPassedAnchor)}
				onClose={closePassedMenu}
			>
				{renderInterviewMenuItems.passed}
			</Menu>
		</>
	);
}
