import React from 'react';
import { css } from '@emotion/css/macro';
import { Delete } from '@mui/icons-material';
import { IconButton } from '../core/components';
import { error } from '../ui/Core/stylesheets/colors';
import { GenericFile } from '../formaggio/types';
import { FileFieldHandler } from '../formaggio/components/FileField';

const fileNameClass = css`
	margin-right: 1rem;
	word-break: break-all;
	font-size: 12px;
	min-width: 80px;
	max-width: 120px;
`;

const fileAttachmentClass = css`
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
`;

type PreUploadFileProps = {
	setFiles: React.Dispatch<React.SetStateAction<GenericFile[]>>;
	idx: number;
	file: GenericFile;
	fileFields?: FileFieldHandler;
	fileFieldsProps?: any;
};

export default function PreUploadFile({
	setFiles,
	idx,
	file,
	fileFields: FileFields,
	fileFieldsProps = {},
}: PreUploadFileProps) {
	const deleteFileFromQueue = React.useCallback(
		() =>
			setFiles((prevState) => {
				const newList = [...prevState];
				newList.splice(idx, 1);
				return newList;
			}),
		[setFiles, idx],
	);

	const changeFile = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.persist) e.persist();
			setFiles((prevState) => {
				const newState = [...prevState];
				newState[idx][e.target.name] = e.target.value;
				return newState;
			});
		},
		[setFiles, idx],
	);

	return (
		<div className={fileAttachmentClass} key={idx}>
			<div className={fileNameClass}>{file.name}</div>
			{FileFields && <FileFields onChange={changeFile} storage={file} {...fileFieldsProps} />}
			<IconButton onClick={deleteFileFromQueue}>
				<Delete htmlColor={error} />
			</IconButton>
		</div>
	);
}
