import React from 'react';
import Data from '../../UserData';
import DateInput from './DateInput';
import { getValueInToLocalTime } from '../../../core/helpers';

function DateSelector({ value = {}, additional, onChange, type = 'date', ...rest }: any) {
	const changeValue = React.useCallback(
		(e: any) => {
			onChange({
				value: type === 'date' ? getValueInToLocalTime(e.target.value) : e.target.value,
			});
		},
		[type, onChange],
	);
	return (
		<>
			<DateInput
				type={type}
				value={value}
				onChange={changeValue}
				additinal={additional}
				{...rest}
			/>
		</>
	);
}

export default function DateTime({ id, label, ...rest }: any) {
	const type = 'date';
	return (
		<Data
			retrievedValue={(e: any) => e}
			elemId={id}
			inputElement={DateSelector}
			additional={{ label, type }}
			{...rest}
		/>
	);
}
