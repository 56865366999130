import format from 'date-fns/format';
import { parse } from 'query-string';
import md5 from 'js-md5';
import { TIME_FORMAT } from '../constants/time';
import defaultUser from '../../../images/user.png';

export function has<T>(arr: T[], val: T) {
	return arr.indexOf(val) >= 0;
}

export function hasAny<T>(arr: T[], vals: T[], negate?: boolean) {
	return arr.filter((ai) => (negate ? !has(vals, ai) : has(vals, ai))).length > 0;
}

export function diffFlat(a: any[], b: any[]) {
	return a.filter((av) => b.indexOf(av) < 0);
}

/* eslint no-bitwise: 0 */
export function sortByBoolean(a: boolean | undefined, b: boolean | undefined) {
	return (b ? 1 : 0) - (a ? 1 : 0);
}

export function stringToColor(anything: string) {
	let hash = 0;

	if (anything.length === 0) return hash;

	for (let i = 0; i < anything.length; i += 1) {
		hash = anything.charCodeAt(i) + ((hash << 5) - hash);
		hash &= hash;
	}

	let hex =
		((hash >> 24) & 0xcc).toString(16) +
		((hash >> 16) & 0xcc).toString(16) +
		((hash >> 8) & 0xcc).toString(16) +
		(hash & 0xcc).toString(16);

	hex += '000000';
	return `#${hex.substring(0, 6)}`;
}

//-- Flag stuff
export function hasFlag(number?: number, flag?: number) {
	if (number === undefined || flag === undefined) return false;
	return (flag & number) === flag;
}

export function flagSumToArray(sum: number, includeZero?: boolean): number[] {
	const result: number[] = [];
	if (includeZero) result.push(0);
	for (let i = 0; 2 ** i <= sum; i += 1) {
		const currIdxPow = 2 ** i;
		if (hasFlag(sum, currIdxPow)) result.push(currIdxPow);
	}
	return result;
}

export function flagArrayToSum(arr: number[]) {
	return arr.reduce((memo, curr) => memo + curr, 0);
}
//-- end flag stuff

export function filterObjectsWithKeyValueFromOthers(list: any[], filteredList: any[], key: string) {
	return list.filter((item) => !filteredList.some((filterItem) => item[key] === filterItem[key]));
}

export function convertToHumanTime(time?: string, timeFormat: TIME_FORMAT = TIME_FORMAT.FULL) {
	return format(time ? new Date(time) : new Date(), timeFormat);
}

export function truncateString(value: string, maxLength = 50) {
	return value.length <= maxLength ? value : `${value.substring(0, maxLength)}...`;
}

export function isObject(obj) {
	return !!obj && typeof obj === 'object';
}

export function hasKeys(obj) {
	return isObject(obj) && Object.keys(obj).length > 0;
}

export function positiveValueFilter(v) {
	return Number(v) > 0;
}

export function hasPositiveValue(obj) {
	return isObject(obj) && Object.values(obj).some(positiveValueFilter);
}

//QS
export function getWindowQS() {
	return parse(window.location.search);
}

export function getQS(key: string) {
	return getWindowQS()[key];
}

export function getNameQS(split: boolean) {
	const name = getQS('name');
	if (name) return split ? String(name).split(' ') : String(name);
	return undefined;
}

export function getTokenQS() {
	const token = getQS('token');
	return token ? String(token) : undefined;
}

//Other

export function goBack() {
	return window.history.back();
}

//Clipboard
export function clipboardCopy(text?: string) {
	try {
		document.execCommand('copy');
	} catch {
		//ignored
	}
	try {
		navigator.clipboard.writeText(text || '');
	} catch {
		//ignored
	}
}

//Camelize
export function camelize(text: string) {
	return text.substring(0, 1).toLocaleLowerCase() + text.substring(1);
}

//Avatar
export function getAvatarUrl(email?: string) {
	if (!email) return defaultUser;
	const hash = md5(email.toLocaleLowerCase());
	return `https://www.gravatar.com/avatar/${hash}?d=mp&r=g&s=128`;
}

//Inputs

export function getValueByInputType(e: any) {
	return e.target.type === 'checkbox' ? e.target.checked : e.target.value;
}

//Date
export function getValueInToLocalTime(value) {
	return new Date(value); //clears time zone details that corrupts dates on the backend
}

//Html

export function removeHtml(text = '') {
	return text.replace(/<[^<>]+>/gi, '');
}

// Other

export function listToString(list: string[] = [], sep = ', ') {
	const result = list.filter((s) => !!s).join(sep);

	return result.length < 1 ? undefined : result;
}
