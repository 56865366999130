import { Dispatch } from 'redux';
import { PopUpProps } from '../components/PopUp';

enum PopUpActionType {
	Show = 'POPUP_SHOW',
	Close = 'POPUP_CLOSE',
}

type PopUpState = PopUpProps;

type PopUpAction =
	| { type: PopUpActionType.Show; payload: PopUpState }
	| { type: PopUpActionType.Close };

let popUpTimer: number;

export function popUpShow({
	state = {},
	autoClose = false,
}: {
	state?: PopUpState;
	autoClose?: boolean;
}) {
	return (dispatch: Dispatch<PopUpAction>) => {
		if (popUpTimer) {
			window.clearTimeout(popUpTimer);
		}
		dispatch({ type: PopUpActionType.Show, payload: state });
		if (autoClose) {
			popUpTimer = window.setTimeout(() => {
				dispatch({ type: PopUpActionType.Close });
			}, 3000);
		}
	};
}

export function popUpClose() {
	return (dispatch: Dispatch<PopUpAction>) => {
		window.clearTimeout(popUpTimer);
		dispatch({ type: PopUpActionType.Close });
	};
}

export default (state: PopUpState = {}, action: PopUpAction) => {
	switch (action.type) {
		case PopUpActionType.Show:
			return { ...action.payload };
		case PopUpActionType.Close:
			return { text: null };
		default:
			return state;
	}
};
