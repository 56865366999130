import React from 'react';
import { connect } from 'formik';
import { get } from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { FormaggioInputProps } from '../types';
import { ErrorMessage } from '../../core/components';

type RadioFieldProps = Omit<RadioGroupProps, 'onChange'> &
	FormaggioInputProps & {
		customChangeHandler?: any;
		onChange?: (e: any) => void;
	};

export function PureRadioGroupField({
	label,
	helperText,
	className,
	errorMessage,
	customChangeHandler,
	onChange,
	children,
	...rest
}: RadioFieldProps) {
	const handleChange = React.useCallback(
		(e) => {
			if (onChange) onChange(e);
			if (customChangeHandler) customChangeHandler(e);
		},
		[onChange, customChangeHandler],
	);

	return (
		<FormControl>
			{label && <FormLabel>{label}</FormLabel>}
			<RadioGroup onChange={handleChange} className={className} {...rest}>
				{children}
			</RadioGroup>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
			<ErrorMessage message={errorMessage} />
		</FormControl>
	);
}

function RadioGroupFieldConnected({ formik, name = '', ...rest }: RadioFieldProps) {
	return (
		<PureRadioGroupField
			customChangeHandler={formik.handleChange}
			value={get(formik.values, name, '') || ''}
			onBlur={formik.handleBlur}
			errorMessage={formik.errors[name]}
			name={name}
			{...rest}
		/>
	);
}

export default connect(RadioGroupFieldConnected);
