import { isAuthenticated } from '../../auth/helpers';
import asyncRequest from '../actions/asyncRequest';

export async function logUncaught(e: ErrorEvent) {
	if (!isAuthenticated()) return;

	try {
		await asyncRequest({
			query: 'Logger/Log',
			method: 'POST',
			params: {
				message: e.message,
				fileLocation: e.filename,
				line: e.lineno,
				col: e.colno,
				link: window.location.origin + window.location.pathname,
			},
		}).catch(() => undefined);
	} catch (e) {
		// Skip sending
	}
}
