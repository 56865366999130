import React from 'react';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { darkgrey, success, error } from '../../ui/Core/stylesheets/colors';

type ThreeStateThumbButtonProps = Omit<IconButtonProps, 'value' | 'onClick'> & {
	value?: boolean;
	onClick: (value: boolean) => void;
	disableDecline?: boolean;
};

export default function ThreeStateThumbButton({
	value,
	onClick,
	disabled,
	disableDecline,
	...rest
}: ThreeStateThumbButtonProps) {
	const clickApprove = React.useCallback(() => onClick(true), [onClick]);
	const clickDecline = React.useCallback(() => onClick(false), [onClick]);

	return (
		<div>
			<IconButton {...rest} disabled={!!value || disabled} onClick={clickApprove}>
				<ThumbUp htmlColor={value ? success : darkgrey} />
			</IconButton>
			{!disableDecline && (
				<IconButton {...rest} disabled={value === false || disabled} onClick={clickDecline}>
					<ThumbDown htmlColor={value === false ? error : darkgrey} />
				</IconButton>
			)}
		</div>
	);
}
