import React from 'react';
import MaterialSelect, { SelectProps } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export type SelectOption = React.OptionHTMLAttributes<unknown> & { title: string };

export const renderSelectOptions = ({ title, ...props }: SelectOption, idx: number) => (
	<MenuItem {...props} key={idx}>
		{title}
	</MenuItem>
);

export type NewSelectProps = SelectProps & {
	options: SelectOption[];
	noEmpty?: boolean;
	onChange?: any;
	emptyTitle?: string;
};

const DEFAULT_TITLE = '-';

export default function Select({
	options,
	multiple,
	noEmpty,
	emptyTitle = DEFAULT_TITLE,
	...rest
}: NewSelectProps) {
	return (
		<MaterialSelect multiple={multiple} {...rest}>
			{!multiple && !noEmpty && (
				<MenuItem value="" title={emptyTitle}>
					{emptyTitle}
				</MenuItem>
			)}
			{options.map(renderSelectOptions)}
		</MaterialSelect>
	);
}
