import React from 'react';
import Input from '@material-ui/core/Input';
import { convertToHumanTime } from '../../../core/helpers';
import { TIME_FORMAT } from '../../../core/constants/time';

export default function DateInput({ value, name, type, onChange, additional, ...rest }: any) {
	const v = name && value?.value ? value?.value[name] : value?.value;
	const dtFormat = type === 'date' ? TIME_FORMAT.STANDARD_DATE_ONLY : TIME_FORMAT.DATETIME_LOCAL;

	const transformedValue = React.useMemo(() => {
		if (v && Date.parse(v)) {
			const innerValue = new Date(v);
			if (innerValue.getTimezoneOffset() > 0) {
				innerValue.setDate(innerValue.getDate() + 1);
			}
			return convertToHumanTime(innerValue.toISOString(), dtFormat);
		}
		return '';
	}, [v, dtFormat]);

	return (
		<>
			{additional?.label && (
				<>
					{additional?.label}
					<br />
				</>
			)}
			<Input
				name={name}
				onChange={onChange}
				type={type || 'datetime-local'}
				value={transformedValue}
				{...rest}
			/>
		</>
	);
}
