import React from 'react';
import Card from '@material-ui/core/Card';
import { Box } from '@mui/material';
import { Heading } from '../core/components';
import { convertToHumanTime } from '../core/helpers';

type LookUpItemProps = {
	projectName?: string;
	projectStartDate?: string;
	children: React.ReactNode;
};

export default function LookUpItem({ projectName, projectStartDate, children }: LookUpItemProps) {
	return (
		<Card elevation={3} style={{ padding: '8px 16px', marginBottom: '8px' }}>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Heading variant="subtitle1" style={{ marginRight: '8px' }}>
					<b>{projectName}</b>
				</Heading>
				<Heading variant="subtitle2" color="textSecondary">
					Started {convertToHumanTime(projectStartDate)}
				</Heading>
			</Box>
			<div>{children}</div>
		</Card>
	);
}
