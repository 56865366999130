import React from 'react';
import { useToggle } from '../core/hooks';
import { GenericFile } from '../formaggio/types';
import { isValidMimeType } from './helpers';

type DragDropFileHookType = {
	setPreUpload?: (arg: boolean) => void;
	setFiles?: (arg: (prevFiles: GenericFile[]) => GenericFile[]) => void;
	onDropFiles?: (files: GenericFile[]) => void;
	accept?: string;
};

/**
 * @param setPreUpload - preUpload window state setter, sometimes not needed
 * @param setFiles
 * @param onDropFiles
 * @param accept
 */
export function useDragDropFile({
	setPreUpload,
	setFiles,
	onDropFiles,
	accept,
}: DragDropFileHookType) {
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const { isOn, setOn } = useToggle();

	const dragOver = React.useCallback(
		(e) => {
			e.preventDefault();
			setOn(true);
		},
		[setOn],
	);

	const dragLeave = React.useCallback(() => setOn(false), [setOn]);

	const dropFiles = React.useCallback(
		(e) => {
			e.preventDefault();
			setOn(false);
			const newFiles: GenericFile[] = Array.from(e.dataTransfer.files);
			const invalidFiles = newFiles.filter(({ type }) => !isValidMimeType(type, accept));

			if (invalidFiles.length > 0) {
				// eslint-disable-next-line no-alert
				alert(`Invalid file "${invalidFiles[0].name}"\r\nOnly ${accept} are permitted`);
				return;
			}

			if (setFiles) setFiles((prevFiles) => [...prevFiles, ...newFiles]);
			if (setPreUpload) setPreUpload(true);
			if (onDropFiles) onDropFiles(newFiles);
		},
		[setOn, setFiles, setPreUpload, onDropFiles, accept],
	);

	const openFileDialog = React.useCallback(() => {
		if (fileInputRef.current !== null) fileInputRef.current.click();
	}, [fileInputRef]);

	return {
		dragOver,
		dragLeave,
		dropFiles,
		openFileDialog,
		isCoverOn: isOn,
		fileInputRef,
	};
}
