import React from 'react';
import { css } from '@emotion/css/macro';
import { useFormikContext } from 'formik';
import { Add, Delete } from '@mui/icons-material';
import { PureTextField, TextField } from '../../formaggio';
import { FieldSet, IconButton } from '../../core/components';
import { SampleSetItem } from '../../../graphql/typescript';
import { error } from '../../ui/Core/stylesheets/colors';
import { useReportInfoContext } from '../../rocTemplates/context/ReportContext';
import SampledWarning from './SampledWarning';

const itemClass = css`
	display: flex;
	margin-top: 0.5rem;
	align-items: flex-start;
`;

const narrowFieldClass = css`
	width: 100px !important;
`;

export default function SampleSetAddUpdateForm() {
	const { version } = useReportInfoContext();
	const { values, setFieldValue } = useFormikContext<any>();
	const { items = [] } = values;

	const changeItems = React.useCallback(
		(value: any) => setFieldValue('items', value),
		[setFieldValue],
	);

	const changeItem = React.useCallback(
		(e, idx) => {
			const newItems = [...items];
			const currentItem: any = { ...(newItems[idx] || {}) };
			const inputName = e.target.name;
			currentItem[inputName] =
				inputName === 'totalSampled' || inputName === 'totalPopulation'
					? Math.min(Number(e.target.value), Number.MAX_SAFE_INTEGER)
					: e.target.value;
			newItems[idx] = currentItem;
			changeItems(newItems);
		},
		[changeItems, items],
	);

	const addItem = React.useCallback(
		() =>
			changeItems([
				...items,
				{ item: '', makeModelVersionRelease: '', totalSampled: 0, totalPopulation: 0 },
			]),
		[changeItems, items],
	);

	const deleteItem = React.useCallback(
		(idx) => {
			const newItems = [...items];
			newItems.splice(idx, 1);
			changeItems(newItems);
		},
		[changeItems, items],
	);

	const renderItems = React.useMemo(
		() =>
			items.map(
				(
					{ item, makeModelVersionRelease, totalSampled, totalPopulation }: SampleSetItem,
					idx: number,
				) => {
					const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
						e.persist();
						changeItem(e, idx);
					};
					const onDelete = () => deleteItem(idx);

					return (
						<div className={itemClass} key={idx}>
							<PureTextField
								variant="outlined"
								label="Description"
								name="item"
								InputLabelProps={{ shrink: true }}
								value={item}
								onChange={onChange}
								fullWidth
								multiline
							/>
							<PureTextField
								variant="outlined"
								label="Make/Model/Version/Release"
								name="makeModelVersionRelease"
								InputLabelProps={{ shrink: true }}
								value={makeModelVersionRelease}
								onChange={onChange}
								fullWidth
								multiline
							/>
							<PureTextField
								variant="outlined"
								label="Total Sampled"
								name="totalSampled"
								InputLabelProps={{ shrink: true }}
								value={totalSampled}
								onChange={onChange}
								className={narrowFieldClass}
								type="number"
								inputProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
								additionalChildren={
									<SampledWarning totalSampled={totalSampled} totalPopulation={totalPopulation} />
								}
							/>
							<PureTextField
								variant="outlined"
								label="Total Population"
								name="totalPopulation"
								InputLabelProps={{ shrink: true }}
								value={totalPopulation}
								onChange={onChange}
								className={narrowFieldClass}
								type="number"
								inputProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
							/>
							<IconButton onClick={onDelete}>
								<Delete htmlColor={error} />
							</IconButton>
						</div>
					);
				},
			),
		[items, changeItem, deleteItem],
	);

	return (
		<>
			<TextField fullWidth multiline label="Type / Description" name="description" />
			{version ? (
				<>
					<TextField
						fullWidth
						multiline
						label="Identify all items in the sample set"
						name="allItems"
					/>
					<TextField fullWidth multiline label="Selection method" name="selectionMethod" />
					<TextField
						label="Total Sampled"
						name="totalSampled"
						type="number"
						inputProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
						InputLabelProps={{ shrink: true }}
						fullWidth
						additionalChildren={
							<SampledWarning
								totalSampled={values.totalSampled}
								totalPopulation={values.totalPopulation}
							/>
						}
					/>
					<TextField
						label="Total Population"
						name="totalPopulation"
						type="number"
						inputProps={{ min: 0, max: Number.MAX_SAFE_INTEGER }}
						InputLabelProps={{ shrink: true }}
						fullWidth
					/>
				</>
			) : (
				<FieldSet legend="Items">
					{renderItems}
					<IconButton onClick={addItem}>
						<Add color="primary" />
					</IconButton>
				</FieldSet>
			)}
		</>
	);
}
