import React from 'react';
import { css } from '@emotion/css/macro';
import MaterialTextField from '@material-ui/core/TextField';
import { Box } from '@mui/material';
import { Button } from '../../core/components';
import { BasicForm, TextField } from '../../formaggio';
import { UserRole } from '../../auth/types';
import { ValidationErrors } from '../../formaggio/types';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { clipboardCopy } from '../../core/helpers';
import CompanyClientRoleSelector from './CompanyClientRoleSelector';
import { companyInviteRoles, projectInviteRoles } from '../../project/constants';

const centerClass = css`
	text-align: center;
`;

export type SendInvitationProps = {
	projectId?: string;
};

type CreateInvitationFormProps = { role: string };

const validate = (values: { role: UserRole }) => {
	const errors: ValidationErrors<CreateInvitationFormProps> = {};
	if (!values.role) errors.role = true;
	return errors;
};

function SendInvitation({ projectId }: SendInvitationProps) {
	const textFieldRef = React.useRef<HTMLInputElement>(null);

	const [link, setLink] = React.useState<string | undefined>(undefined);

	const backToInvitation = React.useCallback(() => {
		setLink(undefined);
	}, [setLink]);

	const copyToClipboard = () => {
		const area = textFieldRef && textFieldRef.current;
		if (area) {
			area.select();
			clipboardCopy(link);
		}
	};

	if (link) {
		return (
			<div className={centerClass}>
				<div>Your invitation link has been generated below</div>
				<MaterialTextField
					inputRef={textFieldRef}
					style={{ marginTop: '1rem' }}
					value={link}
					multiline
					fullWidth
				/>
				<Box mt={2} display="flex">
					<Button color="primary" onClick={copyToClipboard}>
						Copy to clipboard
					</Button>
					<Button color="secondary" onClick={backToInvitation}>
						Invite other person
					</Button>
				</Box>
			</div>
		);
	}

	return (
		<BasicForm
			fetchConfig={{
				query: 'Project/SendInvitation',
				method: 'POST',
				onSuccess: (result: ResponseType & { optionalMessage: string }) => {
					setLink(result.optionalMessage);
				},
				successMessage: true,
			}}
			validate={validate}
			initialValues={{ projectId }}
		>
			<CompanyClientRoleSelector
				selectableRoles={projectId ? projectInviteRoles : companyInviteRoles}
				isProjectInvite={!!projectId}
			/>
			<TextField
				name="email"
				label="E-mail"
				helperText="If not entered, a link will be generated in this window, otherwise sent to provided e-mail"
				type="email"
			/>
			<div className={centerClass}>
				<Button style={{ marginTop: '1rem' }} color="primary" type="submit">
					Invite
				</Button>
			</div>
		</BasicForm>
	);
}

type SendInvitationFlyOutProps = { isOn: boolean; close: () => void } & SendInvitationProps;

export default function SendInvitationFlyOut({
	isOn,
	close,
	projectId,
}: SendInvitationFlyOutProps) {
	return (
		<MaterialFlyOut open={isOn} onClose={close} title="Invite New User">
			<SendInvitation projectId={projectId} />
		</MaterialFlyOut>
	);
}
