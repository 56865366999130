import { cx, css } from '@emotion/css/macro';
import { getResponsibilityByFlag } from '../helpers';

const missingClass = css`
	color: red;
`;

const gutterBottomClass = css`
	padding-bottom: 8px;
`;

type ResponsibilityAreaItemProps = { item: number; isMissing?: boolean; gutterBottom?: boolean };

export default function ResponsibilityAreaItem({
	item,
	isMissing,
	gutterBottom,
}: ResponsibilityAreaItemProps) {
	const convertedItem = getResponsibilityByFlag(item);

	if (!convertedItem) return null;

	return (
		<li className={cx(isMissing && missingClass, gutterBottom && gutterBottomClass)}>
			{convertedItem.title}
			{convertedItem.description && (
				<>
					<br />
					<small>{convertedItem.description}</small>
				</>
			)}
		</li>
	);
}
