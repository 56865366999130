import React from 'react';
import Data from '../../UserData';
import SimplifiedFrame from '../../simplifiedControls/SimplifiedFrame';
import SimplifiedFlagsField from '../../simplifiedControls/SimplifiedFlagsField';
import { hasFlag } from '../../../core/helpers';
import { ControlOrigination } from './enums';
import { PureDateTimeField } from '../../../formaggio';

const IMPL_STATUS = 'implementationStatus';
const CTRL_ORIG = 'controlOrigination';
const INHERIT_DATE = 'controlInheritanceDate';

const implementationStatuses = [
	{ label: 'Implemented' },
	{ label: 'Partially Implemented' },
	{ label: 'Planned' },
	{ label: 'Alternative Implementation' },
	{ label: 'Not Applicable' },
];

const controlOriginations = [
	{ label: 'Service Provider Corporate' },
	{ label: 'Service Provider System Specific' },
	{ label: 'Service Provider Hybrid (Corporate and System Specific)' },
	{ label: 'Configured by Customer (Customer System Specific)' },
	{ label: 'Provided by Customer (Customer System Specific)' },
	{ label: 'Shared (Service Provider and Customer Responsibility)' },
	{ label: 'Inherited from pre-existing FedRAMP Authorization (enter date below)' },
];

export function FedRampControlSummaryInput({ onChange, value = {}, additional = {} }: any) {
	const changeValue = React.useCallback(
		(e) => {
			onChange({ ...value, [e.target.name]: e.target.value });
		},
		[onChange, value],
	);
	const disabled = additional.disabled || false;

	return (
		<SimplifiedFrame>
			<p>Implementation Status (check all that apply):</p>
			<SimplifiedFlagsField
				options={implementationStatuses}
				name={IMPL_STATUS}
				onChange={changeValue}
				value={value[IMPL_STATUS]}
				disabled={disabled}
				useOwnFlags
			/>
			<p>Control Origination (check all that apply):</p>
			<SimplifiedFlagsField
				options={controlOriginations}
				name={CTRL_ORIG}
				onChange={changeValue}
				value={value[CTRL_ORIG]}
				disabled={disabled}
				useOwnFlags
			/>
			{hasFlag(value[CTRL_ORIG], ControlOrigination.InheritedFromExistingFedRampAuthorization) && (
				<PureDateTimeField
					name={INHERIT_DATE}
					onChange={changeValue}
					value={value[INHERIT_DATE]}
					disabled={disabled}
					dateOnly
					label="Date of Authorization"
				/>
			)}
		</SimplifiedFrame>
	);
}

function retrievedValue(value: any) {
	return value;
}

function validation(value: any = {}) {
	if (!value[IMPL_STATUS]) return 'Status should be selected';
	if (!value[CTRL_ORIG]) return 'Origination should be selected';

	return undefined;
}

function getData(data: any = {}) {
	return {
		[IMPL_STATUS]: data[IMPL_STATUS],
		[CTRL_ORIG]: data[CTRL_ORIG],
		[INHERIT_DATE]: data[INHERIT_DATE],
	};
}

type FedRampControlSummaryProps = any;

export default function FedRampControlSummary({ data = [], ...rest }: FedRampControlSummaryProps) {
	return (
		<Data
			retrievedValue={retrievedValue}
			inputElement={FedRampControlSummaryInput}
			manualSave
			data={data}
			getData={getData}
			validation={validation}
			{...rest}
		/>
	);
}
