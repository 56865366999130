import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { Box } from '@mui/material';
import { FormaggioProps } from '../../formaggio/components/wrappers/BasicForm';
import MaterialForm, { MaterialFormProps } from './MaterialForm';
import { useBackdropClickHandler } from '../hooks';

type MaterialDialogProps = {
	isOpen?: boolean;
	onClose?: () => void;
	title?: React.ReactNode | string;
	children: React.ReactNode;
	actions?: React.ReactNode;
	topActions?: React.ReactNode;
	formProps?: Omit<FormaggioProps, 'children'> & MaterialFormProps;
	className?: string;
	contentClass?: string;
	onScroll?: any;
};

export default function MaterialDialog({
	isOpen = false,
	onClose = () => undefined,
	title,
	children,
	actions,
	topActions,
	formProps = {},
	className,
	contentClass,
	onScroll,
	...rest
}: MaterialDialogProps & Omit<DialogProps, 'open' | 'title'>) {
	const closeHandler = useBackdropClickHandler(onClose);

	return (
		<Dialog {...rest} open={isOpen} onClose={closeHandler} PaperProps={{ className }}>
			<MaterialForm {...formProps}>
				{title && (
					<DialogTitle>
						<Box display="flex" alignItems="center">
							{topActions && <Box marginRight="8px">{topActions}</Box>}
							{title}
						</Box>
					</DialogTitle>
				)}
				<DialogContent className={contentClass} onScroll={onScroll}>
					{isOpen && children}
				</DialogContent>
				{actions && <DialogActions>{isOpen && actions}</DialogActions>}
			</MaterialForm>
		</Dialog>
	);
}
