import React from 'react';
import { Form, Formik, FormikConfig } from 'formik';

export type FormBaseProps = Omit<FormikConfig<any>, 'onSubmit' | 'initialValues'> & {
	className?: string;
	initialValues?: any;
	children: React.ReactNode;
	onSubmit?: any;
	additional?: Record<string, unknown>;
	noResetOnSuccess?: boolean;
	transformValues?: (values: any) => Record<string, any>;
};

export default function FormBase({
	children,
	className,
	initialValues = {},
	onSubmit = () => undefined,
	...rest
}: FormBaseProps) {
	return (
		<Formik initialValues={initialValues} {...rest} onSubmit={onSubmit}>
			<Form className={className}>{children}</Form>
		</Formik>
	);
}
