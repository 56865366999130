const standardFileKeys = Object.keys(File);

type FormDataProps = {
	formData: FormData;
	key: string;
	data: any;
	idx?: number;
	increaseTotalUploadBytes?: (arg: number, fileName: string) => void;
};

function handleFormDataAddition({
	formData,
	key,
	data,
	idx,
	increaseTotalUploadBytes,
}: FormDataProps) {
	const hasIndex = idx !== undefined;
	const prefix = hasIndex ? `${key}[${idx}]` : key;

	if (data.constructor.name === 'File') {
		Object.keys(data).forEach((fileKey) => {
			if (standardFileKeys.indexOf(fileKey) < 0) {
				formData.append(prefix + '.' + fileKey, data[fileKey]);
			}
		});

		formData.append(prefix + '.file', data, data.name.replace(/ /g, '_'));

		if (increaseTotalUploadBytes) increaseTotalUploadBytes(data.size, data.name);
	} else if (typeof data === 'object')
		Object.keys(data).forEach((dataKey) => {
			handleFormDataAddition({
				formData,
				key: prefix + '[' + dataKey + ']',
				data: data[dataKey],
				increaseTotalUploadBytes,
			});
		});
	else formData.append(key, data);
	if (hasIndex) formData.append(`${key}.index`, String(idx));
}

const minWarningFileSizeBytes = 1024;

export function objectToFormData(values: Record<string, any>, maxUploadSizeMb?: number) {
	const formData = new FormData();

	let totalUploadBytes = 0;
	const increaseTotalUploadBytes = (arg: number, fileName: string) => {
		if (
			arg < minWarningFileSizeBytes &&
			// eslint-disable-next-line no-alert,no-restricted-globals
			!confirm(
				`Warning! File "${fileName}" is smaller than ${
					minWarningFileSizeBytes / 1024
				} kb, proceed at your own risk.\nNote: Uploading directly from ZIP/RAR/etc or strict antivirus settings might cause empty file transfer.`,
			)
		)
			throw new Error(`Uploading empty files canceled by user`);

		totalUploadBytes += arg;
	};

	Object.keys(values).forEach((key) => {
		const data = values[key];
		if (!data) return;

		switch (typeof data) {
			case 'object': {
				if (data.constructor.name === 'Array')
					data.forEach((item: any, idx: number) => {
						handleFormDataAddition({ formData, key, data: item, idx, increaseTotalUploadBytes });
					});
				else handleFormDataAddition({ formData, key, data, increaseTotalUploadBytes });

				break;
			}
			default:
				formData.append(key, data);
		}
	});

	if (maxUploadSizeMb && totalUploadBytes > maxUploadSizeMb * 1024 * 1024)
		throw new Error(`Files exceeded ${maxUploadSizeMb} MB limit`);

	return formData;
}
