import React from 'react';
import { LazyQueryResult, useLazyQuery } from '@apollo/client';
import {
	GetUserCompaniesQuery,
	GetUserCompaniesQueryVariables,
	CompanyInfo,
} from '../../graphql/typescript';
import { GET_USER_COMPANIES_QUERY } from '../../graphql/domains/company/queryList';
import { useAllowedMethods } from '../auth/hooks';

export function useUserCompanies(): [
	CompanyInfo[],
	LazyQueryResult<GetUserCompaniesQuery, GetUserCompaniesQueryVariables>,
] {
	const [load, query] = useLazyQuery<GetUserCompaniesQuery, GetUserCompaniesQueryVariables>(
		GET_USER_COMPANIES_QUERY,
	);

	const { 'Company/GetUserCompanies': canViewCompanies } = useAllowedMethods();

	React.useEffect(() => {
		if (canViewCompanies) load();
	}, [canViewCompanies, load]);

	return [query?.data?.list?.data || [], query];
}
