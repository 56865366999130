import { addWeeks, format } from 'date-fns';

export enum TIME_FORMAT {
	FULL = 'PPp',
	DATE_ONLY = 'PP',
	DATETIME_LOCAL = "yyyy-MM-dd'T'HH:mm",
	STANDARD = "yyyy-MM-dd'T'HH:mm:ssxxx",
	STANDARD_DATE_ONLY = 'yyyy-MM-dd',
}

export const fromDefaultDate = () => format(addWeeks(new Date(), -1), TIME_FORMAT.STANDARD);

export const untilDefaultDate = () => format(new Date(), TIME_FORMAT.STANDARD);

export const currentTimeZone = format(new Date(), 'xxx');

export function getXDaysFromCurrentDate(x: number) {
	return format(
		new Date(new Date().setDate(new Date().getDate() + x)).setHours(0, 0, 0, 0),
		TIME_FORMAT.STANDARD,
	);
}
