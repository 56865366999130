import { RocWarningType, RocWarningLevel } from './enums';

export const rocQaStatuses = {
	[RocWarningType.ShortNa]: {
		name: 'shortNa',
		title: 'Short N/A Reason',
		description: 'N/A reason should be at least 15 characters long',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.SampleSetSize]: {
		name: 'sampleSetSize',
		title: 'Sample Set Size',
		description: 'You should sample at least 2 items in each population',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.AqsaMentioned]: {
		name: 'aqsaMentioned',
		title: 'AQSA Mentioned',
		description: 'Associate QSAs should not be completing ROC items without oversight from a QSA.',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.NotStarted]: {
		name: 'notStarted',
		title: 'Item Not Started',
		description: 'Item must be completed or marked as N/A',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.PeerReviewRequired]: {
		name: 'peerReviewRequired',
		title: 'Peer Review Required',
		description: 'Item must be accepted by you peer reviewer',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.AttestationsOfScanCompliance]: {
		name: 'attestationsOfScanCompliance',
		title: 'Scan Compliance',
		description: 'Attestations of Scan Compliance have to be completed',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.QuarterlyScanVulnerabilities]: {
		name: 'quarterlyScanVulnerabilities',
		title: 'Scan Vulnerabilities',
		description: "The most recent scan must be marked as 'NO' (no vulnerabilities found).",
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.LastQuarterlyScan]: {
		name: 'lastQuarterlyScan',
		title: 'Old last Scan',
		description: 'Most recent scan must be done within the last (3 months + 7 days).',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.BusinessOverview]: {
		name: 'businessOverview',
		title: 'Business Overview',
		description: 'Business Overview information must be provided',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.AdditionalServices]: {
		name: 'additionalServices',
		title: 'Additional Services',
		description: 'Information about Additional Services must be provided.',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.UseOfSubcontractors]: {
		name: 'useOfSubcontractors',
		title: 'Subcontractors',
		description: 'Subcontractors are used. You must identify all of them.',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.RemoteTestingActivity]: {
		name: 'remoteTestingActivity',
		title: 'Remote Testing',
		description:
			'Remote Testing methods were used for the assessment. You must provide the details.',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.MissingChannelResponse]: {
		name: 'missingChannelResponse',
		title: 'Missing Channel Response',
		description: 'There must be a response for each one or all payment channels.',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.SegmentationUsed]: {
		name: 'segmentationUsed',
		title: 'Segmentation Used',
		description:
			'Describe how segmentation is implemented, confirmed environments out of scope and the assessor who attests that the segmentation is in place and working properly',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.SegmentationNotUsed]: {
		name: 'segmentationNotUsed',
		title: 'Segmentation Not Used',
		description:
			'Provide the name of the assessor who attests that the entire network has been included in the scope of the assessment',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.ProductsAndSolutions]: {
		name: 'productsAndSolutions',
		title: 'Products & Solutions',
		description:
			"Provide information regarding items the organization uses from PCI SSC's Lists of Validated Products and Solutions",
		level: RocWarningLevel.Error,
	},
	[RocWarningType.SamplingIsUsed]: {
		name: 'samplingIsUsed',
		title: 'Sampling Is Used',
		description:
			'You have to describe the samples. How samples are appropriate and representative of the overall populations',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.SamplingNotUsed]: {
		name: 'samplingNotUsed',
		title: 'Sampling Is Not Used',
		description:
			'You have to provide the name of the assessor who attests that every item in each population has been assessed',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.StandardizedProcessesControls]: {
		name: 'StandardizedProcessesControls',
		title: 'Standardized Processes & Controls',
		description:
			'You have to describe how the processes and controls were validated by the assessor to be in place and effective',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.RequirementStateNotSelected]: {
		name: 'RequirementStateNotSelected',
		title: 'Requirement state is not selected',
		description: 'Requirement state needs to be selected',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.RequirementDescriptionEmpty]: {
		name: 'RequirementDescriptionEmpty',
		title: 'Requirement description is empty',
		description: 'Describe why the assessment finding was selected',
		level: RocWarningLevel.Warning,
	},
	[RocWarningType.DateRangeError]: {
		name: 'DateRangeError',
		title: 'Date range error',
		description: 'The Start Date must be earlier than the End',
		level: RocWarningLevel.Error,
	},
	[RocWarningType.DateRangeIncomplete]: {
		name: 'DateRangeIncomplete',
		title: 'Date range is incomplete',
		description: 'The Start or End date is missing',
		level: RocWarningLevel.Error,
	},
};

export const rocQaColorCoding = {
	[RocWarningLevel.Critical]: {
		color: '#800517',
	},
	[RocWarningLevel.Error]: {
		color: '#F62817',
	},
	[RocWarningLevel.Warning]: {
		color: '#DAA520',
	},
	[RocWarningLevel.Suggestion]: {
		color: '#6698FF',
	},
};

export const onlyReportLevelWarnings =
	RocWarningType.NotStarted + RocWarningType.PeerReviewRequired;
