import React from 'react';
import { css } from '@emotion/css/macro';
import { Button } from '../core/components';

type QuickSnippetProps = { text: string; clickPreset: (arg: string) => void };

function QuickSnippet({ text, clickPreset }: QuickSnippetProps) {
	const onClick = React.useCallback(() => clickPreset(text), [clickPreset, text]);

	return (
		<Button size="small" variant="outlined" onClick={onClick}>
			{text}
		</Button>
	);
}

export type QuickSnippetsConfig = { presets: string[]; replaceMode?: boolean };

const rootClass = css`
	display: flex;
	flex-wrap: wrap;
`;

type QuickSnippetsProps = QuickSnippetsConfig & {
	name?: string;
	value: string;
	onChange: (e: any) => void;
};

function QuickSnippets({ presets, replaceMode, name, value, onChange }: QuickSnippetsProps) {
	const clickPreset = React.useCallback(
		(text) => onChange({ target: { name, value: replaceMode ? text : value + ' ' + text } }),
		[onChange, name, replaceMode, value],
	);

	return (
		<div className={rootClass}>
			{presets
				.filter((text) => text !== value)
				.map((text) => (
					<QuickSnippet clickPreset={clickPreset} text={text} key={text} />
				))}
		</div>
	);
}

export default React.memo(QuickSnippets);
