import React from 'react';
import { Global } from '@emotion/react/macro';
import { css } from '@emotion/css/macro';
import { Navigate, Route, Routes } from 'react-router-dom';
import Auth from './features/Auth';
import ForgotPassword from './features/ForgotPassword';
import ResetPassword from './features/ResetPassword';
import SetPassword from './features/SetPassword';
import Portal from './features/Portal';
import UserActivation from './features/UserActivation';
import LockoutEnd from './features/LockoutEnd';
import UserRegistration from './features/UserRegistration';
import ClientUserRegistration from './features/ClientUserRegistration';
import Public from './features/Public';
import PopUp from './modules/popup/components/PopUp';
import MainLoader from './modules/loading/MainLoader';
import globalStyle from './modules/ui/Core/stylesheets/global';
import CookieNotification from './modules/legal/CookieNotification';
import { ROUTER_PATHS } from './modules/core/constants/paths';
import { GlobalFooter } from './modules/core/components/GlobalFooter';
import { TempLogin } from './modules/request/TempLogin';
import Unsubscribe from './features/Unsubscribe';
import Impersonate from './features/Impersonate';

const mainClass = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

export default function App() {
	return (
		<>
			<Global styles={globalStyle} />
			<MainLoader />
			<PopUp />
			<CookieNotification />
			<div className={mainClass}>
				<React.Suspense fallback={null}>
					<Routes>
						<Route path="/" element={<Navigate to="/login" replace />} />
						<Route path="/login" element={<Auth />} />
						<Route path="/user/register" element={<UserRegistration />} />
						<Route path="/client/register" element={<ClientUserRegistration />} />
						<Route path="/user/activate" element={<UserActivation />} />
						<Route path="/user/lockout" element={<LockoutEnd />} />
						<Route path="/password/forgot" element={<ForgotPassword />} />
						<Route path="/password/reset" element={<ResetPassword />} />
						<Route path="/password/set" element={<SetPassword />} />
						<Route path="/temp-login" element={<TempLogin />} />
						<Route path={ROUTER_PATHS.POLICIES} element={<Public />} />
						<Route path="/unsubscribe" element={<Unsubscribe />} />
						<Route path="/impersonate" element={<Impersonate />} />
						<Route path="/*" element={<Portal />} />
					</Routes>
				</React.Suspense>
				<GlobalFooter />
			</div>
		</>
	);
}
