import { Save } from '@mui/icons-material';
import Button from '@material-ui/core/Button';
import { MediaFile } from '../../../graphql/typescript';
import { ModelItemIdProps } from '../../rocTemplates/types';
import { DateTimeField, FileField, FileForm, TextField } from '../../formaggio';
import { extractAttachmentName } from '../helpers';
import { FieldSet } from '../../core/components';
import { MAX_PROJECT_ATTACHMENT_SIZE_MB } from '../constants';
import { TIME_FORMAT } from '../../core/constants/time';

type AttachmentWithEditProps = MediaFile &
	Pick<ModelItemIdProps, 'modelId'> & { refetch: () => void };

export default function AttachmentEditForm({
	modelId,
	toggleFormOff,
	refetch,
	...rest
}: AttachmentWithEditProps & { toggleFormOff: () => void }) {
	return (
		<FieldSet legend="Edit project file">
			<FileForm
				url={`Evidences/UpdateAttachment/${modelId}`}
				onUploadCompleted={() => {
					toggleFormOff();
					refetch();
				}}
				initialValues={rest}
				maxUploadSizeMb={MAX_PROJECT_ATTACHMENT_SIZE_MB}
			>
				{rest.webUrl ? (
					<TextField label="Url" name="webUrl" fullWidth />
				) : (
					<p>{extractAttachmentName(rest.fileName)}</p>
				)}
				<TextField label="Description" name="description" fullWidth />
				<TextField label="Version" name="version" fullWidth />
				<DateTimeField
					format={TIME_FORMAT.DATE_ONLY}
					label="Document Date"
					name="actualDateTime"
					dateOnly
				/>
				<FileField
					noDragInstruction
					name="files"
					instruction={
						<small>
							Click or drag a replacement here if needed
							<br />
							File will be replaced in all related controls
						</small>
					}
				/>
				<Button variant="text" startIcon={<Save />} type="submit" size="small">
					Save
				</Button>
			</FileForm>
		</FieldSet>
	);
}
