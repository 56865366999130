import { css } from '@emotion/css/macro';
import SimpleLink from './SimpleLink';
import { mainLight } from '../../ui/Core/stylesheets/colors';
import { getContrastText } from '../../ui/Core/helpers';

const footerClass = css`
	padding: 0.75rem;
	text-align: center;
`;

const footerText = css`
	font-size: 10px;
	margin: 0;
`;

const footerImage = css`
	margin-top: 4px;
	color: ${getContrastText(mainLight)};
`;

const Footer = (
	<footer className={footerClass}>
		<div className={footerText}>COPYRIGHT</div>
		<SimpleLink href="https://turboqsa.com">
			<svg
				className={footerImage}
				xmlns="http://www.w3.org/2000/svg"
				width="30px"
				viewBox="0 0 145.5 149.8"
				fill="currentColor"
			>
				<path d="M87.5.1a82.2 82.2 0 0 0-63.3 32.5C11.2 48.3.5 67.6.2 88.4c2.6-2 5.6-2.8 8.5-4.5 6-2.8 6-10 9-18.5 7-17.1 18.4-37.1 33.8-47.7l4-2.7a54.5 54.5 0 0 1 54.7 4.3c16.5 14 23.2 37 21.9 58a92.2 92.2 0 0 1-17.4 47.2c-2.9-5.2-6.3-11-12.8-9.6a800 800 0 0 0-7.1 5.3c-7 4 .5 3.3 3.5 6.9 1.9 2.8 3.6 5.8 5.4 8.8a63 63 0 0 1-4.2 3 34 34 0 0 1-20.2 1c-16.5-4-26.8-19.9-31-35.4-7-25-.8-55.9 20.7-71 4.1-1.8 4.8-1.8 7-2.1 14 .1 25.5 7.8 27 30 2.8 18.3-6.3 46.4-21 52.1-.2.2-2.4.7-2.5.8A26.8 26.8 0 0 1 67.6 97a36.9 36.9 0 0 1 7.7-30.8c2.5 3.5 3.3 8.3 4.1 12.7 4.8 10 15.6-.6 14-9.2-2-8.9-9.9-17.7-19.2-12.4a39.7 39.7 0 0 0-15 45.5c3.3 7.7 7 13.7 15.4 20.4 13.4-.4 24.2-11 30-22.2a85.2 85.2 0 0 0 8.7-50 35 35 0 0 0-36.4-27c-12.8 1.4-20.5 8.3-27.2 16.7A79.7 79.7 0 0 0 33 95.8c1.3 17.6 8.2 36 22.6 46.9 9.7 7.5 23.2 9 34.4 4.2a120 120 0 0 0 15.7-8.1c2.9 4.1 6.3 7.7 11.3 9.4 8.6 3.7 18.2-.5 24.8-6.1 7.3-4.9-3.7-1.5-7-1.2-7.3.2-13.4-5-17-11 2.6-2.3 5-4.7 7.4-7.3 22.1-27 27.6-68.7 8.4-98.9C124.1 7.8 105.6.1 87.5.1z" />
			</svg>
		</SimpleLink>
		<div className={footerText}>{new Date().getFullYear()}</div>
	</footer>
);

export default Footer;
