import React from 'react';
import AttachmentData from '../AttachmentData';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import SampleSetData from '../sampleSets/SampleSetData';

type SystemEvidenceDataProps = any;

export default function SystemEvidenceData({
	componentsReviewed,
	description,
	sampleSetIds = [],
	...rest
}: SystemEvidenceDataProps) {
	const { attachments = [] } = useReportContext();
	const renderSampleSets = React.useMemo(
		() =>
			attachments
				.filter(({ id }) => sampleSetIds.indexOf(id) >= 0)
				.map((sampleSet: any, idx) => <SampleSetData {...sampleSet} key={idx} />),
		[attachments, sampleSetIds],
	);

	return (
		<AttachmentData {...rest}>
			{componentsReviewed && <p>Components Reviewed: {componentsReviewed}</p>}
			{renderSampleSets}
			{description && <p>Evidence Description: {description}</p>}
		</AttachmentData>
	);
}
