import { useCallback } from 'react';

type RepeaterProps = {
	items: any;
	component: any;
	itemProps?: {};
	keyFunction?: (item: any, id: string) => void;
};

export default function Repeater({
	items,
	component: Component,
	itemProps,
	keyFunction,
}: RepeaterProps) {
	const renderItem = useCallback(
		(item, id) => {
			let transformedItem = item;
			if (typeof item === 'string') {
				transformedItem = { text: item };
			}
			const key = typeof keyFunction === 'function' ? keyFunction(item, id) : id;
			return <Component {...transformedItem} {...itemProps} key={key} />;
		},
		[Component, itemProps, keyFunction],
	);

	if (!items || !items.length) {
		return null;
	}

	return items.map(renderItem);
}
