import { css } from '@emotion/css/macro';
import MaterialTextField from '@material-ui/core/TextField';
import { FileField, FileForm, PureDateTimeField } from '../../formaggio';
import FileList from './FileList';
import { Button, Heading } from '../../core/components';
import { ModelItemIdProps } from '../../rocTemplates/types';
import WebUrlList, { validateWebUrl } from './WebUrlList';
import { TIME_FORMAT } from '../../core/constants/time';
import { MAX_PROJECT_ATTACHMENT_SIZE_MB } from '../constants';
import { useProjectAttachments } from '../helpers';
import { RocDataType } from '../../rocTemplates/enums';

const marginBottomClass = css`
	margin-bottom: 16px !important;
`;

const fileDescriptionFieldClass = css`
	margin-bottom: 8px !important;
`;

const fileFieldClass = css`
	display: flex;
	flex-direction: column;
	margin: 8px 0;
`;

export function EvidenceDescriptionFields({ onChange, storage = {}, itemId }: any) {
	return (
		<div className={fileFieldClass}>
			<MaterialTextField
				label="Description"
				name="description"
				onChange={onChange}
				className={fileDescriptionFieldClass}
				value={storage.description || ''}
				error={!storage.description}
			/>
			{!!itemId && (
				<MaterialTextField
					label="Evidence location"
					name="evidenceLocation"
					onChange={onChange}
					className={fileDescriptionFieldClass}
					value={storage.evidenceLocation || ''}
				/>
			)}
			<PureDateTimeField
				format={TIME_FORMAT.DATE_ONLY}
				label="Document Date"
				value={storage.actualDateTime || new Date()}
				onChange={onChange}
				className={fileDescriptionFieldClass}
				name="actualDateTime"
				dateOnly
			/>
			<MaterialTextField
				label="Version"
				name="version"
				onChange={onChange}
				value={storage.version || ''}
			/>
		</div>
	);
}

function validate(values: any) {
	const errors: any = {};
	const { webUrls = [], newAttachments = [] } = values;

	if (
		webUrls.length > 0 &&
		webUrls.some(({ webUrl, description }: any) => !validateWebUrl(webUrl) || !description)
	)
		errors.webUrls = true;

	if (
		newAttachments.length > 0 &&
		newAttachments.some(({ description }: any) => !String(description || '').trim())
	)
		errors.newAttachments = true;

	return errors;
}

function transform(values: any) {
	const result = { ...values };
	const files = result?.newAttachments || [];
	const urls = result?.webUrls || [];
	result.newAttachments = [...files, ...urls];
	if (result.webUrls) delete result.webUrls;

	return result;
}

export type EvidenceAttachments = Record<string, string>;

type EvidenceCreateProps = {
	onCompleted: () => void;
	className?: string;
	paymentChannelId?: string;
	accept?: string;
	isSampleSetTrackingSheet?: boolean;
} & Partial<ModelItemIdProps>;

export default function EvidenceCreate({
	modelId,
	itemId,
	onCompleted,
	className,
	paymentChannelId,
	accept,
	isSampleSetTrackingSheet,
}: EvidenceCreateProps) {
	const { list } = useProjectAttachments(
		modelId || '',
		RocDataType.MediaFile,
		!!isSampleSetTrackingSheet,
	);

	return (
		<FileForm
			url={isSampleSetTrackingSheet ? 'SampleSets/UploadTrackingSheet' : 'Evidences/Create'}
			className={className}
			onUploadCompleted={onCompleted}
			initialValues={{
				paymentChannelId,
				[isSampleSetTrackingSheet ? 'sampleSetId' : 'rocItemId']: itemId,
				projectId: modelId,
			}}
			validate={isSampleSetTrackingSheet ? undefined : validate}
			transformValues={transform}
			maxUploadSizeMb={MAX_PROJECT_ATTACHMENT_SIZE_MB}
		>
			<div>
				{list && list.length > 0 && !!itemId && (
					<>
						<Heading style={{ marginTop: '0.5rem' }}>Project Files:</Heading>
						<FileList accept={accept} list={list} />
					</>
				)}
				{!accept && <WebUrlList />}
				<FileField
					name={isSampleSetTrackingSheet ? 'attachment' : 'newAttachments'}
					className={marginBottomClass}
					multiple={!isSampleSetTrackingSheet}
					fileFields={isSampleSetTrackingSheet ? undefined : EvidenceDescriptionFields}
					fileFieldsProps={{ itemId }}
					accept={accept}
					instruction={
						isSampleSetTrackingSheet
							? 'Tracking sheet Excel file only, 250 MB max'
							: 'Max 250 MB in total'
					}
				/>
				<Button type="submit" color="primary">
					Submit
				</Button>
			</div>
		</FileForm>
	);
}
