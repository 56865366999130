import { Licensing } from '../core/types/api';
import { FeatureFlagDetails } from '../license/featureFlags';

export type LicenseStatus = {
	info?: string;
	startsOn: string;
	expiresOn: string;
	maxServerInstances: number;
	features: string[];
	featuresDetailed: FeatureFlagDetails;
	userLimits: { type: string; current: number; max: number }[];
	responseSource: string;
	build?: string;
};

export const EmptyLicenseStatus: LicenseStatus = {
	expiresOn: '9999-12-31',
	startsOn: '0000-01-01',
	maxServerInstances: 0,
	features: [],
	featuresDetailed: {},
	userLimits: [],
	responseSource: 'Unknown',
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
	return value !== null && value !== undefined;
}

// Convert hub message into license status
export function stringToLicenseStatus(s: string): LicenseStatus {
	if (s == null) return EmptyLicenseStatus;

	const licensing: Licensing = JSON.parse(s);

	return {
		info: licensing.info,
		expiresOn: licensing.expiresOn || EmptyLicenseStatus.expiresOn,
		startsOn: licensing.startsOn || EmptyLicenseStatus.startsOn,
		maxServerInstances: licensing.maxServerInstances || EmptyLicenseStatus.maxServerInstances,
		features: licensing.features,
		featuresDetailed: licensing.featuresDetailed,
		userLimits: [
			{
				type: 'Global Admin',
				current: licensing.gaCount ?? 0,
				max: licensing.maxUsersGACount ?? Number.MAX_SAFE_INTEGER,
			},
			{
				type: 'QSA',
				current: licensing.qsaCount ?? 0,
				max: licensing.maxUsersQSACount ?? Number.MAX_SAFE_INTEGER,
			},
			{
				type: 'Non-QSA',
				current: licensing.nonQSACount ?? 0,
				max: licensing.maxUsersNonQSACount ?? Number.MAX_SAFE_INTEGER,
			},
		],
		responseSource: licensing.responseSource || 'Unknown',
		build: licensing.build,
	};
}

export function checkUserLimit(license: LicenseStatus): {
	valid: boolean;
	error?: string | undefined;
} {
	if (license == null || license.userLimits == null || license.userLimits.length === 0)
		return { valid: true };

	const violations = license.userLimits
		.map((l) => {
			if (l.max > 0 && l.max < l.current)
				return `${l.type} user count exceeded: ${l.current}, allowed: ${l.max}.`;
			return null;
		})
		.filter(notEmpty);

	if (violations.length > 0) return { valid: false, error: violations.join(' ') };

	return { valid: true };
}

export function checkLicenseExpiration(license: LicenseStatus): {
	valid: boolean;
	error?: string | undefined;
} {
	if (
		license == null ||
		license.expiresOn == null ||
		license.expiresOn === EmptyLicenseStatus.expiresOn
	)
		return { valid: true };

	// TODO: timezone shift?

	const exceededDate = new Date(license.expiresOn);
	const remainingMs = exceededDate.getTime() - new Date().getTime();

	if (remainingMs < 0 || Number.isNaN(remainingMs))
		return { valid: false, error: 'Your license expired, please contact TurboQSA for renewal.' };

	const remainingDays = Math.floor(remainingMs / (24 * 60 * 60 * 1000));

	if (remainingDays === 0)
		return {
			valid: false,
			error: `Your license expires today, please contact TurboQSA for renewal.`,
		};
	if (remainingDays <= 30)
		return {
			valid: false,
			error: `Your license expires in ${remainingDays} days, please contact TurboQSA for renewal.`,
		};

	return { valid: true };
}
