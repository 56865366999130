import React from 'react';
import { css, cx } from '@emotion/css/macro';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import {
	Add,
	Close,
	ContentCopy,
	ContentPaste,
	ContentPasteGo,
	DeleteForever,
	Edit,
	Search,
	Tag,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { RocItem } from './types';
import { rocItemTypes } from './constants';
import { viewStyleInformationToClassName } from './constants/styles';
import ItemAddOrEdit from './TemplateEditor/components/ItemAddOrEdit';
import { toStringPath } from './TemplateEditor/helpers';
import './stylesheets/roc.css';
import { IconButton } from '../core/components';
import { RocItemType } from './enums';
import { TypeItemContextWrapper } from './context/ItemContext';
import { useReportContext } from './context/ReportContext';
import { clipboardCopy } from '../core/helpers';

const elementClass = css`
	padding: 0 5px 2px 5px;
	margin-bottom: 3px;
	transition: border 0.2s;
`;

const elementControlClass = css`
	font-size: 0.8rem;
	color: grey;
	display: flex;
	max-height: 18px;
`;

const componentWrapperClass = css`
	display: flex;
`;

const closeButtonClass = css`
	margin-left: 0.5rem !important;
`;

const dialogContentClass = css`
	padding: 1rem 24px !important;
`;

const STORED_EDITOR_OBJECT_KEY = 'storedEditorObject';

type ReplicatorSharedProps = {
	fetchAPI?: any;
	viewMode?: boolean;
	wrapperComponent?: React.ComponentType<any>;
	isArrayChild?: boolean;
} & RocItem;

function EmptyItem() {
	return <></>;
}

function DebugItem(props: any = {}) {
	return (
		<Box bgcolor="firebrick" color="white" padding="8px">
			Unknown item type {props?.type || props?.props?.type} to render at:{' '}
			{JSON.stringify(props.path)}
		</Box>
	);
}

type EditorReplicatorProps = {
	loadItem: (id: string) => void;
	setSection?: (arg: any) => void;
	notDeleteable?: boolean;
	path: string[];
} & ReplicatorSharedProps;

export function EditorReplicator({
	itemId,
	parentsIds = [],
	order,
	type = 7,
	children,
	fetchAPI,
	notDeleteable,
	style,
	path = [],
	setSection,
	loadItem,
	subType,
	name,
	...rest
}: EditorReplicatorProps) {
	const { refresh, projectId: templateId } = useReportContext();

	const Component = React.useMemo(
		() => (type === undefined ? EmptyItem : rocItemTypes[type].component),
		[type],
	);

	const renderChildren = React.useMemo(
		() =>
			children?.map(({ itemId, ...props }, elemIdx) => (
				<EditorReplicator
					{...props}
					fetchAPI={fetchAPI}
					key={itemId}
					path={[...path, `children[${elemIdx}]`]}
					setSection={setSection}
					loadItem={loadItem}
					itemId={itemId}
				/>
			)),
		[fetchAPI, path, setSection, loadItem, children],
	);

	const [modalState, setModalState] = React.useState({ isEditMode: false, isOpen: false });

	const closeModal = React.useCallback(() => {
		setModalState((oldMode) => ({ ...oldMode, isOpen: false }));
	}, [setModalState]);

	const addItem = React.useCallback(() => {
		setModalState({ isEditMode: false, isOpen: true });
	}, [setModalState]);

	const editItem = React.useCallback(() => {
		setModalState({ isEditMode: true, isOpen: true });
	}, [setModalState]);

	const copyObject = React.useCallback(() => {
		localStorage.setItem(
			STORED_EDITOR_OBJECT_KEY,
			JSON.stringify({ ...rest, children, type, subType, name, style }),
		);
	}, [rest, children, type, subType, name, style]);

	const pasteObject = React.useCallback(
		(inside?: boolean) => {
			const newParentsIds = inside ? [...parentsIds, itemId] : parentsIds;

			if (localStorage.getItem(STORED_EDITOR_OBJECT_KEY)) {
				fetchAPI({
					query: `RocItemEditor/add/${templateId}`,
					method: 'PUT',
					params: {
						...JSON.parse(localStorage.getItem(STORED_EDITOR_OBJECT_KEY) || ''),
						editorParentIds: newParentsIds,
						order: order + 10,
						id: undefined,
					},
					onSuccess: refresh,
				});
				// eslint-disable-next-line no-alert
			} else alert('No item in clipboard');
		},
		[parentsIds, itemId, fetchAPI, templateId, order, refresh],
	);

	const pasteNextToObject = React.useCallback(() => pasteObject(), [pasteObject]);
	const pasteInsideContainer = React.useCallback(() => pasteObject(true), [pasteObject]);

	const deleteItem = React.useCallback(() => {
		fetchAPI({
			query: `RocItemEditor/delete/${templateId}/${itemId}`,
			method: 'DELETE',
			onSuccess: refresh,
			confirmation: true,
		});
	}, [fetchAPI, templateId, itemId, refresh]);

	const [showControls, setShowControls] = React.useState(false);

	const viewStyleCSS = React.useMemo(() => viewStyleInformationToClassName(style), [style]);

	return (
		<div
			className={cx(
				elementClass,
				css`
					border: 1px solid ${showControls ? 'red' : 'grey'};
				`,
			)}
			onMouseEnter={() => setShowControls(true)}
			onMouseLeave={() => setShowControls(false)}
		>
			<div className={elementControlClass}>
				<Tooltip
					title={
						<>
							<div>Path: {toStringPath(path)}</div>
							{JSON.stringify({ itemId, type, subType, style })}
						</>
					}
				>
					<div>
						{rocItemTypes[type].name}
						{name && ` - ${name} - `}
						{(order || order === 0) && `#${order}`}
					</div>
				</Tooltip>
				{showControls && (
					<>
						{typeof children !== 'undefined' && (
							<Tooltip title="Add inside this">
								<IconButton size="small" onClick={addItem}>
									<Add className={elementControlClass} />
								</IconButton>
							</Tooltip>
						)}
						<IconButton size="small" onClick={editItem}>
							<Edit className={elementControlClass} />
						</IconButton>
						<Tooltip title="Copy">
							<IconButton size="small" onClick={copyObject}>
								<ContentCopy className={elementControlClass} />
							</IconButton>
						</Tooltip>
						<Tooltip title="Copy ID">
							<IconButton size="small" onClick={() => clipboardCopy(itemId)}>
								<Tag className={elementControlClass} />
							</IconButton>
						</Tooltip>
						{path.length > 1 && (
							<>
								{(type === RocItemType.Container || type === RocItemType.Array) && (
									<>
										{type === RocItemType.Container && (
											<Tooltip title="View">
												<IconButton size="small" onClick={() => loadItem(itemId)}>
													<Search className={elementControlClass} />
												</IconButton>
											</Tooltip>
										)}
										<Tooltip title="Paste inside this">
											<IconButton size="small" onClick={pasteInsideContainer}>
												<ContentPaste className={elementControlClass} />
											</IconButton>
										</Tooltip>
									</>
								)}
								{!notDeleteable && (
									<>
										<Tooltip title="Paste next to this">
											<IconButton size="small" onClick={pasteNextToObject}>
												<ContentPasteGo className={elementControlClass} />
											</IconButton>
										</Tooltip>
										<Tooltip title="Delete">
											<IconButton size="small" onClick={deleteItem}>
												<DeleteForever className={elementControlClass} />
											</IconButton>
										</Tooltip>
									</>
								)}
							</>
						)}
					</>
				)}
			</div>
			<Dialog fullScreen open={modalState.isOpen} onClose={closeModal}>
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6">{modalState.isEditMode ? 'Edit Item' : 'Add Item'}</Typography>
						<IconButton className={closeButtonClass} onClick={closeModal}>
							<Close htmlColor="white" />
						</IconButton>
					</Toolbar>
				</AppBar>
				<DialogContent className={dialogContentClass} dividers>
					<ItemAddOrEdit
						{...rest}
						isEditMode={modalState.isEditMode}
						templateId={templateId}
						order={order}
						lastNumber={children && children.length}
						type={type}
						closeModal={closeModal}
						style={style}
						refresh={refresh}
						parentsIds={parentsIds}
						subType={subType}
						name={name}
						itemId={itemId}
					>
						{children}
					</ItemAddOrEdit>
				</DialogContent>
			</Dialog>
			<div className={type === RocItemType.Array ? undefined : componentWrapperClass}>
				<Component
					{...(style?.attributes || {})}
					{...rest}
					fetchAPI={fetchAPI}
					path={path}
					modelId={templateId}
					refresh={refresh}
					className={viewStyleCSS}
					subType={subType}
					name={name}
					type={type}
					itemId={itemId}
				>
					{children && renderChildren}
				</Component>
			</div>
		</div>
	);
}

type ClientReplicatorProps = {
	isHiddenInReport?: boolean;
} & ReplicatorSharedProps;

export function ClientReplicator({
	type,
	fetchAPI,
	children,
	style,
	isHiddenInReport,
	wrapperComponent,
	...rest
}: ClientReplicatorProps) {
	const Component = React.useMemo(() => {
		let component: any;

		try {
			component = rocItemTypes[type].component;
		} catch (e) {
			component = DebugItem;
		}

		return component;
	}, [type]);

	const WrapperComponent: any = React.useMemo(
		() => wrapperComponent || React.Fragment,
		[wrapperComponent],
	);

	const renderChildren = React.useMemo(() => {
		let result;
		try {
			result = children?.map(({ itemId, ...props }) => (
				<ClientReplicator fetchAPI={fetchAPI} key={itemId} itemId={itemId} {...props} />
			));
		} catch (e) {
			result = <div>{JSON.stringify(e)}</div>;
		}
		return result;
	}, [children, fetchAPI]);

	const viewStyleCSS = React.useMemo(() => viewStyleInformationToClassName(style), [style]);

	if (isHiddenInReport) return null;

	return (
		<TypeItemContextWrapper type={type} {...rest}>
			<WrapperComponent {...(wrapperComponent ? { type, ...rest } : {})}>
				<Component
					fetchAPI={fetchAPI}
					className={viewStyleCSS}
					viewMode
					type={type}
					{...(style?.attributes || {})}
					{...rest}
				>
					{children && renderChildren}
				</Component>
			</WrapperComponent>
		</TypeItemContextWrapper>
	);
}
