import React from 'react';
import { css } from '@emotion/css/macro';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import { Delete, Quiz } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { useAllowedMethods, useReportAccess } from '../../auth/hooks';
import { CheckboxField, FileField, FileForm } from '../../formaggio';
import { Button } from '../../core/components';
import { WorkflowSharedProps } from './WorkflowList';
import Request from '../../request/Request';
import { useToggle } from '../../core/hooks';
import { error } from '../../ui/Core/stylesheets/colors';
import { ItemStatus } from '../../project/enums';
import { getAvatarUrl } from '../../core/helpers';
import { RolesEnum } from '../../auth/enums';
import FormDraftField from '../../draft/FormDraftField';
import {
	GetUsersOfProjectQuery,
	GetUsersOfProjectQueryVariables,
} from '../../../graphql/typescript';
import { GET_USERS_OF_PROJECT_QUERY } from '../../../graphql/domains/project/queryList';
import WorkflowStatusSelector from './WorkflowStatusSelector';
import { convertDraftToText } from '../../draft/helpers';
import { getProjectSubRoleNames } from '../../auth/helpers';
import { roleTitles } from '../../auth/constants';
import { MAX_PROJECT_ATTACHMENT_SIZE_MB } from '../../evidence/constants';

const rootClass = css`
	padding: 8px;
	position: sticky;
	bottom: 0;
	background: white;
`;

const topControlsClass = css`
	display: flex;
	align-items: center;
	margin: 8px 0;
`;

const submitButtonClass = css`
	margin-right: 8px !important;
`;

const hintClass = css`
	margin-top: 8px;
`;

const messageClass = css`
	margin-bottom: 8px;
`;

function transform(values: any = {}) {
	const newValues = { ...values };

	newValues.undraftedMessage = convertDraftToText(newValues.message);

	return newValues;
}

type WorkflowCreateProps = WorkflowSharedProps & {
	alreadyRequestedIds?: string[];
	onSuccess?: () => void;
	isBulk?: boolean;
	bulkStatusFilter?: ItemStatus;
};

export default function WorkflowCreate({
	modelId,
	itemId,
	refresh,
	alreadyRequestedIds,
	onSuccess,
	isBulk,
	itemResponsibility,
}: WorkflowCreateProps) {
	const { 'Workflow/Create': canCreateWorkflow } = useAllowedMethods();
	const [request, setRequest] = React.useState<Request | undefined>();
	const clearRequest = React.useCallback(() => setRequest(undefined), [setRequest]);
	const { isOn, toggleOn, toggleOff } = useToggle();

	const { role, isAQSA, isClient } = useReportAccess();

	const { data } = useQuery<GetUsersOfProjectQuery, GetUsersOfProjectQueryVariables>(
		GET_USERS_OF_PROJECT_QUERY,
		{
			variables: { projectId: modelId, includeContacts: true },
			skip: !canCreateWorkflow,
		},
	);
	const mappedSuggestions = React.useMemo(
		() =>
			(data?.list?.data || []).map(({ id, name, projectRole, subRole, email }) => ({
				id,
				name:
					name +
					' (' +
					(subRole ? getProjectSubRoleNames(subRole) + ' ' : '') +
					roleTitles[projectRole] +
					')',
				avatar: getAvatarUrl(email),
				email,
			})),
		[data?.list?.data],
	);

	if (!canCreateWorkflow) return null;

	return (
		<>
			<FileForm
				url="Workflow/Create"
				onUploadCompleted={() => {
					clearRequest();
					refresh();
					if (onSuccess) onSuccess();
				}}
				initialValues={{ visibleToCustomers: true, isBulk }}
				additional={{ projectId: modelId, itemId, request }}
				transformValues={transform}
				className={rootClass}
				maxUploadSizeMb={MAX_PROJECT_ATTACHMENT_SIZE_MB}
			>
				<Typography variant="caption">
					Use @ symbol to mention project participants or contacts
				</Typography>
				<FormDraftField
					name="message"
					className={messageClass}
					mentions={mappedSuggestions}
					placeholder="Type your message here..."
					editorKey={itemId}
				/>
				<FileField multiple name="attachments" />
				<div className={topControlsClass}>
					<Button color="primary" type="submit" className={submitButtonClass}>
						Submit
					</Button>
					{isClient && (
						<>
							<Button startIcon={<Quiz />} color="secondary" size="small" onClick={toggleOn}>
								Response Request
							</Button>
							{!!request && (
								<Button
									startIcon={<Delete htmlColor={error} />}
									size="small"
									onClick={clearRequest}
								>
									Clear
								</Button>
							)}
						</>
					)}
				</div>
				{(isAQSA || role === RolesEnum.TechnicalEditor) && !request && (
					<>
						<WorkflowStatusSelector isBulk={isBulk} />
						<CheckboxField name="visibleToCustomers" label="Customer visible entry" />
						{isAQSA && (
							<CheckboxField
								name="isWorkPaper"
								label="Worknote"
								helperText="Entry for workpaper, not visible to customers"
							/>
						)}
					</>
				)}
				{!!request && (
					<Alert severity="info" className={hintClass}>
						Request is attached. Click &quot;Submit&quot; to send. Optionally, enter a message that
						will be sent with the request.
					</Alert>
				)}
			</FileForm>
			{!isBulk && (
				<Request
					alreadyRequestedIds={alreadyRequestedIds}
					modelId={modelId}
					isOpen={isOn}
					onClose={toggleOff}
					setRequest={setRequest}
					itemResponsibility={itemResponsibility}
				/>
			)}
		</>
	);
}
