import React from 'react';
import { get } from 'lodash';
import { css } from '@emotion/css/macro';
import { Add, Delete } from '@mui/icons-material';
import { FieldSet, IconButton } from '../../../../core/components';
import { rowPresets, rowPresetsSelect } from '../../../constants/presetsNoFragment';
import ItemGeneralFields from '../ItemGeneralFields';
import { PureSelectField } from '../../../../formaggio';
import { toStringPath } from '../../helpers';

const flexClass = css`
	display: flex;
	margin-bottom: 0.5rem;
`;

export default function TableRowControls({ DTO, setDTO, path, onChange }: any) {
	const columnsFromDTO = React.useMemo(
		() => get(DTO, toStringPath([...path, 'children']), []),
		[DTO, path],
	);

	const addColumn = React.useCallback(() => {
		onChange({ name: 'children', value: [...columnsFromDTO, {}] });
	}, [onChange, columnsFromDTO]);

	const removeColumn = React.useCallback(
		(idx) => {
			const newCols = [...columnsFromDTO];
			newCols.splice(idx, 1);
			onChange({ name: 'children', value: newCols });
		},
		[onChange, columnsFromDTO],
	);

	// - Presets
	const [preset, setPreset] = React.useState('');

	const onPresetChange = React.useCallback(
		(e) => {
			setPreset(e.target.value);
			onChange({
				name: 'children',
				value: e.target.value === '' ? [] : rowPresets[e.target.value].preset,
			});
			onChange({
				name: 'subType',
				value: e.target.value === '' ? '' : rowPresets[e.target.value].name,
			});
		},
		[setPreset, onChange],
	);

	// - Render Options
	const renderColumnOptions = React.useCallback(
		(_, idx) => (
			<FieldSet key={idx}>
				<IconButton onClick={() => removeColumn(idx)}>
					<Delete />
				</IconButton>
				<ItemGeneralFields DTO={DTO} path={[...path, `children[${idx}]`]} setDTO={setDTO} />
			</FieldSet>
		),
		[setDTO, DTO, path, removeColumn],
	);

	return (
		<>
			<FieldSet legend="Columns">
				<PureSelectField
					customChangeHandler={onPresetChange}
					name="presetOptions"
					label="Preset"
					options={rowPresetsSelect}
					value={preset}
				/>
				<div className={flexClass}>{columnsFromDTO.map(renderColumnOptions)}</div>
				<IconButton onClick={addColumn}>
					<Add />
				</IconButton>
			</FieldSet>
		</>
	);
}
