import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { css } from '@emotion/css/macro';
import { Label } from '@mui/icons-material';
import { Contact } from '../../modules/contact/types';
import { flagSumToArray } from '../../modules/core/helpers';
import { mainDark } from '../../modules/ui/Core/stylesheets/colors';
import ResponsibilityAreaItem from '../../modules/contact/components/ResponsibilityAreaItem';

function renderResponsibilities(item: number, idx: number) {
	return <ResponsibilityAreaItem key={item + idx} item={item} />;
}

type ResponsibilitiesToolTipProps = {
	responsibilities?: number | null;
	children: React.ReactElement;
};

function ResponsibilitiesToolTip({ responsibilities, children }: ResponsibilitiesToolTipProps) {
	const extractedResponsibilities = React.useMemo(
		() => (responsibilities ? flagSumToArray(responsibilities) : []),
		[responsibilities],
	);

	if (!responsibilities) return null;

	return (
		<Tooltip
			title={
				<ul style={{ paddingInlineStart: '16px' }}>
					{extractedResponsibilities.map(renderResponsibilities)}
				</ul>
			}
		>
			{children}
		</Tooltip>
	);
}

const toolTipIconClass = css`
	font-size: 1.1rem;
	margin-right: 0.5rem;
`;

type ResponsibilitiesTooltipIconProps = Partial<Contact>;

export function ResponsibilitiesTooltipIcon({
	responsibilityArea,
}: ResponsibilitiesTooltipIconProps) {
	if (!responsibilityArea) return null;

	return (
		<>
			<ResponsibilitiesToolTip responsibilities={responsibilityArea}>
				<Label className={toolTipIconClass} htmlColor={mainDark} />
			</ResponsibilitiesToolTip>
		</>
	);
}
