import { Box } from '@mui/material';
import { CONFIG } from '../constants/config';
import SimpleLink from './SimpleLink';

export function GlobalFooter() {
	return (
		<Box
			color="white"
			padding="16px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			fontSize="12px"
		>
			TurboQSA {CONFIG.version}&nbsp;-&nbsp;{CONFIG.buildInfo}&nbsp;-&nbsp;
			<SimpleLink color="#fff" href="https://www.turboqsa.com/blog">
				New features and fixes
			</SimpleLink>
		</Box>
	);
}
