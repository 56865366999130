function getErrors(errors = {}) {
	const errArray = [];

	Object.keys(errors).forEach((key) => {
		if (errors[key].length > 0) {
			errArray.push(errors[key]);
		}
	});
	return errArray.join(' ');
}

export default function popUpError(errors) {
	return { text: getErrors(errors), color: 'firebrick' };
}
