import { Dispatch } from 'redux';
import { LoadingAction, LoadingState } from './types';

export function setLoading(state: boolean) {
	return (dispatch: Dispatch) => {
		dispatch({ type: 'LOADING_SET', payload: state });
	};
}

export default (state: LoadingState = {}, action: LoadingAction) => {
	switch (action.type) {
		case 'LOADING_SET':
			return { loading: action.payload };
		default:
			return state;
	}
};
