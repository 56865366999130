import React from 'react';
import { css, cx } from '@emotion/css/macro';
import MaterialTabs, { TabsProps } from '@material-ui/core/Tabs';
import MaterialTab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { CardHeader } from './Card';
import { mainDark } from '../../ui/Core/stylesheets/colors';

//Tab content
const tabClass = css`
	padding-top: 1rem;
`;

type TabProps = {
	component: React.ReactNode;
	pageTitle?: string;
	className?: string;
	title: string | React.ReactNode;
	id: string;
};

function Tab({ component, pageTitle, className }: Omit<TabProps, 'id'>) {
	return (
		<section>
			{pageTitle && (
				<CardHeader color={mainDark} isRounded>
					{pageTitle}
				</CardHeader>
			)}
			<div className={cx(tabClass, className)}>{component}</div>
		</section>
	);
}

//Conditional colored wrapper
type ConditionalWrapperProps = { colored?: boolean; children: React.ReactNode };

function ConditionalWrapper({ colored, children }: ConditionalWrapperProps) {
	if (!colored) return <>{children}</>;

	return <AppBar position="static">{children}</AppBar>;
}

//Tabs component
export type TabsArrayType = TabProps[];

type NewTabsProps = {
	tabs: TabsArrayType;
	initTab?: string | boolean;
	onSwitchTab?: (args: object) => void;
	manualActiveTab?: string | boolean;
	colored?: boolean;
	className?: string;
} & TabsProps;

export default function Tabs({
	tabs = [],
	manualActiveTab = false,
	initTab = false,
	onSwitchTab,
	colored,
	className,
}: NewTabsProps) {
	const [activeTab, setActiveTab] = React.useState<string | boolean>(initTab);

	React.useEffect(() => {
		if (manualActiveTab) setActiveTab(manualActiveTab);
	}, [setActiveTab, manualActiveTab]);

	const tabChange = React.useCallback(
		(_, newValue) => {
			if (onSwitchTab) onSwitchTab(newValue);
			setActiveTab(newValue);
		},
		[setActiveTab, onSwitchTab],
	);

	const render = React.useMemo(
		() => ({
			controls: tabs.map(({ title, id }: TabProps) => (
				<MaterialTab className={className} value={id} label={title} key={id} />
			)),
			content: tabs.reduce(
				(memo, { id, ...rest }) =>
					id === (manualActiveTab || activeTab) ? <Tab {...rest} key={id} /> : memo,
				<></>,
			),
		}),
		[activeTab, className, manualActiveTab, tabs],
	);

	return (
		<>
			<ConditionalWrapper colored={colored}>
				<MaterialTabs onChange={tabChange} value={activeTab || manualActiveTab}>
					{render.controls}
				</MaterialTabs>
			</ConditionalWrapper>
			{render.content}
		</>
	);
}
