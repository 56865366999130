import AttachmentData from '../AttachmentData';

type ObservationEvidenceDataProps = any;

export default function ObservationEvidenceData({
	description,
	process,
	...rest
}: ObservationEvidenceDataProps) {
	return (
		<AttachmentData {...rest}>
			Observed Process: {process}
			{description && (
				<>
					<br />
					<p>Description: {description}</p>
				</>
			)}
		</AttachmentData>
	);
}
