import { setLoading } from '../../loading/reducers';
import { signOut, checkAuth } from '../../auth/AuthReducers';
import { popUpShow } from '../../popup/reducers';
import { popUpError } from '../components';
import { ReduxDispatch } from './types';
import { FetchOptions } from './fetchAPI';
import { success } from '../../ui/Core/stylesheets/colors';
import InternalErrorMessage from '../components/InternalErrorMessage';

type ErrorHandlerProps = {
	dispatch: ReduxDispatch;
	result: any;
	fetchOptions: FetchOptions;
};

export default function globalFetchHandler({ result, dispatch, fetchOptions }: ErrorHandlerProps) {
	const options = { errorMessage: true, ...fetchOptions };
	let errorHappened = false;
	if (options.onResponse) options.onResponse(result);
	switch (result.status) {
		case 303:
			dispatch(checkAuth());
			break;
		case 401:
			dispatch(setLoading(false));
			dispatch(signOut());
			return { continueRequest: false, errorHappened: true };
		case 403:
			dispatch(
				popUpShow({
					state: {
						text: `Insufficient permissions to call ${options.url}`,
						color: 'firebrick',
					},
				}),
			);
			errorHappened = true;
			break;
		case 408:
			dispatch(
				popUpShow({
					state: {
						text: 'Connection timed out',
						color: 'darkslategray',
					},
				}),
			);
			errorHappened = true;
			break;
		case 500:
			dispatch(
				popUpShow({
					state: {
						text: <InternalErrorMessage text={popUpError(result.errors).text} />,
						color: 'maroon',
					},
				}),
			);
			errorHappened = true;
			break;
		default: {
			if (result.errors) {
				if (options.errorMessage)
					dispatch(
						popUpShow({ state: popUpError(result.errors), autoClose: options.autoCloseError }),
					);
				errorHappened = true;
				if (options.onError) options.onError(result);
			}
			if (result.success) {
				if (options.successMessage)
					dispatch(
						popUpShow({
							state: { text: 'Success', color: success },
							autoClose: true,
						}),
					);
				if (options.onSuccess) options.onSuccess(result);
			}
		}
	}
	dispatch(setLoading(false));
	return { errorHappened, continueRequest: true };
}
