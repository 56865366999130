import gql from 'graphql-tag';

export default gql`
	query getTemplateList {
		list: get_api_RocModelControllerNew_template_list
			@rest(path: "RocModelControllerNew/template/list") {
			data {
				id
				name
				fileName
				version
			}
		}
	}
`;
