import React from 'react';
import { Box, BoxProps } from '@mui/material';
import TextOutline from './TextOutline';

type CounterProps = {
	count?: number;
	children: React.ReactNode;
	secondary?: boolean;
	color?: string;
	counterProps?: BoxProps;
} & BoxProps;

export default function Counter({
	children,
	count,
	secondary,
	color,
	counterProps,
	...rest
}: CounterProps) {
	if (!count || count < 0) return <>{children}</>;

	return (
		<Box display="flex" alignItems="center" {...rest}>
			<Box display="flex" alignItems="center">
				{children}
			</Box>
			<TextOutline {...counterProps} color={color} secondary={secondary}>
				{count}
			</TextOutline>
		</Box>
	);
}
