import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { Box } from '@mui/material';
import { middlegrey } from '../../ui/Core/stylesheets/colors';

const simplifiedGroupClass = css`
	border: 1px solid ${middlegrey};
	background: white;
	border-radius: 5px;
	& > button {
		border-radius: 0;
		border: 0;
		border-left: 1px solid ${middlegrey};
	}
	& > button:first-child {
		border-left: 0;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	& > button:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	display: flex;
	flex-direction: row;
`;

type SimplifiedButtonGroupProps = any;

function SimplifiedButtonGroup({ children, className, ...rest }: SimplifiedButtonGroupProps) {
	return (
		<Box display="flex">
			<div className={cx(simplifiedGroupClass, className)} {...rest}>
				{children}
			</div>
		</Box>
	);
}

export default React.memo(SimplifiedButtonGroup);
