import AttachmentInput from './AttachmentInput';
import ProjectSampleSets from '../../../attachments/sampleSets/ProjectSampleSets';
import SampleSetData from '../../../attachments/sampleSets/SampleSetData';

type SampleSetInputProps = any;

export default function SampleSetInput({ ...props }: SampleSetInputProps) {
	return (
		<AttachmentInput
			ProjectAttachmentsComponent={ProjectSampleSets}
			AttachmentDataComponent={SampleSetData}
			attachmentName="sample set"
			attachmentPluralName="sample sets"
			{...props}
		/>
	);
}
