import { css } from '@emotion/css/macro';
import { connect } from 'react-redux';
import { Loading } from '../core/components';
import { LoadingState } from './types';
import { fade } from '../ui/Core/stylesheets/keyframes';

const blockerClass = css`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99998;
`;

const loadingClass = css`
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	position: fixed;
	right: 40px;
	bottom: 40px;
	padding: 0.25rem;
	background: white;
	border-radius: 50%;
	animation: ${fade} 0.3s;
	z-index: 99999;
`;

function MainLoader({ loading }: LoadingState) {
	if (!loading) return null;
	return (
		<>
			<div className={blockerClass} />
			<Loading className={loadingClass} />
		</>
	);
}

export default connect((state: any) => state.loadingReducer)(MainLoader);
