import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import Data from '../UserData';
import { hasFlag } from '../../core/helpers';

export function RadioFlagInput({ id, onChange, value, additional = {} }: any) {
	const { options = [], isFlags } = additional;
	const { value: inputValue } = value;
	const renderOptions = React.useMemo(
		() =>
			isFlags
				? options.map(({ label }: any, idx: number) => {
						const flag = 2 ** idx;
						return (
							<FormControlLabel
								control={<Checkbox inputProps={{ 'data-flag': flag } as any} />}
								label={label}
								checked={hasFlag(inputValue, flag)}
								data-flag={flag}
								onChange={onChange}
								key={label}
								disabled={additional.disabled}
							/>
						);
				  })
				: options.map(({ label }: any, idx: number) => (
						// eslint-disable-next-line react/jsx-indent
						<FormControlLabel
							onChange={onChange}
							value={String(idx)}
							control={<Radio />}
							label={label}
							key={label}
							disabled={additional.disabled}
						/>
				  )),
		[isFlags, options, inputValue, onChange, additional.disabled],
	);

	if (isFlags) return renderOptions;

	return (
		<RadioGroup
			style={{ display: 'flex', flexDirection: 'row' }}
			name={id}
			value={String(value?.value)}
			onChange={onChange}
		>
			{renderOptions}
		</RadioGroup>
	);
}

type RadioProps = any;

function retrieveValue(e: React.ChangeEvent<HTMLInputElement>, value: any, isFlags?: boolean) {
	if (!isFlags)
		return {
			value: parseInt(e.target.value, 10),
		};

	const inputValue = value?.value || 0;
	const flag = Number(e.target.getAttribute('data-flag'));

	return { value: !value || !hasFlag(inputValue, flag) ? inputValue + flag : inputValue - flag };
}

function RadioFlagItem({ itemId, options, isFlags, data = [], ...rest }: RadioProps) {
	return (
		<Data
			retrievedValue={(e, value) => retrieveValue(e, value, isFlags)}
			id={itemId}
			inputElement={RadioFlagInput}
			additional={{ options, isFlags }}
			data={data}
			manualSave={isFlags}
			instantSave={!isFlags}
			{...rest}
		/>
	);
}

export default React.memo(RadioFlagItem);
