import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { css } from '@emotion/css/macro';
import { useLazyQuery } from '@apollo/client';
import Chip from '@material-ui/core/Chip';
import { Edit } from '@mui/icons-material';
import Data from '../UserData';
import { useToggle } from '../../core/hooks';
import { CompensatingControlDialog } from '../CompensatingControl';
import { Heading, IconButton } from '../../core/components';
import { mainDark } from '../../ui/Core/stylesheets/colors';
import { GET_COMPENSATING_CONTROL_QUERY } from '../../../graphql/domains/compensatingControl/queryList';
import { DisclosureSummaryDialog } from '../DisclosureSummaryDialog';
import { useReportAccess } from '../../auth/hooks';
import { useReportContext } from '../context/ReportContext';
import { retrieveSummaryValue } from './helper';

const disclosureSummaryClass = css`
	margin-bottom: 1rem;
`;

export const NotTestedInputValue = 3;

export function SoAFSInput({ id, onChange, value, onFocus, additional }: any) {
	const { isAQSA } = useReportAccess();
	const { isOn: isCCWOn, toggleOn: openCCWDialog, toggleOff: closeCCWDialog } = useToggle();
	const {
		isOn: isDisclosureDialogOn,
		toggleOn: openDisclosureDialog,
		toggleOff: closeDisclosureDialog,
	} = useToggle();

	const disabled = additional?.disabled || false;

	if (value?.value === NotTestedInputValue)
		return (
			<Heading onFocus={onFocus} onMouseEnter={onFocus}>
				Not Tested
			</Heading>
		);

	return (
		<>
			<RadioGroup
				style={{ display: 'flex', flexDirection: 'row' }}
				name={id}
				value={String(value?.value)}
				onFocus={onFocus}
				onMouseEnter={onFocus}
			>
				<FormControlLabel
					onChange={onChange}
					value="0"
					control={<Radio />}
					label="In Place"
					disabled={!isAQSA || disabled}
				/>
				<FormControlLabel
					onChange={openCCWDialog}
					value="1"
					control={<Radio />}
					label="In Place w/ CCW"
					disabled={!isAQSA || disabled}
				/>
				<FormControlLabel
					onChange={onChange}
					value="2"
					control={<Radio />}
					label="N/A"
					disabled={!isAQSA || disabled || additional?.isNaDisabled}
				/>
				<FormControlLabel
					onChange={openDisclosureDialog}
					value="3"
					control={<Radio />}
					label="Not Tested"
					disabled={!isAQSA || disabled}
				/>
				<FormControlLabel
					onChange={onChange}
					value="4"
					control={<Radio />}
					label="Not in Place"
					disabled={!isAQSA || disabled}
				/>
			</RadioGroup>
			<DisclosureSummaryDialog
				onChange={onChange}
				isOpen={isDisclosureDialogOn}
				closeDisclosureDialog={closeDisclosureDialog}
				setDisclosureValue={additional?.setDisclosureValue}
			/>
			<CompensatingControlDialog
				refetchCC={additional?.refetchCC}
				ccwOn={additional?.ccwOn}
				isOpen={isCCWOn}
				onClose={closeCCWDialog}
				setDisclosureValue={additional?.setDisclosureValue}
			/>
		</>
	);
}

type DisclosureSummaryProps = { text?: string };

function DisclosureSummary({ text }: DisclosureSummaryProps) {
	if (!text) return null;
	return (
		<div className={disclosureSummaryClass}>
			Disclosure Summary:
			<br />
			{text}
		</div>
	);
}

const compensatingControlInputClass = css`
	margin-bottom: 8px;
`;

function CompensatingControlInput({
	isOn,
	toggleOn,
	toggleOff,
	refetchCC,
	ccwOn,
	ccwOff,
	dataCC,
	setDisclosureValue,
	isCCIncomplete,
}: any) {
	return (
		<div className={compensatingControlInputClass}>
			<IconButton style={{ marginRight: '0.5rem' }} onClick={toggleOn}>
				<Edit htmlColor={mainDark} />
			</IconButton>
			In Place with CCW
			{isOn && !!dataCC && (
				<CompensatingControlDialog
					isEdit
					initialCC={dataCC?.control?.data}
					isOpen={isOn}
					onClose={toggleOff}
					refetchCC={refetchCC}
					ccwOn={ccwOn}
					ccwOff={ccwOff}
					setDisclosureValue={setDisclosureValue}
				/>
			)}
			{isCCIncomplete && (
				<Chip
					label="CCW Incomplete"
					style={{ background: 'firebrick', color: 'white', display: 'flex' }}
				/>
			)}
		</div>
	);
}

export default function SoAFS({
	itemId,
	isCcwVisible,
	disclosureSummary,
	isCCIncomplete,
	data = [],
	...rest
}: any) {
	const { projectId } = useReportContext();
	const { isOn, toggleOn, toggleOff } = useToggle();
	const { isOn: isCcwOn, toggleOn: ccwOn, toggleOff: ccwOff } = useToggle(isCcwVisible);

	const [disclosureValue, setDisclosureValue] = React.useState<string>(disclosureSummary);
	React.useEffect(() => {
		setDisclosureValue(disclosureSummary);
	}, [setDisclosureValue, disclosureSummary]);

	const [load, { data: dataCC, refetch: refetchCC, called }] = useLazyQuery(
		GET_COMPENSATING_CONTROL_QUERY,
		{
			variables: {
				rocModelId: projectId,
				rocItemId: itemId,
			},
		},
	);

	React.useEffect(() => {
		if (isOn && !called) load();
	}, [isOn, called, load]);

	return (
		<>
			{isCcwOn && (
				<CompensatingControlInput
					disclosureValue={disclosureValue}
					isOn={isOn}
					toggleOn={toggleOn}
					toggleOff={toggleOff}
					refetchCC={refetchCC}
					ccwOn={ccwOn}
					ccwOff={ccwOff}
					dataCC={dataCC}
					setDisclosureValue={setDisclosureValue}
					isCCIncomplete={isCCIncomplete}
				/>
			)}
			{!!disclosureValue && <DisclosureSummary text={disclosureValue} />}
			<Data
				retrievedValue={retrieveSummaryValue}
				id={itemId}
				inputElement={SoAFSInput}
				additional={{
					ccwOn,
					disclosureValue,
					setDisclosureValue,
					refetchCC,
					disabled: isCcwOn,
					isNaDisabled: rest.isNaDisabled,
				}}
				disclosureValueToSend={disclosureValue}
				data={data}
				instantSave
				{...rest}
			/>
		</>
	);
}
