import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Clear, Search } from '@mui/icons-material';
import { BasicForm, CheckboxField, SelectField, TextField } from '../../formaggio';
import CompanySelect from '../../company/components/CompanySelect';
import { allRolesWithoutCEMOptions } from '../../auth/constants';
import { FieldSet, Button } from '../../core/components';
import { filtersClass } from './UsersList';
import ClientSelect from '../../client/ClientSelect';

export const userSearchInitialValues = {
	companyId: '',
	userName: '',
	firstName: '',
	lastName: '',
	role: '',
	isEnabled: true,
};

type UserSearchFilterProps = {
	hasCompanySelector?: boolean;
	hasClientSelector?: boolean;
	search: () => void;
	reset: () => void;
};

export default function UserSearchFilter({
	hasCompanySelector,
	hasClientSelector,
	search,
	reset,
}: UserSearchFilterProps) {
	return (
		<FieldSet legend="Filter">
			<BasicForm onSubmit={search} initialValues={userSearchInitialValues}>
				<div className={filtersClass}>
					{hasCompanySelector && (
						<CompanySelect hasUnassigned id="companyFilter" label="QSA Company" />
					)}
					{hasClientSelector && <ClientSelect />}
					<TextField label="Username" name="userName" />
					<TextField label="First Name" name="firstName" />
					<TextField label="Last Name" name="lastName" />
					<SelectField
						label="Role"
						name="role"
						id="roleFilter"
						options={allRolesWithoutCEMOptions}
					/>
					<CheckboxField label="Enabled" name="isEnabled" />
				</div>
				<div className={filtersClass}>
					<ButtonGroup>
						<Button startIcon={<Search />} type="submit">
							Search
						</Button>
						<Button startIcon={<Clear />} type="reset" onClick={reset}>
							Reset
						</Button>
					</ButtonGroup>
				</div>
			</BasicForm>
		</FieldSet>
	);
}
