import gql from 'graphql-tag';

export default gql`
	query getUserCompanies {
		list: get_api_Company_GetUserCompanies @rest(path: "Company/GetUserCompanies") {
			data {
				id
				companyName
				dbaName
				billingAddress
				phoneNumber
				siteUrl
				email
				evidenceRetentionMessage
				administrators {
					id
					email
					userName
				}
			}
		}
	}
`;
