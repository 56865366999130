import React, { ChangeEvent } from 'react';
import { getValueByInputType } from './index';

export function useAddOne<T>(
	setState: React.Dispatch<React.SetStateAction<T[]>>,
	initialObject: T,
) {
	return React.useCallback(
		() => setState((prevList) => [...prevList, initialObject]),
		[setState, initialObject],
	);
}

export function editByIndex(
	setState: React.Dispatch<React.SetStateAction<any[]>>,
	index: number,
	changeEvent: React.ChangeEvent<HTMLInputElement>,
) {
	setState((prevList) => {
		const newList = [...prevList];
		const newItem = { ...(newList[index] || {}) };
		newItem[changeEvent.target.name] =
			changeEvent.target.type === 'checkbox'
				? changeEvent.target.checked
				: changeEvent.target.value;
		newList[index] = newItem;
		return newList;
	});
}

export function removeOneByIndex<T>(
	setState: React.Dispatch<React.SetStateAction<T[]>>,
	index: number,
) {
	setState((prevList) => {
		const newList = [...prevList];
		newList.splice(index, 1);
		return newList;
	});
}

export function useFieldHandler(setState: React.Dispatch<React.SetStateAction<any>>) {
	return React.useCallback(
		(e: ChangeEvent<HTMLInputElement>) => setState(getValueByInputType(e)),
		[setState],
	);
}

export function useMultiFieldHandler<T extends Record<string, unknown>>(
	setState: React.Dispatch<React.SetStateAction<T>>,
) {
	return React.useCallback(
		(e: ChangeEvent<HTMLInputElement>) =>
			setState((prevState) => ({
				...prevState,
				[e.target.name]: getValueByInputType(e),
			})),
		[setState],
	);
}
