import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { hasFlag } from '../../core/helpers';
import { SummaryOfRequirementState } from '../enums';
import Data from '../UserData';
import { retrieveSummaryValue } from './helper';

function SummaryOfRequirementsInput({ value }: any) {
	const summaryValue = value?.value;

	return (
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
			<FormControlLabel
				disabled
				control={<Checkbox checked={hasFlag(summaryValue, SummaryOfRequirementState.InPlace)} />}
				label="In Place"
			/>
			<FormControlLabel
				disabled
				control={
					<Checkbox checked={hasFlag(summaryValue, SummaryOfRequirementState.NotApplicable)} />
				}
				label="Not Applicable"
			/>
			<FormControlLabel
				disabled
				control={<Checkbox checked={hasFlag(summaryValue, SummaryOfRequirementState.NotTested)} />}
				label="Not Tested"
			/>
			<FormControlLabel
				disabled
				control={<Checkbox checked={hasFlag(summaryValue, SummaryOfRequirementState.NotInPlace)} />}
				label="Not in Place"
			/>
		</div>
	);
}

export default function SummaryOfRequirements({
	compensatingControlUsed,
	customizedApproachUsed,
	...rest
}: any) {
	return (
		<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
			<div style={{ width: '100%' }}>
				<Data
					compensatingControlUsed={compensatingControlUsed}
					retrievedValue={retrieveSummaryValue}
					inputElement={SummaryOfRequirementsInput}
					instantSave
					{...rest}
				/>
			</div>
			<div style={{ marginTop: '15px' }}>
				<FormControlLabel
					disabled
					control={<Checkbox checked={compensatingControlUsed} />}
					label="Compensating control"
				/>
				<FormControlLabel
					disabled
					control={<Checkbox checked={customizedApproachUsed} />}
					label="Customized approach"
				/>
			</div>
		</div>
	);
}
