import React from 'react';
import { TextField } from '../formaggio';
import MaterialDialog from '../flyout/components/MaterialDialog';
import { Button } from '../core/components';
import { NotTestedInputValue } from './rocItems/SoAFS';
import { FormType } from '../formaggio/constants';

type DisclosureSummaryDialogType = {
	onChange: any;
	isOpen: boolean;
	closeDisclosureDialog: () => void;
	setDisclosureValue: any;
};

//For now this is used for Not Tested controls
export function DisclosureSummaryDialog({
	onChange,
	isOpen,
	closeDisclosureDialog,
	setDisclosureValue,
}: DisclosureSummaryDialogType) {
	const onSubmit = React.useCallback(
		(values) => {
			setDisclosureValue(values?.disclosureSummary);
			onChange({ target: { value: NotTestedInputValue } });
			closeDisclosureDialog();
		},
		[setDisclosureValue, closeDisclosureDialog, onChange],
	);

	return (
		<MaterialDialog
			isOpen={isOpen}
			title="Disclosure summary for Not Tested response"
			formProps={{ formType: FormType.Basic, onSubmit, initialValues: { disclosureSummary: '' } }}
			actions={
				<>
					<Button type="submit" color="primary">
						OK
					</Button>
					<Button color="secondary" onClick={closeDisclosureDialog}>
						Cancel
					</Button>
				</>
			}
			onClose={closeDisclosureDialog}
		>
			<TextField
				placeholder="(for example, not deemed in scope for the assessment, etc.)"
				name="disclosureSummary"
				fullWidth
				multiline
				variant="outlined"
			/>
		</MaterialDialog>
	);
}
