import TableCell from '@material-ui/core/TableCell';
import ProjectAttachments from '../ProjectAttachments';
import SampleSet from './SampleSet';
import SampleSetAddUpdateForm from './SampleSetAddUpdateForm';
import { RocDataType } from '../../rocTemplates/enums';
import SampleSetRowControls from './SampleSetRowControls';
import { useReportInfoContext } from '../../rocTemplates/context/ReportContext';
import { ProjectAttachmentsSharedProps } from '../types';

export function getSampleSetRowSpan(attachment: any) {
	return attachment?.items?.length || 1;
}

export default function ProjectSampleSets(props: ProjectAttachmentsSharedProps) {
	const { version, sampleSetsPrefix } = useReportInfoContext();

	return (
		<ProjectAttachments
			{...props}
			attachmentName="sample set"
			pluralName="sample sets"
			prefix={sampleSetsPrefix || 'Sample Set'}
			AttachmentComponent={SampleSet}
			AddUpdateForm={SampleSetAddUpdateForm}
			AdditionalRowControls={SampleSetRowControls}
			type={RocDataType.SampleSet}
			getRowSpan={getSampleSetRowSpan}
			headerCells={
				<>
					<TableCell>Type / Description</TableCell>
					{version ? (
						<>
							<TableCell>Items</TableCell>
							<TableCell>Selection Method</TableCell>
						</>
					) : (
						<>
							<TableCell>
								Listing of all{' '}
								<abbr title="devices, locations, change records, people, etc.">items</abbr> in the
								Sample Set
							</TableCell>
							<TableCell>Make / Model / Version / Release</TableCell>
						</>
					)}
					<TableCell>Total Sampled</TableCell>
					<TableCell>Total Population</TableCell>
					<TableCell />
					<TableCell>Referenced In</TableCell>
					<TableCell colSpan={2}>Tracking Sheets</TableCell>
				</>
			}
		/>
	);
}
