import { keyframes } from '@emotion/css/macro';

export const fade = keyframes`
from {
	opacity: 0;
	visibility: hidden;
}
to {
	opacity: 1;
	visibility: visible;
}`;

export const fadeLoop = keyframes`
from {
	opacity: 1;
	visibility: visible;
}
50% {
	opacity: 0;
	visibility: hidden;
}
to {
	opacity: 1;
	visibility: visible;
}`;

export const hover = keyframes`
from {
	transform: translate(0,0);
	opacity: 1;
}
50% {
	transform: translate(0,-3px);
	opacity: 0.8;
}
to {
	transform: translate(0,0);
	opacity: 1;
}`;
