import { css } from '@emotion/react/macro';
import { getBrandAssetFile } from '../../../branding/helpers';
import { mainDark } from './colors';
import { BRAND } from '../../../branding/constants';

export default css`
	body {
		background: url(${getBrandAssetFile('backgroundUrl', 'bg-pattern.png')});
		background-color: ${mainDark};
		background-blend-mode: soft-light;
		background-size: ${BRAND.backgroundSizePx}px;
	}

	footnote:before {
		content: ' [';
	}

	footnote:after {
		content: ']';
	}
`;
