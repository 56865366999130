import Typography, { TypographyProps } from '@material-ui/core/Typography';

type HeadingProps = { size?: number } & TypographyProps; //TODO: Deprecate "size" everywhere

export default function Heading({ children, variant = 'h6', ...rest }: HeadingProps) {
	return (
		<Typography variant={variant} {...rest}>
			{children}
		</Typography>
	);
}
