import { UploadStates } from './constants';
import { ReduxDispatch } from '../core/actions/types';
import globalFetchHandler from '../core/actions/globalFetchHandler';
import { AUTH_TOKEN_KEY } from '../auth/constants';
import { setLoading } from '../loading/reducers';
import { objectToFormData } from '../../shared/helpers/formDataConverter';

type Upload = {
	values: Record<string, any>;
	url: string;
	setUploadState: any;
	dispatch: ReduxDispatch;
	maxUploadSizeMb?: number;
};

export default function upload({
	values,
	url,
	setUploadState,
	dispatch,
	maxUploadSizeMb,
}: Upload): Promise<Response> {
	dispatch(setLoading(true));

	return new Promise((resolve, reject) => {
		const req = new XMLHttpRequest();

		req.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable) {
				setUploadState({
					state: UploadStates.Uploading,
					percentage: Math.round((event.loaded / event.total) * 100),
				});
			}
		});

		req.onreadystatechange = () => {
			if (req.readyState === 4) {
				const response =
					req.response ||
					JSON.stringify({
						errors: ['Upload rejected, file(s) exceeded size limit, or some other error occurred'],
					});
				const result = { status: req.status, ...JSON.parse(response) };

				const isBadStatus = req.status !== 200;
				if (result.errors || isBadStatus) {
					if (isBadStatus && !result.errors) {
						// eslint-disable-next-line no-alert
						alert(JSON.stringify(result));
					}
					setUploadState({ state: UploadStates.Error, percentage: 0 });
					reject(result);
				} else {
					setUploadState({ state: UploadStates.Complete, percentage: 100 });
					resolve(result);
				}

				globalFetchHandler({ result, dispatch, fetchOptions: { url } });
			}
		};

		req.open('POST', url);

		const auth = localStorage.getItem(AUTH_TOKEN_KEY);
		if (auth) req.setRequestHeader('Authorization', `Bearer ${auth}`);

		try {
			req.send(objectToFormData(values, maxUploadSizeMb));
		} catch (e: any) {
			const result = { errors: [e.message] };
			setUploadState({ state: UploadStates.Error, percentage: 0 });
			reject(result);
			globalFetchHandler({
				result,
				dispatch,
				fetchOptions: { url },
			});
		}
	});
}
