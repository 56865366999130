import React from 'react';
import { css, cx } from '@emotion/css/macro';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button } from '../../core/components';
import { useAPI, useToggle } from '../../core/hooks';
import { darkgrey } from '../../ui/Core/stylesheets/colors';
import { ModelItemIdProps } from '../../rocTemplates/types';

const rootClass = css`
	position: sticky;
	bottom: 0;
	background: white;
	padding: 4px;
`;

const topBorderClass = css`
	border-top: 1px solid ${darkgrey};
`;

type HintControlsProps = ModelItemIdProps & {
	isEditMode?: boolean;
	onSaved?: () => void;
	onCancel?: () => void;
	className?: string;
	text?: string;
	id?: string;
	replyTo?: any;
	refresh: () => void;
	initialFlag?: boolean;
	isNote?: boolean;
	onChange?: any;
};

export default React.forwardRef(
	(
		{
			isEditMode,
			onCancel,
			onSaved,
			className,
			modelId,
			itemId,
			refresh,
			text,
			id,
			initialFlag,
			isNote,
		}: HintControlsProps,
		ref,
	) => {
		const [message, setMessage] = React.useState(text || '');
		const [flag, setFlag] = React.useState(initialFlag || false);

		const { fetchAPI } = useAPI({});

		const { isOn: startedChanging, toggleOn, toggleOff } = useToggle();

		const change = React.useCallback(
			(e) => {
				setMessage(e.target.value);
				toggleOn();
			},
			[toggleOn],
		);

		const changeVisible = React.useCallback((e) => setFlag(e.target.checked), [setFlag]);
		const typeProps = React.useMemo(
			() => ({
				flagFieldName: isNote ? 'important' : 'isVisibleToCustomer',
				messageFieldName: isNote ? 'text' : 'message',
				endpointPath: isNote ? 'notes/' : '',
			}),
			[isNote],
		);

		const post = React.useCallback(() => {
			toggleOn();
			if (!message) return null;
			return fetchAPI({
				query: `Hints/${typeProps.endpointPath}add/${modelId}/${itemId}`,
				method: 'POST',
				params: { [typeProps.messageFieldName]: message, [typeProps.flagFieldName]: flag },
				onSuccess: () => {
					refresh();
					setMessage('');
					toggleOff();
				},
			});
		}, [
			toggleOn,
			message,
			fetchAPI,
			typeProps.endpointPath,
			typeProps.messageFieldName,
			typeProps.flagFieldName,
			modelId,
			itemId,
			flag,
			refresh,
			toggleOff,
		]);

		const save = React.useCallback(() => {
			if (!message) return null;
			return fetchAPI({
				query: `Hints/${typeProps.endpointPath}update/${modelId}/${itemId}/${id}`,
				method: 'PUT',
				params: { [typeProps.messageFieldName]: message, [typeProps.flagFieldName]: flag },
				onSuccess: () => {
					refresh();
					if (!isEditMode) setMessage('');
					if (onSaved) onSaved();
				},
			});
		}, [
			message,
			fetchAPI,
			typeProps.endpointPath,
			typeProps.messageFieldName,
			typeProps.flagFieldName,
			modelId,
			itemId,
			id,
			flag,
			refresh,
			isEditMode,
			onSaved,
		]);

		const editModeClass = css`
			padding: ${isEditMode ? '0' : '0.75rem'};
		`;

		return (
			<div
				className={cx(
					rootClass,
					editModeClass,
					!isEditMode && !isNote && topBorderClass,
					className,
				)}
			>
				<TextField
					inputRef={ref}
					label={(isEditMode ? 'Edit' : 'New') + ' ' + (isNote ? 'Note' : 'Hint')}
					multiline
					style={{ marginBottom: '0.75rem', background: 'white' }}
					variant="outlined"
					fullWidth
					value={message}
					onChange={change}
					error={!message.trim() && startedChanging}
					name="hintText"
				/>
				{isEditMode ? (
					<>
						<Button size="small" color="primary" onClick={save} style={{ marginRight: '0.5rem' }}>
							Save
						</Button>
						<Button size="small" color="secondary" onClick={onCancel}>
							Cancel
						</Button>
					</>
				) : (
					<>
						<Button size="small" color="primary" onClick={post} style={{ marginRight: '1rem' }}>
							Post
						</Button>
					</>
				)}
				<FormControlLabel
					style={{ marginLeft: '0.5rem' }}
					control={<Checkbox checked={flag} onChange={changeVisible} color="primary" />}
					label={isNote ? 'Important' : 'Visible to customer'}
				/>
			</div>
		);
	},
);
