import React from 'react';
import { css } from '@emotion/css/macro';
import { Button } from '../core/components';
import AttachmentRef, { AttachmentRefProps } from './AttachmentRef';

const buttonClass = css`
	margin-bottom: 8px !important;
`;

type AttachmentDataProps = {
	children: React.ReactNode;
	changeHighlight?: () => void;
} & AttachmentRefProps;

export default function AttachmentData({
	children,
	changeHighlight,
	referenceNumber,
}: AttachmentDataProps) {
	return (
		<div>
			{changeHighlight ? (
				<>
					<Button variant="outlined" onClick={changeHighlight} size="small" className={buttonClass}>
						<AttachmentRef referenceNumber={referenceNumber} />
					</Button>
				</>
			) : (
				<AttachmentRef referenceNumber={referenceNumber} />
			)}
			<br />
			{children}
		</div>
	);
}
