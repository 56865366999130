import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, IconButton } from '@mui/material';
import { css } from '@emotion/css/macro';
import { Error, HighlightOff, Warning } from '@mui/icons-material';
import { useItemContext } from './context/ItemContext';
import { onlyReportLevelWarnings, rocQaColorCoding, rocQaStatuses } from '../project/rocQa';
import { hasFlag, positiveValueFilter } from '../core/helpers';
import { RocWarningLevel, RocWarningType } from '../project/enums';
import RocWarningStatusIcon from './RocWarningStatusIcon';
import { useAPI } from '../core/hooks';
import { useHubState } from '../hubs/hooks';

const rocWarningItemClass = css`
	white-space: nowrap;
	margin-bottom: 8px;
	font-weight: bold;
	font-size: 12px;
	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	padding: 2px 6px;
	margin-left: 5px;
	color: black;
`;

const clearWarningsButtonClass = css`
	padding: 0 !important;
	color: ${rocQaColorCoding[RocWarningLevel.Warning].color} !important;
	width: 0.8em;
	&:hover {
		color: grey !important;
	}
	margin-left: 4px !important;
`;

function getDescriptionLine(count, rocWarningType) {
	return `${count}) ${rocQaStatuses[rocWarningType].title}: ${rocQaStatuses[rocWarningType].description}\n`;
}

function getRocWarningInfo(rocWarnings, hiddenWarnings) {
	let warningDescription = '';
	let warningsCount = 0;
	let errorDescription = '';
	let errorsCount = 0;
	let maxWarningLevel = RocWarningLevel.Suggestion;

	const values = Object.values(RocWarningType).filter(positiveValueFilter);

	values.forEach((value: number) => {
		if (
			hasFlag(rocWarnings, value) &&
			!hasFlag(onlyReportLevelWarnings, value) &&
			!hasFlag(hiddenWarnings, value)
		) {
			if (rocQaStatuses[value].level === RocWarningLevel.Error) {
				errorsCount += 1;
				errorDescription += getDescriptionLine(errorsCount, value);
			}
			if (rocQaStatuses[value].level === RocWarningLevel.Warning) {
				warningsCount += 1;
				warningDescription += getDescriptionLine(warningsCount, value);
			}

			if (rocQaStatuses[value].level > maxWarningLevel)
				maxWarningLevel = rocQaStatuses[value].level;
		}
	});

	let title = '';
	if (errorsCount > 0)
		title += `QA Errors: \n ${errorDescription}` + (warningsCount > 0 ? '\n' : '');
	if (warningsCount > 0) title += `QA Warnings: \n ${warningDescription}`;
	return { title, maxWarningLevel, errorsCount, warningsCount };
}

export default function RocWarnings({ projectId, refresh, canClearWarnings }) {
	const { itemId, rocWarnings, hiddenWarnings } = useItemContext();
	const [hubState] = useHubState();
	const [newRocWarnings, setNewWarnings] = React.useState(rocWarnings);

	React.useEffect(() => {
		if (hubState.newItemWarnings[0] === projectId && hubState.newItemWarnings[1] === itemId)
			setNewWarnings(hubState.newItemWarnings[2]);
	}, [projectId, itemId, hubState.newItemWarnings]);

	const { title, maxWarningLevel, errorsCount, warningsCount } = React.useMemo(
		() => getRocWarningInfo(newRocWarnings, hiddenWarnings),
		[newRocWarnings, hiddenWarnings],
	);

	const { fetchAPI } = useAPI({});
	const clearWarnings = React.useCallback(
		() =>
			fetchAPI({
				query: `RocItemControllerNew/ClearWarnings/${projectId}/${itemId}`,
				method: 'POST',
				successMessage: true,
				onSuccess: refresh,
			}),
		[fetchAPI, projectId, itemId, refresh],
	);

	if (!newRocWarnings || (errorsCount === 0 && warningsCount === 0)) return null;

	return (
		<Tooltip title={<Box whiteSpace="pre-line">{title}</Box>}>
			<Box className={rocWarningItemClass} color={rocQaColorCoding[maxWarningLevel].color}>
				<div>ROC QA</div>
				{errorsCount > 0 && (
					<RocWarningStatusIcon
						Icon={Error}
						warningsCount={errorsCount}
						warningLevel={RocWarningLevel.Error}
					/>
				)}
				{warningsCount > 0 && (
					<>
						<RocWarningStatusIcon
							Icon={Warning}
							warningsCount={warningsCount}
							warningLevel={RocWarningLevel.Warning}
						/>
						{canClearWarnings && (
							<Tooltip title="Clear warnings" placement="right-start">
								<IconButton className={clearWarningsButtonClass} onClick={clearWarnings}>
									<HighlightOff viewBox="-5 -4 28 32" />
								</IconButton>
							</Tooltip>
						)}
					</>
				)}
			</Box>
		</Tooltip>
	);
}
