import React from 'react';
import Accordion, { AccordionProps } from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

type DropdownProps = {
	children: React.ReactNode;
	header: string;
	headerClassName?: string;
	hideContents?: boolean;
	initiallyOpen?: boolean;
	className?: string;
} & AccordionProps;

export default function Dropdown({
	children,
	header,
	initiallyOpen = false,
	hideContents,
	className,
	...rest
}: DropdownProps) {
	const [isOn, setOn] = React.useState(initiallyOpen);
	const change = React.useCallback((_, expanded) => setOn(expanded), [setOn]);

	return (
		<Accordion onChange={change} defaultExpanded={initiallyOpen} {...rest}>
			<AccordionSummary>{header}</AccordionSummary>
			{(!hideContents || isOn) && (
				<AccordionDetails>
					<div className={className}>{children}</div>
				</AccordionDetails>
			)}
		</Accordion>
	);
}
