import { CompositeDecorator, ContentState, convertFromRaw, EditorState } from 'draft-js';
import { DraftAtomic, DraftTitledLink } from './Components';

export function convertTextToDraft(text: string): ContentState {
	let result;

	try {
		result = convertFromRaw(JSON.parse(text));
	} catch {
		result = convertFromRaw({
			blocks: [
				{
					data: {},
					depth: 0,
					entityRanges: [],
					inlineStyleRanges: [],
					key: '',
					text,
					type: 'unstyled',
				},
			],
			entityMap: {},
		});
	}

	return result;
}

function draftBlocksToPlainText(blocks: []) {
	return blocks.map((block: any) => (!block.text.trim() && '\n') || block.text).join('\n');
}

export function convertDraftToText(text = '') {
	let result;

	try {
		const { blocks = [] } = JSON.parse(text);
		result = draftBlocksToPlainText(blocks);
	} catch (e) {
		result = text;
	}

	return result.trim();
}

export function isDraftMessageVisible(text = '') {
	try {
		const { blocks, entityMap = {} } = JSON.parse(text);

		return Object.keys(entityMap).length > 0 || !!draftBlocksToPlainText(blocks).trim();
	} catch (e) {
		return !!text.trim();
	}
}

function findStrategy(contentBlock, callback, contentState) {
	contentBlock.findEntityRanges((character) => {
		const entityKey = character.getEntity();
		return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
	}, callback);
}

export const draftDecorators = new CompositeDecorator([
	{
		strategy: findStrategy,
		component: DraftTitledLink,
	},
]);

export function setInitialDraftEditorState(value?: string) {
	return value
		? EditorState.createWithContent(convertTextToDraft(value))
		: EditorState.createEmpty();
}

export function draftBlockRenderer(block) {
	if (block.getType() === 'atomic') {
		return {
			component: DraftAtomic,
			editable: false,
		};
	}

	return null;
}

const b64regex = /data:(image\/.*);base64,(.*)/;

function base64ToArrayBuffer(base64 = ''): [Uint8Array, string] {
	if (!base64) return [null, 'Empty image'];

	const matches = base64.match(b64regex);

	if (!matches || matches.length < 3) return [null, 'Error decoding image'];

	const binaryString = atob(matches[2]);
	const binaryLen = binaryString.length;
	const bytes = new Uint8Array(binaryLen);

	for (let i = 0; i < binaryLen; i += 1) bytes[i] = binaryString.charCodeAt(i);

	return [bytes, matches[1]];
}

export function showDocument(base64) {
	const [bytes, contentResult] = base64ToArrayBuffer(base64);

	if (bytes == null) {
		// eslint-disable-next-line no-alert -- Ok
		alert(contentResult);
		return;
	}

	window.open(URL.createObjectURL(new Blob([bytes], { type: contentResult })), '_blank');
}
