import { Link, LinkProps } from 'react-router-dom';
import { css, cx } from '@emotion/css/macro';

const rootClass = css`
	color: inherit;
`;

const underlineClass = css``;

type RouterLinkProps = LinkProps & { isForButton?: boolean };

/**
 * Use this only with internal links. For external navigation use SimpleLink.
 */
export default function RouterLink({ className, isForButton, ...rest }: RouterLinkProps) {
	return <Link className={cx(rootClass, isForButton && underlineClass, className)} {...rest} />;
}
