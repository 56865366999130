import { css } from '@emotion/css/macro';

export const styles: Record<string, string> = {
	rocGreen: 'background: #cbdfc0 !important;',
	rocLightGreen: 'background: #eaf1dd !important;',
	rocLightGrey: 'background: #f2f2f2 !important;', //Also used in V4.0 Findings section
	rocBold: 'font-weight: bold !important;',
	rocCenter: 'text-align: center !important;',
	rocV4GreenTableHeader:
		'background: #006a72 !important; color: white !important; font-weight: bold !important; font-size: 14px !important;',
	rocV4LightGreenTableHeader:
		'background: #009999 !important; color: white !important; font-weight: bold !important; font-size: 14px !important;',
	rocV4BizarreTableHeader:
		'background: #00a3b0 !important; color: white !important; font-weight: bold !important; font-size: 14px !important;', //For some strange reason only one cell is filled with this color in the whole V4.0 document...
	rocV4Grey: 'background: #e2e7e6 !important;',
	rocV4DarkGrey: 'background: #cbd4d5 !important;',
};

type ViewStyleToClassNameProps = { classNames?: string[] };

export function viewStyleInformationToClassName({ classNames }: ViewStyleToClassNameProps = {}) {
	if (!classNames) return null;
	const classesString = classNames.reduce((memo, style) => {
		if (styles[style]) return memo.concat(styles[style]);
		return memo;
	}, '');
	return css`
		${classesString}
	`;
}
