import React from 'react';
import Data from '../UserData';
import { RocDataType } from '../enums';
import MediaFileInput from './attachments/MediaFile';
import SampleSetInput from './attachments/SampleSetInput';
import ObservationEvidenceInput from './attachments/ObservationEvidenceInput';
import SystemEvidenceInput from './attachments/SystemEvidenceInput';

function getAttachmentInput(dataType: RocDataType) {
	switch (dataType) {
		case RocDataType.MediaFile:
			return {
				inputElement: MediaFileInput,
				emptyValue: { evidenceLocation: '' },
				validation: (value: any) => (value?.attachmentId ? undefined : 'File required'),
				getData: ({ attachmentId, evidenceLocation }: any) => ({
					attachmentId,
					evidenceLocation,
				}),
			};
		case RocDataType.SampleSet:
			return {
				inputElement: SampleSetInput,
				getData: ({ attachmentId }: any) => ({
					attachmentId,
				}),
			};
		case RocDataType.ObservationEvidence:
			return {
				inputElement: ObservationEvidenceInput,
				getData: ({ attachmentId }: any) => ({
					attachmentId,
				}),
			};
		case RocDataType.SystemEvidence:
			return {
				inputElement: SystemEvidenceInput,
				getData: ({ attachmentId }: any) => ({
					attachmentId,
				}),
			};
		default:
			return { inputElement: () => <>Unknown Data Type</> };
	}
}

export default function Attachment({ itemId, notApplicable, rocDataType, accept, ...rest }: any) {
	const attachmentInput = React.useMemo(() => getAttachmentInput(rocDataType), [rocDataType]);

	return (
		<Data
			elemId={itemId}
			retrievedValue={(file: object) => file}
			manualSave
			notApplicable={notApplicable}
			additional={{ accept }}
			rocDataType={rocDataType}
			{...rest}
			{...attachmentInput}
		/>
	);
}
