import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { Check } from '@mui/icons-material';
import { Box } from '@mui/material';
import { EntryAuthor } from '../../../graphql/typescript';
import { WorkflowAuthorsProps } from './WorkflowList';
import { Heading, UserTypeBadge } from '../../core/components';
import { success } from '../../ui/Core/stylesheets/colors';

type WorkflowAuthorNameProps = Pick<EntryAuthor, 'authorId' | 'authorType'> & WorkflowAuthorsProps;

function WorkflowAuthorName({ authorId, authorType, users, contacts }: WorkflowAuthorNameProps) {
	const author = React.useMemo(
		() => ({
			user: !authorType ? users?.filter(({ id }) => id === authorId)[0] : undefined,
			contact: authorType === 1 ? contacts?.filter(({ id }) => id === authorId)[0] : undefined,
			email: authorType === 2 ? authorId : undefined,
		}),
		[users, contacts, authorType, authorId],
	);

	if (author.user)
		return (
			<>
				{author.user.firstName} {author.user.lastName}
			</>
		);

	if (author.contact) return <>{author.contact.name}</>;

	if (author.email) return <>{authorId}</>;

	return null;
}

const rootClass = css`
	display: flex;
	align-items: center;
`;

const nameClass = css`
	margin-right: 8px;
	font-size: 13px !important;
`;

const approvalClass = css`
	color: ${success};
	align-items: center;
	display: flex;
`;

const checkClass = css`
	margin-right: 8px;
`;

type WorkflowAuthorProps = WorkflowAuthorNameProps &
	Pick<EntryAuthor, 'authorProjectRole'> & { isApprovalType?: boolean };

export default function WorkflowAuthor({
	authorProjectRole,
	isApprovalType,
	...rest
}: WorkflowAuthorProps) {
	return (
		<div className={rootClass}>
			<div className={cx(nameClass, isApprovalType && approvalClass)}>
				{isApprovalType && (
					<>
						<Check className={checkClass} htmlColor={success} />
						<Box margin="0 4px">Reviewed by</Box>
					</>
				)}
				<Heading variant="body2" size={1} color="textSecondary">
					<WorkflowAuthorName {...rest} />
				</Heading>
			</div>
			<UserTypeBadge userRole={authorProjectRole} />
		</div>
	);
}
