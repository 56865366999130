import React from 'react';
import { RocItemSubType } from '../enums';
import { RouterLink } from '../../core/components';
import { useReportContext } from '../context/ReportContext';

const section49Link = "<a href='#' class='link-section-4-9'>Section 4.9</a>";
const section6LinkPhrase =
	"the evidence reference number(s) from <a href='#' class='link-section-6'>Section 6</a> for ";

function processText(text = '') {
	let result = text;

	if (text.indexOf('<a ') >= 0) {
		//ROC v4: For now let's just remove this link, not sure it will be useful
		if (text.indexOf(section49Link) > 0) result = text.replace(section49Link, 'Section 4.9');

		//ROC v4: Rephrase some text that stopped making sense in UI implementation
		if (text.indexOf(section6LinkPhrase) > 0) result = text.replace(section6LinkPhrase, '');
	}

	return { dangerouslySetInnerHTML: { __html: result } };
}

type TextBlockProps = {
	text: string;
	className?: string;
};

function TextBlockSimple({ text, className }: TextBlockProps) {
	const processedProps = React.useMemo(() => (text ? processText(text) : {}), [text]);

	return <div className={className} {...processedProps} />;
}

function TextBlockInterviewLink({ text, interviewId }) {
	const { projectId } = useReportContext();

	if (!interviewId) return <div>{text}</div>;

	return (
		<div>
			<RouterLink to={`/portal/interviews/project/${projectId}/edit/${interviewId}`}>
				{text}
			</RouterLink>
		</div>
	);
}

function TextBlock({ subType, isAutoGenerated, ...rest }: any = {}) {
	if (!isAutoGenerated) return <TextBlockSimple {...rest} />;

	if (subType === RocItemSubType.InterviewLink) return <TextBlockInterviewLink {...rest} />;

	return <div>{rest.text}</div>;
}

export default React.memo(TextBlock);
