import { css } from '@emotion/css/macro';
import React from 'react';
import { Button, Heading } from '../core/components';
import LookUpItem from './LookUpItem';
import { convertToHumanTime } from '../core/helpers';
import { useAPI } from '../core/hooks';
import { RocDataType } from '../core/types/api';
import { useReportContext } from '../rocTemplates/context/ReportContext';
import { useAllowedMethods } from '../auth/hooks';
import { useItemContext } from '../rocTemplates/context/ItemContext';
import { ALL_PAYMENT_CHANNELS } from '../rocTemplates/UserData';
import FolderTile from '../evidence/components/FolderTile';
import SampleSetData from '../attachments/sampleSets/SampleSetData';
import { InterviewEventInput } from '../rocTemplates/rocItems/InterviewEvent';
import { RadioFlagInput } from '../rocTemplates/rocItems/RadioFlag';
import { RequirementInput } from '../rocTemplates/rocItems/Requirement';

function LookUpResponseData({
	rocDataType,
	attachmentId,
	sampleSets = [],
	mediaFiles = [],
	projectId,
	itemId,
	value,
	...rest
}: any) {
	switch (rocDataType) {
		case RocDataType.SampleSet:
			return <SampleSetData {...sampleSets.filter(({ id }: any) => id === attachmentId)[0]} />;
		case RocDataType.MediaFile:
			return (
				<FolderTile
					{...mediaFiles.filter(({ id }: any) => id === attachmentId)[0]}
					itemId={itemId}
					modelId={projectId}
				/>
			);
		case RocDataType.InterviewEvent:
			return <InterviewEventInput value={rest} disabled />;
		case RocDataType.RadioFlag: {
			return (
				<RadioFlagInput
					additional={{ options: rest.options, isFlags: rest.isFlags, disabled: true }}
					value={{ value }}
				/>
			);
		}
		case undefined: // Bool
		case RocDataType.Bool:
			return <>{value ? 'Yes' : 'No'}</>;
		case RocDataType.Date:
		case RocDataType.Text:
		case RocDataType.AssessorName:
			return <>{String(value)}</>;
		case RocDataType.Requirement:
			return <RequirementInput value={rest} additional={{ disabled: true }} />;
		default:
			return <>This input is not yet supported</>;
	}
}

function ResponseLookUpReplicator({
	data,
	sampleSets,
	mediaFiles,
	channelId,
	closeDialog,
	...rest
}: any) {
	const { fetchAPI } = useAPI();
	const { refresh, projectId } = useReportContext();

	const { 'RocLookUp/Use': canUseLookUp } = useAllowedMethods();

	return (
		<>
			{data?.map(({ editTime, ...restData }: any) => {
				const use = () =>
					fetchAPI({
						query: `RocLookUp/Use/${projectId}/${rest.id.rocModelId}/${rest.id.itemId}/${restData.id}`,
						qs: { channelId: channelId === ALL_PAYMENT_CHANNELS ? undefined : channelId },
						method: 'POST',
						onSuccess: () => {
							closeDialog();
							refresh();
						},
					});

				return (
					<div key={editTime}>
						<Heading variant="caption" color="textSecondary">
							{convertToHumanTime(editTime)}
						</Heading>
						<div>
							<LookUpResponseData
								sampleSets={sampleSets}
								mediaFiles={mediaFiles}
								projectId={rest.id.rocModelId}
								itemId={rest.id.itemId}
								{...restData}
								{...rest}
							/>
							{canUseLookUp && (
								<div style={{ margin: '8px 0' }}>
									<Button onClick={use}>Use</Button>
								</div>
							)}
						</div>
					</div>
				);
			})}
		</>
	);
}

const LookUpEntryMemo = React.memo(ResponseLookUpReplicator);

type LookUpProps = { channelId?: string; closeDialog: () => void };

const rootClass = css`
	padding: 8px;
`;

export default function LookUp({ channelId, closeDialog }: LookUpProps) {
	const { projectId } = useReportContext();
	const { itemId } = useItemContext();

	const { state } = useAPI({
		props: { query: `RocLookUp/View/${projectId}/${itemId}` },
		autoLoad: true,
	});

	const { data: list = [] } = state?.autodata || {};

	const renderList = React.useMemo(() => {
		if (list.length === 0) return <Heading>No responses</Heading>;

		return list.map(({ projectStartDate, projectName, item, sampleSets, mediaFiles }: any) => (
			<LookUpItem
				projectStartDate={projectStartDate}
				key={projectStartDate}
				projectName={projectName}
			>
				<LookUpEntryMemo
					{...item}
					sampleSets={sampleSets}
					mediaFiles={mediaFiles}
					channelId={channelId}
					closeDialog={closeDialog}
				/>
			</LookUpItem>
		));
	}, [channelId, list, closeDialog]);

	return <div className={rootClass}>{renderList}</div>;
}
