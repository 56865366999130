import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { mainDark } from '../../ui/Core/stylesheets/colors';

const cardHeaderClass = css`
	padding: 1.25rem;
	color: white;
	background: ${mainDark};
	text-align: left;
	font-size: 1.1rem;
	font-weight: 300;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
		0px 1px 10px 0px rgba(0, 0, 0, 0.12);
`;

const roundedClass = css`
	border-radius: 5px;
`;

type CardHeaderProps = {
	children: React.ReactNode;
	className?: string;
	color?: string;
	style?: React.CSSProperties;
	isRounded?: boolean;
};

export function CardHeader({ children, className, color, style, isRounded }: CardHeaderProps) {
	return (
		<div
			style={style}
			className={cx(
				cardHeaderClass,
				color
					? css`
							background: ${color};
					  `
					: null,
				isRounded ? roundedClass : null,
				className,
			)}
		>
			{children}
		</div>
	);
}

const cardClass = css`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	box-shadow: 0 2px 0.5rem 0 rgba(0, 0, 0, 0.2);
`;

type CardProps = { children: React.ReactNode; className?: string; color?: string };

export function Card({ children, className, color = 'white' }: CardProps) {
	const dynamicClass = css`
		background: ${color};
	`;
	return <div className={cx(cardClass, dynamicClass, className)}>{children}</div>;
}
