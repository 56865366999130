import React from 'react';
import { Box } from '@mui/material';
import { middlegrey } from '../../ui/Core/stylesheets/colors';

type SimplifiedFrameProps = { children: React.ReactNode };

export default function SimplifiedFrame({ children }: SimplifiedFrameProps) {
	return (
		<Box padding="4px 8px" borderRadius="5px" border={`1px solid ${middlegrey}`} marginBottom="8px">
			{children}
		</Box>
	);
}
