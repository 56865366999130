import React from 'react';
import { UserInfoSuccessResponse } from '../../graphql/typescript';
import { getMyMaxRole } from './helpers';
import { RolesEnum } from './enums';

type AuthContextType = UserInfoSuccessResponse & {
	isMyRoleGreaterOrEqual: (arg: RolesEnum) => boolean;
	iHaveARole: (arg: RolesEnum) => boolean;
};

const AuthContext = React.createContext<AuthContextType>({
	isMyRoleGreaterOrEqual: (arg) => arg && false,
	iHaveARole: (arg) => arg && false,
	globalOptions: {},
});

export function useAuthContext(): AuthContextType {
	return React.useContext(AuthContext);
}

type AuthContextWrapperProps = { children: React.ReactNode; userInfo: UserInfoSuccessResponse };

export function AuthContextWrapper({ children, userInfo = {} }: AuthContextWrapperProps) {
	const isMyRoleGreaterOrEqual = React.useCallback(
		(arg: RolesEnum) => getMyMaxRole(userInfo.roles) <= arg,
		[userInfo.roles],
	);

	const iHaveARole = React.useCallback(
		(role: RolesEnum) => userInfo.roles.indexOf(Number(role)) >= 0,
		[userInfo.roles],
	);

	const [info, setInfo] = React.useState<AuthContextType>({
		...userInfo,
		iHaveARole,
		isMyRoleGreaterOrEqual,
	});

	React.useEffect(() => {
		setInfo({ ...userInfo, iHaveARole, isMyRoleGreaterOrEqual });
	}, [userInfo, isMyRoleGreaterOrEqual, iHaveARole]);

	return <AuthContext.Provider value={info}>{children}</AuthContext.Provider>;
}
