import React from 'react';
import { get } from 'lodash';
import { PureSelectField, PureTextField, PureCheckboxField } from '../../../formaggio';
import { RocItemType } from '../../enums';
import { FieldSet } from '../../../core/components';
import ItemGeneralFields from './ItemGeneralFields';
import { containerTypes } from '../../constants';
import { RowControls, OneMultipleRowControls } from './tableControls';
import HTMLField from './HTMLField/HTMLField';

export type ItemDependentFieldsProps = {
	type: RocItemType;
	nestedDTO: any;
	DTO: object;
	onChangeField: (e: React.ChangeEvent<any>) => void;
	onChange: (e: React.ChangeEvent<any>) => void;
	path: string[];
	setDTO: (arg: object) => void;
};

export default function ItemDependentFields({
	type,
	nestedDTO,
	DTO,
	onChangeField,
	onChange, //JSON
	path,
	setDTO,
}: ItemDependentFieldsProps) {
	switch (type) {
		case 0:
			return (
				<PureTextField
					onChange={onChangeField}
					value={get(nestedDTO, 'format')}
					name="format"
					label="Format"
					helperText="regexp"
				/>
			);
		case 2: //ArrayROCItem
			return (
				<>
					<PureCheckboxField
						onChange={onChangeField}
						storage={nestedDTO}
						name="needsOwnTableContainer"
						label="Needs own table container"
					/>
					<FieldSet legend="Item Template">
						<ItemGeneralFields DTO={DTO} path={[...path, 'children[0]']} setDTO={setDTO} />
					</FieldSet>
				</>
			);
		case 3:
			return (
				<>
					<PureTextField
						onChange={onChangeField}
						value={get(nestedDTO, 'renderType')}
						name="renderType"
						label="Bool Render Type"
					/>
					<PureTextField
						value={get(nestedDTO, 'label')}
						onChange={onChangeField}
						name="label"
						label="Label"
					/>
				</>
			);
		case 7:
			return (
				<>
					<PureSelectField
						label="Container Type"
						name="containerType"
						customChangeHandler={onChangeField}
						value={get(nestedDTO, 'containerType')}
						options={containerTypes}
					/>
					<PureTextField
						style={{ minWidth: '400px' }}
						value={get(nestedDTO, 'name')}
						onChange={onChangeField}
						name="name"
						label="Name"
					/>
				</>
			);
		case 8:
			return <HTMLField onChange={onChange} name="text" storage={nestedDTO} />;
		case 11:
			return (
				<PureCheckboxField
					onChange={onChangeField}
					storage={nestedDTO}
					name="isNaDisabled"
					label="Is 'Not Applicable' disabled"
				/>
			);
		case 12: //ROCRow aka TableRow
			return (
				<RowControls
					DTO={DTO}
					setDTO={setDTO}
					nestedDTO={nestedDTO}
					onChangeField={onChangeField}
					path={path}
					onChange={onChange}
				/>
			);
		case 14:
			return (
				<OneMultipleRowControls
					DTO={DTO}
					nestedDTO={nestedDTO}
					onChangeField={onChangeField}
					path={path}
					setDTO={setDTO}
					onChange={onChange}
				/>
			);
		default:
			return null;
	}
}
