import gql from 'graphql-tag';

export default gql`
	query getRocItemsByResponsibilityArea($rocModelId: String!, $responsibilityArea: Int!) {
		list: get_api_ResponsibilityArea_GetItems_rocModelId(
			rocModelId: $rocModelId
			responsibilities: $responsibilityArea
		)
			@rest(
				path: "ResponsibilityArea/GetItems/{args.rocModelId}?responsibilities={args.responsibilities}"
			) {
			data {
				id
				responsibilityArea
				itemDescription
				numbering
				chapterId
			}
		}
	}
`;
