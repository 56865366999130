import React from 'react';
import { css } from '@emotion/css/macro';
import { FetchAPI } from '../../core/actions/fetchAPI';
import { RocItem } from '../types';
import TableCell from './TableCell';
import { usePermalinkHighlightQS, useReplicator } from '../hooks';
import { useEditableRowContext } from '../context/EditableRowContext';
import { getReportCellProps } from '../helpers';
import ScopeItemInfo from '../../scopeBuilder/components/ScopeItemInfo';

type TableRowSharedProps = {
	modelId: string;
	viewMode?: boolean;
	path: string[];
	fetchAPI: FetchAPI;
	refresh: () => void;
	rowId: string;
	isRequiredForEvidences?: boolean;
};

export type PanelProps = {
	isHintsEnabled?: boolean;
	isGuidanceEnabled?: boolean;
	isFlagEnabled?: boolean;
	interviewAttendeesListId?: string;
	responsibilityArea?: number;
	availableChannelOptions?: any[];
};

type TableRowProps = TableRowSharedProps & {
	parentType: number;
	isArrayChild?: boolean;
} & PanelProps &
	RocItem;

const simpleCellClass = css`
	white-space: pre-wrap;
	padding: 4px;
`;

function ReadOnlyTableRow({ children = [] }: TableRowProps) {
	const render = React.useMemo(
		() =>
			children.map(({ props = {} }: any = {}, idx) => {
				const { text } = props;
				const data = props.data && props.data[0] && props.data[0].value;
				let value = text || '<Enter response here>';

				if (typeof data === 'boolean') {
					if (data === false) value = 'No';
					else if (data === true) value = 'Yes';
				} else if (data) value = String(data);

				return (
					<td key={idx} colSpan={props.renderType === 3 ? 2 : 1} className={simpleCellClass}>
						{value}
					</td>
				);
			}),
		[children],
	);

	return <tr>{render}</tr>;
}

const ReadOnlyTableRowMemo = React.memo(ReadOnlyTableRow);

function RenderedTableRow({
	itemId,
	subType,
	children = [],
	viewMode,
	fetchAPI,
	path = [],
	isArrayChild,
}: TableRowProps) {
	const Replicator = useReplicator(viewMode);

	const renderColumns = React.useMemo(
		() =>
			children.map(({ props }: any = {}, idx) => (
				<Replicator
					{...getReportCellProps(props, idx, subType)}
					{...props}
					wrapperComponent={TableCell}
					isArrayChild={isArrayChild}
					path={[...path, `children[${idx}]`]}
					key={props.itemId || idx}
					viewMode={viewMode}
					fetchAPI={fetchAPI}
					rowId={itemId}
					cellIndex={idx}
				/>
			)),
		[children, subType, Replicator, path, viewMode, fetchAPI, itemId, isArrayChild],
	);

	return <tr>{renderColumns}</tr>;
}

function TableRowOptimized({ itemId, ...rest }: TableRowProps) {
	const { currentId, isArrayRow } = useEditableRowContext();
	const highlightId = usePermalinkHighlightQS();

	if (!itemId) return null;

	if (isArrayRow && currentId !== itemId && highlightId !== itemId)
		return <ReadOnlyTableRowMemo itemId={itemId} {...rest} />;

	return <RenderedTableRow itemId={itemId} {...rest} />;
}

export default function TableRow({ itemId, appliedScopeInfo, ...rest }: TableRowProps) {
	if (!itemId) return null;

	return (
		<>
			{appliedScopeInfo && (
				<tr>
					<td colSpan={Number.MAX_SAFE_INTEGER}>
						<ScopeItemInfo appliedScopeInfo={appliedScopeInfo} />
					</td>
				</tr>
			)}
			<TableRowOptimized itemId={itemId} {...rest} />
		</>
	);
}
