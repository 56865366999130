import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { css } from '@emotion/css/macro';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormikContext } from 'formik';
import { Label } from '@mui/icons-material';
import Tooltip from '@material-ui/core/Tooltip';
import { contactSpecializations } from '../types';
import { flagSumToArray } from '../../core/helpers';
import { useToggle } from '../../core/hooks';
import { Button, IconButton } from '../../core/components';
import { getResponsibilityByFlag } from '../helpers';
import { PureCheckboxGroupField } from '../../formaggio';

const itemClass = css`
	white-space: normal;
	max-width: 400px;
`;

type SpecializationItemProps = {
	title: string;
	description?: string;
	isMenuItem?: boolean;
	isMini?: boolean;
};

function SpecializationItem({ title, description, isMenuItem, isMini }: SpecializationItemProps) {
	return (
		<Box className={itemClass} pb={isMenuItem || isMini ? 0 : 1}>
			{title}
			{description && !isMini && (
				<Typography color="textSecondary" variant="subtitle2">
					{description}
				</Typography>
			)}
		</Box>
	);
}

type SpecializationItemWrapperProps = { spec: number; isMini?: boolean };

export function SpecializationItemWrapper({ spec, isMini }: SpecializationItemWrapperProps) {
	return <SpecializationItem key={spec} {...getResponsibilityByFlag(spec)} isMini={isMini} />;
}

const responsibilitiesCheckboxGroup = contactSpecializations.map((item, idx) => ({
	label: <SpecializationItem isMenuItem {...item} />,
	value: 2 ** idx,
}));

type ResponsibilityAreaSelectorProps = {
	setActualAreas: (arg: number) => void;
	initialAreas?: number;
	currentAreas?: number;
	style?: React.CSSProperties;
	noPrimaryContact?: boolean;
	miniVersion?: boolean;
	className?: string;
	label?: string;
	disabled?: boolean;
};

export function ResponsibilityAreaSelector({
	setActualAreas,
	currentAreas = 0,
	initialAreas = 0,
	style,
	noPrimaryContact,
	miniVersion,
	className,
	label = 'Responsibility Area(s)',
	disabled,
}: ResponsibilityAreaSelectorProps) {
	const [areas, setAreas] = React.useState<number>(initialAreas || 0);

	const parsedResponsibilities = React.useMemo(() => {
		if (!noPrimaryContact) return responsibilitiesCheckboxGroup;
		const newGroup = [...responsibilitiesCheckboxGroup];
		newGroup.shift();
		return newGroup;
	}, [noPrimaryContact]);

	const areasList = React.useMemo(
		() =>
			flagSumToArray(currentAreas).map((n, idx) => (
				<SpecializationItemWrapper spec={n} isMini={miniVersion} key={idx} />
			)),
		[currentAreas, miniVersion],
	);

	const { isOn, toggleOn, toggleOff } = useToggle();

	const applyResponsibilityChange = React.useCallback(() => {
		setActualAreas(areas);
		toggleOff();
	}, [setActualAreas, areas, toggleOff]);

	const resetResponsibilityChange = React.useCallback(() => {
		setAreas(0);
	}, [setAreas]);

	const areaChange = React.useCallback((nextValue) => setAreas(nextValue), [setAreas]);

	return (
		<div style={style} className={className}>
			{miniVersion ? (
				<>
					<Tooltip
						title={
							<>
								{label} (click to change):
								<div>{areas ? areasList : 'none'}</div>
							</>
						}
					>
						<IconButton onClick={toggleOn}>
							<Label />
						</IconButton>
					</Tooltip>
				</>
			) : (
				<>
					<InputLabel style={{ marginBottom: '8px' }}>{label}:</InputLabel>
					<div>{areasList}</div>
					{!disabled && (
						<Button onClick={toggleOn} color="secondary" size="small" variant="outlined">
							Change areas
						</Button>
					)}
				</>
			)}
			<Dialog open={isOn} onClose={toggleOff}>
				<DialogTitle>Select responsibility areas</DialogTitle>
				<DialogContent>
					<PureCheckboxGroupField
						options={parsedResponsibilities}
						changeValue={areaChange}
						value={areas}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={applyResponsibilityChange} color="primary">
						OK
					</Button>
					<Button onClick={toggleOff} color="secondary">
						Cancel
					</Button>
					<Button onClick={resetResponsibilityChange} color="secondary">
						Reset
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

type ResponsibilityAreaSelectorConnectedProps = {
	name: string;
};

export function ResponsibilityAreaSelectorConnected({
	name,
	...rest
}: ResponsibilityAreaSelectorConnectedProps &
	Omit<ResponsibilityAreaSelectorProps, 'setActualAreas'>) {
	const { values, setFieldValue } = useFormikContext<any>();

	const change = React.useCallback((flags) => setFieldValue(name, flags), [name, setFieldValue]);

	return (
		<ResponsibilityAreaSelector
			{...rest}
			setActualAreas={change}
			initialAreas={values[name] || 0}
			currentAreas={values[name]}
		/>
	);
}
