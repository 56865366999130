import React from 'react';
import { Add } from '@mui/icons-material';
import { Button } from '../../../core/components';
import MaterialDialog from '../../../flyout/components/MaterialDialog';
import { useToggle } from '../../../core/hooks';
import { useReportContext } from '../../context/ReportContext';
import SimplifiedButton from '../../simplifiedControls/SimplifiedButton';

function AttachmentNewInput({ additional, ProjectAttachmentsComponent, attachmentName }: any) {
	const { toggleOn, isOn, toggleOff } = useToggle();
	const { getChannelById, refresh } = useReportContext();
	const channelName = React.useMemo(
		() => getChannelById(additional?.PCId)?.name || 'All',
		[additional?.PCId, getChannelById],
	);
	const onClose = React.useCallback(() => {
		toggleOff();
		refresh();
	}, [refresh, toggleOff]);

	return (
		<>
			<SimplifiedButton startIcon={<Add />} onClick={toggleOn}>
				Add {attachmentName}
			</SimplifiedButton>
			<MaterialDialog
				title={'Select ' + attachmentName + ' for channel: ' + channelName}
				actions={
					<Button onClick={onClose} color="secondary">
						Close
					</Button>
				}
				isOpen={isOn}
				onClose={onClose}
				fullScreen
			>
				<ProjectAttachmentsComponent isForReference additional={additional} />
			</MaterialDialog>
		</>
	);
}

function AttachmentExistingInput({
	value = {},
	ProjectAttachmentsComponent,
	AttachmentDataComponent,
	attachmentPluralName,
}: any) {
	const { attachments = [], refresh } = useReportContext();

	const attachment = React.useMemo(
		() => attachments.filter(({ id }) => id === value.attachmentId)[0] || { id: undefined },
		[attachments, value.attachmentId],
	);
	const { id: attachmentId } = attachment;

	const [highlight, setHighlight] = React.useState<string | undefined>();
	const changeHighlight = React.useCallback(
		() => setHighlight(attachmentId),
		[setHighlight, attachmentId],
	);
	const toggleOff = React.useCallback(() => {
		setHighlight(undefined);
		refresh();
	}, [refresh, setHighlight]);

	return (
		<>
			<AttachmentDataComponent
				id={attachmentId}
				changeHighlight={changeHighlight}
				{...attachment}
			/>
			<MaterialDialog
				title={'Project ' + attachmentPluralName}
				actions={
					<Button onClick={toggleOff} color="secondary">
						Close
					</Button>
				}
				isOpen={!!highlight}
				onClose={toggleOff}
				fullScreen
			>
				<ProjectAttachmentsComponent isForReference highlight={highlight} />
			</MaterialDialog>
		</>
	);
}

type AttachmentInputProps = {
	additional: any;
	AttachmentDataComponent: React.ElementType;
	ProjectAttachmentsComponent: React.ElementType;
	attachmentName: string;
	attachmentPluralName: string;
};

export default function AttachmentInput({ additional, ...rest }: AttachmentInputProps) {
	return additional.isNewMode ? (
		<AttachmentNewInput {...rest} additional={additional} />
	) : (
		<AttachmentExistingInput {...rest} additional={additional} />
	);
}
