import React from 'react';
import { css } from '@emotion/css/macro';
import { WebUrl } from './WebUrlList';
import { MediaFile } from '../../../graphql/typescript';
import { extractAttachmentName, FileExtensionIcon } from '../helpers';
import { convertToHumanTime } from '../../core/helpers';
import { TIME_FORMAT } from '../../core/constants/time';
import { requirementNumberToString } from '../../rocTemplates/helpers';

const descriptionClass = css`
	display: flex;
	flex-direction: column;
`;

const descriptionTextClass = css`
	margin-right: 16px;
`;

export default function FileInfo({
	fileName,
	webUrl,
	description,
	actualDateTime,
	version,
	requirementNumber,
}: MediaFile) {
	const nameOfFile = React.useMemo(
		() => (fileName ? extractAttachmentName(fileName) : 'Unknown file'),
		[fileName],
	);

	const requirement = React.useMemo(
		() => requirementNumberToString(requirementNumber),
		[requirementNumber],
	);

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginRight: '0.5rem',
				wordBreak: 'break-word',
				minWidth: '100px',
			}}
		>
			<FileExtensionIcon
				fileName={nameOfFile}
				isUrl={!!webUrl}
				style={{ fontSize: '1.2rem', marginRight: '1rem' }}
			/>
			<div>
				<div>
					{!!requirement && (
						<>
							<small>Requirement {requirement}:</small>
							<br />
						</>
					)}
					{webUrl ? <WebUrl url={webUrl} label={description} /> : nameOfFile}
				</div>
				<div className={descriptionClass}>
					{!!description && !webUrl && (
						<small className={descriptionTextClass}>{description}</small>
					)}
					{!!version && <small>- Version {version}</small>}
					{!!actualDateTime && (
						<small>- Dated {convertToHumanTime(actualDateTime, TIME_FORMAT.DATE_ONLY)}</small>
					)}
				</div>
			</div>
		</div>
	);
}
