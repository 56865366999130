import { Chip } from '@material-ui/core';
import { css } from '@emotion/css/macro';
import { Warning } from '@mui/icons-material';
import { warning } from '../../ui/Core/stylesheets/colors';

const chipClass = css`
	border-color: ${warning} !important;
	color: ${warning};
	padding: 2px;
	margin-top: 4px;
`;

const iconClass = css`
	padding: 4px;
	color: ${warning} !important;
	width: 16px !important;
	height: 16px !important;
`;

type SampledWarningProps = { totalSampled?: number; totalPopulation?: number };

export default function SampledWarning({ totalSampled, totalPopulation }: SampledWarningProps) {
	if (!totalPopulation || totalPopulation < 2 || (!!totalSampled && totalSampled > 1)) return null;

	return (
		<Chip
			className={chipClass}
			avatar={<Warning className={iconClass} />}
			label={
				<>
					Sample size of less than 2 may increase
					<br />
					the likelihood of PCI Council audit
				</>
			}
			variant="outlined"
		/>
	);
}
