import React from 'react';
import usePopup from './usePopup';
import { success } from '../../ui/Core/stylesheets/colors';

export default function useSuccessPopup() {
	const { showPopup } = usePopup();

	return React.useCallback(
		(text?: React.ReactNode) => {
			showPopup({
				color: success,
				text: text || 'Success',
			});
		},
		[showPopup],
	);
}
