import React from 'react';
import { css } from '@emotion/css/macro';

const fileInputClass = css`
	display: none;
`;

type HiddenFileInputType = { multiple?: boolean; onChange?: any; accept?: string };

export const HiddenFileInput = React.forwardRef<HTMLInputElement, HiddenFileInputType>(
	(props, ref) => <input ref={ref} className={fileInputClass} type="file" {...props} />,
);

export const draggedOverClass = css`
	opacity: 0.2;
`;
