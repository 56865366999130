import React from 'react';
import SelectField, {
	toSelectOptions,
	SelectFieldProps,
} from '../../formaggio/components/SelectField';
import { useUserCompanies } from '../hooks';

type CompanySelectProps = {
	onSelect?: SelectFieldProps['onChange'];
	name?: string;
	id?: string;
	hasUnassigned?: boolean;
	label?: string;
};

export default function CompanySelect({
	onSelect,
	name = 'companyId',
	id = name,
	hasUnassigned,
	label = 'Company',
}: CompanySelectProps) {
	const [companies] = useUserCompanies();
	const options = React.useMemo(() => {
		const result: { value: string; title: string }[] = [];
		if (hasUnassigned) result.push({ value: 'UNASSIGNED', title: 'Unassigned' });
		return [...result, ...toSelectOptions(companies, 'id', 'companyName')];
	}, [companies, hasUnassigned]);

	if (options.length === 0 || (companies.length === 1 && !hasUnassigned)) return null;

	return (
		<SelectField
			fullWidth
			label={label}
			onChange={onSelect}
			id={id}
			name={name}
			options={options}
			searchable
		/>
	);
}
