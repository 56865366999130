import { Download } from '@mui/icons-material';
import React from 'react';
import { Button } from '../../../core/components';
import { downloadFile } from '../../../files/helpers';
import { DownloadState } from '../../../../shared/modules/files/constants';

type ContainerExportProps = {
	containerId: string;
	projectId: string;
};

export default function ContainerExport({ containerId, projectId }: ContainerExportProps) {
	const [downloadState, setDownloadState] = React.useState<DownloadState>(DownloadState.Idle);

	const download = React.useCallback(
		() =>
			downloadFile({
				setDownloadState,
				query: `RocModelControllerNew/ExportItem/${projectId}/${containerId}`,
				fileName: 'Targeted_Risk_Analysis.docx',
			}),
		[setDownloadState, projectId, containerId],
	);

	return (
		<div style={{ textAlign: 'right', marginTop: 4, marginBottom: 4 }}>
			<Button
				startIcon={<Download />}
				disabled={downloadState === DownloadState.Downloading}
				onClick={download}
				size="large"
			>
				Export Table as DOCX
			</Button>
		</div>
	);
}
