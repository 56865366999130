import React from 'react';
import { css } from '@emotion/css/macro';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { ApprovalAuthor, AttachmentLink, MediaFile } from '../../../graphql/typescript';
import { RocDataType } from '../../rocTemplates/enums';
import FolderTile from './FolderTile';

function reviewedAttachmentsHelper(workflows, attachments, projectId, id) {
	const allLinks = workflows.reduce(
		(memo, { attachments: links = [] }) =>
			links.length > 0 ? [...memo, ...links.filter((l) => !!l.approvalAuthor)] : memo,
		[],
	);

	const workflowAttachmentIds = allLinks.map(({ attachmentId }) => attachmentId);

	return attachments
		.filter(
			(a: MediaFile) =>
				a.type === RocDataType.MediaFile && workflowAttachmentIds.indexOf(a.id) >= 0,
		)
		.map((a: MediaFile) => {
			const approvalAuthors = allLinks.reduce(
				(memo: ApprovalAuthor[], { attachmentId, approvalAuthor }: AttachmentLink) =>
					attachmentId === a.id &&
					memo.filter((author) => author.authorId === approvalAuthor.authorId).length === 0
						? [...memo, approvalAuthor]
						: memo,
				[],
			);

			return (
				<FolderTile
					key={a.id}
					{...a}
					modelId={projectId}
					itemId={id}
					approvalAuthors={approvalAuthors}
					showApprovalAuthor
				/>
			);
		});
}

const rootClass = css`
	margin-bottom: 4px;
`;

export default function OptionalFilesList() {
	const { projectId, attachments = [] } = useReportContext();
	const { itemId, lists, rocDataType } = useItemContext();
	const { workflows = [] } = lists;

	const reviewedAttachments = React.useMemo(
		() => reviewedAttachmentsHelper(workflows, attachments, projectId, itemId),
		[attachments, itemId, projectId, workflows],
	);

	// Don't display if it's already a file control so the list doesn't duplicate
	if (rocDataType === RocDataType.MediaFile) return null;

	return <div className={rootClass}>{reviewedAttachments}</div>;
}
