import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { cx } from '@emotion/css/macro';
import {
	SampleSet as SampleSetType,
	SampleSetItem as SampleSetItemType,
} from '../../../graphql/typescript';
import { TableStickyCell } from '../../../shared/components';
import { BaseAttachmentProps } from '../types';
import { attachmentHighlightClass } from '../styles';
import { useReportInfoContext } from '../../rocTemplates/context/ReportContext';

function SampleSetItem({
	item,
	totalPopulation,
	totalSampled,
	makeModelVersionRelease,
}: SampleSetItemType) {
	return (
		<>
			<TableStickyCell>{item}</TableStickyCell>
			<TableStickyCell>{makeModelVersionRelease}</TableStickyCell>
			<TableStickyCell>{totalSampled}</TableStickyCell>
			<TableStickyCell>{totalPopulation}</TableStickyCell>
		</>
	);
}

type SampleSetProps = SampleSetType & BaseAttachmentProps;

export default function SampleSet({
	referenceNumber,
	description,
	items,
	children,
	highlighted,
	allItems,
	selectionMethod,
	totalSampled,
	totalPopulation,
}: SampleSetProps) {
	const { version } = useReportInfoContext();

	const renderItems = React.useMemo(() => {
		const newItems = [...(items || [])];
		const firstItem = newItems?.shift();
		return {
			rowSpan: newItems.length + 1,
			firstItem: <SampleSetItem {...firstItem} />,
			otherItems: newItems.map((sampleSetItem, idx) => (
				<TableRow key={idx}>
					<SampleSetItem {...sampleSetItem} />
				</TableRow>
			)),
		};
	}, [items]);

	return (
		<>
			<TableRow>
				<TableStickyCell
					rowSpan={renderItems.rowSpan}
					className={cx(highlighted ? attachmentHighlightClass : null)}
				>
					{referenceNumber}
				</TableStickyCell>
				<TableStickyCell rowSpan={renderItems.rowSpan}>{description}</TableStickyCell>
				{version ? (
					<>
						<TableStickyCell>{allItems}</TableStickyCell>
						<TableStickyCell>{selectionMethod}</TableStickyCell>
						<TableStickyCell>{totalSampled}</TableStickyCell>
						<TableStickyCell>{totalPopulation}</TableStickyCell>
					</>
				) : (
					renderItems.firstItem
				)}
				{children}
			</TableRow>
			{renderItems.otherItems}
		</>
	);
}
