import { ChangeEvent } from 'react';
import { Box } from '@mui/material';
import { css } from '@emotion/css/macro';
import { mainDark } from '../../ui/Core/stylesheets/colors';

const radioClass = css`
	filter: grayscale(1);
	margin: 0 8px 8px 0;
`;

export type SimplifiedChoiceWrapperProps = {
	name?: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	value?: any;
	id?: string;
	disabled?: boolean;
};

export default function SimplifiedChoiceWrapper({
	options = [],
	name,
	onChange,
	value,
	id,
	Input,
	checkedFunc,
	disabled,
}: SimplifiedChoiceWrapperProps & {
	Input: any;
	checkedFunc: (value: any, optValue: any) => boolean;
	options: { label: string; value?: string | number; disabled?: boolean }[];
}) {
	return (
		<Box display="flex">
			{options.map(({ label, value: optValue, disabled: disabledOption }) => (
				<Box margin="0 8px 8px 0" key={optValue}>
					<Input
						id={id}
						name={name}
						value={optValue}
						onChange={onChange}
						checked={checkedFunc(value, optValue)}
						color={mainDark}
						className={radioClass}
						disabled={disabledOption || disabled}
					/>
					{label && <label htmlFor={id}>{label}</label>}
				</Box>
			))}
		</Box>
	);
}
