import gql from 'graphql-tag';

export default gql`
	query getRocItemInterviewInfo($rocModelId: String!, $rocItemId: String!) {
		info: get_api_RocItemControllerNew_getRocItemInterviewInfo_rocModelId_rocItemId(
			rocModelId: $rocModelId
			rocItemId: $rocItemId
		)
			@rest(
				path: "RocItemControllerNew/getRocItemInterviewInfo/{args.rocModelId}/{args.rocItemId}"
			) {
			data {
				item {
					id
					responsibilityArea
					itemDescription
					numbering
					chapterId
				}
				responsibleContacts {
					id
					name
					responsibilityArea
					jobTitle
					organization
					email
				}
			}
		}
	}
`;
