import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { mainLight } from '../../ui/Core/stylesheets/colors';

type PageButtonProps = {
	onClick?: (number: number | string) => void;
	number: number | string;
	active?: boolean;
} & ButtonProps;

function PageButton({ onClick, number, active, ...rest }: PageButtonProps) {
	const clickHandler = React.useCallback(() => {
		if (onClick) {
			onClick(number);
		}
	}, [onClick, number]);

	return (
		<Button
			variant="contained"
			{...rest}
			color={active ? 'primary' : 'inherit'}
			onClick={clickHandler}
			style={active ? { background: mainLight, color: 'white' } : {}}
		>
			{number}
		</Button>
	);
}

type PaginationProps = {
	pagesCount: number;
	buttonsCount: number;
	onPageClick: (number: number) => void;
	className?: string;
};

export default function Pagination({
	pagesCount = 0,
	buttonsCount,
	onPageClick,
	className,
}: PaginationProps) {
	const [page, setPage] = React.useState<number>(0);

	const pages = React.useMemo(
		() => [...Array(pagesCount < buttonsCount ? pagesCount : buttonsCount).keys()],
		[pagesCount, buttonsCount],
	);

	const renderPages = React.useMemo(
		() =>
			pages.map((number) => {
				const halfButtonsCount = Math.round(buttonsCount / 2) + 1;
				let calc = page + number - halfButtonsCount + halfButtonsCount / 2;
				if (page < halfButtonsCount) {
					calc = number;
				}
				if (pagesCount - page < halfButtonsCount) {
					calc = number + pagesCount - halfButtonsCount - Math.round(buttonsCount / 4);
				}
				const finalNumber = buttonsCount > pagesCount ? number : calc;

				const click = () => {
					setPage(finalNumber);
					onPageClick(finalNumber);
				};

				return (
					<PageButton
						key={finalNumber}
						onClick={click}
						active={finalNumber === page}
						number={finalNumber + 1}
					/>
				);
			}),
		[page, pagesCount, buttonsCount, onPageClick, pages],
	);

	const firstClick = React.useCallback(() => {
		setPage(0);
		onPageClick(0);
	}, [onPageClick, setPage]);

	const lastClick = React.useCallback(() => {
		setPage(pagesCount - 1);
		onPageClick(pagesCount - 1);
	}, [pagesCount, onPageClick, setPage]);

	if (pagesCount <= 1) {
		return null;
	}

	return (
		<ButtonGroup size="small" variant="text" className={className}>
			{page !== 0 && <PageButton onClick={firstClick} number="&laquo;" />}
			{renderPages}
			{page !== pagesCount - 1 && <PageButton onClick={lastClick} number="&raquo;" />}
		</ButtonGroup>
	);
}
