import React from 'react';
import { Box } from '@mui/material';
import { error } from '../ui/Core/stylesheets/colors';
import { getContrastText } from '../ui/Core/helpers';
import { useHubState } from '../hubs/hooks';
import { CONFIG } from '../core/constants/config';
import { FeatureFlagDetails } from './featureFlags';
import { FeatureDetail } from '../core/types/api';

type FeatDescProps = FeatureDetail & { isEnabled?: boolean };

function FeatDescription({ description, isEnabled }: FeatDescProps) {
	return (
		<p>
			[{isEnabled ? 'Enabled' : 'Disabled'}] {description}
		</p>
	);
}

function processFeats(
	a: FeatureFlagDetails,
	b: FeatureFlagDetails,
	isEnabled?: boolean,
): FeatDescProps[] {
	if (!a || !b) return [];

	const bkeys = Object.keys(b);

	return Object.keys(a)
		.filter((ak) => bkeys.indexOf(ak) < 0)
		.map((ak) => {
			const av = a[ak];
			return av ? { ...av, isEnabled } : null;
		})
		.filter((f) => !!f);
}

function getUpdatedFeatureDescriptions(
	prevFeats: FeatureFlagDetails,
	newFeats: FeatureFlagDetails,
) {
	return [...processFeats(newFeats, prevFeats, true), ...processFeats(prevFeats, newFeats)];
}

function UpdatesList({ children }) {
	return (
		<Box>
			<p>TurboQSA instance updated</p>
			{children}
			<>Page refresh required</>
		</Box>
	);
}

function Floater() {
	const [hubState] = useHubState();
	const { current, first } = hubState.licenseStatus;
	const { featuresDetailed: newFeats, build } = current;
	const { featuresDetailed: prevFeats } = first;
	const render = React.useMemo(() => {
		const featUpdate = getUpdatedFeatureDescriptions(prevFeats, newFeats).map((d, idx) => (
			<FeatDescription {...d} key={idx} />
		));
		const buildUpdate = !build || build === CONFIG.buildInfo ? null : <p>New {build}</p>;

		return {
			featUpdate,
			buildUpdate,
			hasUpdates: !!buildUpdate || featUpdate.length > 0,
		};
	}, [build, newFeats, prevFeats]);

	if (!render.hasUpdates) return null;

	return (
		<Box
			color={getContrastText(error)}
			bgcolor={error}
			padding="4px 8px"
			fontSize="13px"
			position="fixed"
			bottom="10px"
			left="10px"
			borderRadius="5px"
			maxWidth="200px"
			zIndex={1}
		>
			{render.hasUpdates && (
				<UpdatesList>
					{render.buildUpdate}
					{render.featUpdate}
				</UpdatesList>
			)}
		</Box>
	);
}

export default function LicenseAlerts() {
	const [hubState] = useHubState();

	return hubState.licenseStatus.isChanged ? <Floater /> : null;
}
