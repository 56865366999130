import React from 'react';
import { css } from '@emotion/css/macro';
import {
	ApprovalAuthor,
	AttachmentLink,
	MediaFile,
	RocDataAction,
	RocDataType,
} from '../../core/types/api';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import FolderTile from '../../evidence/components/FolderTile';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { Button } from '../../core/components';
import { useAllowedMethods } from '../../auth/hooks';
import { useAPI, useToggle } from '../../core/hooks';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { SelectField, TextField } from '../../formaggio';
import { FormType } from '../../formaggio/constants';
import FileInfo from '../../evidence/components/FileInfo';

type ApproveWorkflowMediaFileType = {
	attachment: MediaFile;
	workflowId?: string;
	isApproved?: boolean;
	refreshList?: () => void;
};

function ApproveWorkflowMediaFile({
	workflowId,
	attachment,
	isApproved,
	refreshList,
}: ApproveWorkflowMediaFileType) {
	const { projectId, refresh } = useReportContext();

	const { fetchAPI } = useAPI();

	const setApproval = React.useCallback(
		() =>
			fetchAPI({
				query: `Workflow/ApproveFile/${projectId}/${workflowId}/${attachment.id}`,
				method: 'POST',
				qs: { isApproved: !isApproved },
				onSuccess: () => {
					refresh();
					refreshList();
				},
			}),
		[attachment.id, fetchAPI, isApproved, projectId, refresh, refreshList, workflowId],
	);

	return (
		<Button size="small" onClick={setApproval} color={isApproved ? 'secondary' : 'primary'}>
			{isApproved ? 'Remove approval' : 'Mark reviewed'}
		</Button>
	);
}

type WorkflowLinkedMediaFileType = {
	id?: string;
	workflowId?: string;
	message?: string;
	availableChannelOptions: any[];
	approvalAuthor?: ApprovalAuthor;
	refreshList: () => void;
};

function UseWorkflowMediaFile({
	attachment,
	availableChannelOptions,
	refreshList,
}: WorkflowLinkedMediaFileType & ApproveWorkflowMediaFileType) {
	const { projectId, refresh } = useReportContext();
	const { itemId } = useItemContext();
	const { isOn, toggleOn, toggleOff } = useToggle();

	if (!availableChannelOptions || availableChannelOptions.length === 0) return null;

	return (
		<>
			<Button size="small" onClick={toggleOn}>
				Use
			</Button>
			<MaterialFlyOut
				open={isOn}
				onClose={toggleOff}
				title="Use file as response"
				formProps={{
					formType: FormType.Basic,
					fetchConfig: {
						query: `RocItemControllerNew/report/setValue/${projectId}/${itemId}/${RocDataAction.Insert}`,
						method: 'POST',
						onSuccess: () => {
							refresh();
							refreshList();
							toggleOff();
						},
					},
					initialValues: {
						rocDataType: RocDataType.MediaFile,
						attachmentId: attachment.id,
						paymentChannelId: availableChannelOptions[0].value,
					},
				}}
			>
				<FileInfo {...attachment} />
				<SelectField
					options={availableChannelOptions}
					label="Channel"
					name="paymentChannelId"
					fullWidth
					noEmpty
				/>
				<TextField
					label="Evidence location"
					name="evidenceLocation"
					fullWidth
					multiline
					variant="outlined"
				/>
				<Button type="submit">Submit</Button>
			</MaterialFlyOut>
		</>
	);
}

function WorkflowLinkedMediaFile({
	workflowId,
	attachmentId,
	message,
	availableChannelOptions,
	approvalAuthor,
	refreshList,
}: { id?: string; attachmentId?: string } & WorkflowLinkedMediaFileType) {
	const { 'Evidences/Create': canUseFile, 'Workflow/ApproveFile': canApproveFile } =
		useAllowedMethods();
	const { attachments, projectId } = useReportContext();
	const { itemId } = useItemContext();
	const attachment = attachments?.filter(({ id }) => id === attachmentId)[0];
	const { rocDataType } = useItemContext();

	return (
		<FolderTile
			{...(attachment as MediaFile)}
			modelId={projectId}
			itemId={itemId}
			approvalAuthors={approvalAuthor ? [approvalAuthor] : undefined}
			showApprovalAuthor
			additionalControls={
				!!attachment && (
					<>
						{rocDataType === RocDataType.MediaFile ? (
							<>
								{canUseFile && (
									<UseWorkflowMediaFile
										attachment={attachment as MediaFile}
										message={message}
										availableChannelOptions={availableChannelOptions}
										refreshList={refreshList}
									/>
								)}
							</>
						) : (
							<>
								{canApproveFile && (
									<ApproveWorkflowMediaFile
										workflowId={workflowId}
										attachment={attachment as MediaFile}
										isApproved={!!approvalAuthor}
										refreshList={refreshList}
									/>
								)}
							</>
						)}
					</>
				)
			}
		/>
	);
}

function WorkflowLinkedDataEntry({ ...props }: AttachmentLink & WorkflowLinkedMediaFileType) {
	return <WorkflowLinkedMediaFile {...props} />;
}

const rootClass = css`
	margin-top: 8px;
`;

export default function WorkflowLinkedData({
	workflowId,
	attachmentLinks,
	message,
	availableChannelOptions,
	refreshList,
}: { attachmentLinks: AttachmentLink[] } & WorkflowLinkedMediaFileType) {
	const render = React.useMemo(
		() =>
			attachmentLinks?.map((props, idx) => (
				<WorkflowLinkedDataEntry
					workflowId={workflowId}
					{...props}
					key={idx}
					message={message}
					availableChannelOptions={availableChannelOptions}
					refreshList={refreshList}
				/>
			)),
		[workflowId, availableChannelOptions, attachmentLinks, message, refreshList],
	);

	return <div className={rootClass}>{render}</div>;
}
