import { css } from '@emotion/css/macro';
import { mainLight } from '../../ui/Core/stylesheets/colors';
import { getBrandAssetFile } from '../../branding/helpers';
import { brandTitle } from '../../branding/constants';

const logoClass = css`
	text-align: center;
	font-size: 1.5rem;
	font-weight: 300;
	color: ${mainLight};
	margin: auto;
`;

const imageClass = css`
	width: 190px;
`;

type HeaderImageProps = { horizontal?: boolean; className?: string };

export function HeaderImage({ horizontal, className }: HeaderImageProps) {
	return (
		<img
			className={className}
			src={getBrandAssetFile(
				horizontal ? 'horizontalLogoUrl' : 'logoUrl',
				horizontal ? 'horizontal-logo.svg' : 'logo.svg',
			)}
			alt={brandTitle}
		/>
	);
}

const Header = (
	<div className={logoClass}>
		<HeaderImage className={imageClass} />
	</div>
);

export default Header;
