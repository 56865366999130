import Data from '../../UserData';
import { TextAreaInput, TextAreaPresentation } from '../TextField';
import { RocDataType } from '../../enums';
import OscalParameter from './OscalParameter';

const retrieveValue = (e: any) => ({
	value: e.target.value,
});

const validation = ({ value }: any) => (!String(value || '').trim() ? 'Required field' : undefined);

export default function OscalTextField({ ...rest }: any) {
	return (
		<OscalParameter {...rest}>
			<Data
				retrievedValue={retrieveValue}
				inputElement={TextAreaInput}
				presentationElement={TextAreaPresentation}
				{...rest}
				validation={validation}
				rocDataType={RocDataType.Text}
			/>
		</OscalParameter>
	);
}
