import React from 'react';
import { css } from '@emotion/css/macro';
import { Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import { IconButton } from '../../core/components';
import { error, mainLight } from '../../ui/Core/stylesheets/colors';
import { useAPI } from '../../core/hooks';

const iconButtonClass = css`
	color: ${mainLight};
`;

const editIconClass = css`
	font-size: 1rem;
`;

type PostControlsProps = {
	query: string;
	children?: React.ReactNode;
	onClickEdit: () => void;
	refresh: () => void;
};

export default function PostControls({ onClickEdit, refresh, query, children }: PostControlsProps) {
	const { fetchAPI } = useAPI();

	const deletePost = React.useCallback(
		() =>
			fetchAPI({
				query,
				method: 'DELETE',
				onSuccess: refresh,
				confirmation: true,
			}),
		[fetchAPI, query, refresh],
	);

	return (
		<Box
			display="flex"
			alignItems="center"
			marginLeft="16px"
			padding="4px"
			bgcolor="white"
			borderRadius="16px"
		>
			<IconButton onClick={onClickEdit} className={iconButtonClass} color="primary">
				<Edit className={editIconClass} />
			</IconButton>
			<IconButton onClick={deletePost} className={iconButtonClass}>
				<Delete htmlColor={error} />
			</IconButton>
			{children}
		</Box>
	);
}
