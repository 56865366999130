import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { ContactPage } from '@mui/icons-material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { Button, Loading } from '../core/components';
import { useToggle } from '../core/hooks';
import NewContactFlyOut from '../contact/components/NewContactFlyOut';
import { useAllowedMethods } from '../auth/hooks';
import { useProjectInfo } from '../project/hooks';
import { Requestee, RequesteeWithDesc } from './Request';
import { useContactAndUserList } from '../users/hooks';
import { hasFlag } from '../core/helpers';
import { Roles } from '../core/types/api';

type RequesteeDialogProps = {
	projectId: string;
	onClose: () => void;
	onChoose: (client: any) => void;
	alreadyRequestedIds?: string[];
	requestees: Requestee[];
	itemResponsibility?: number;
};

export default function RequesteeDialog({
	projectId,
	onClose,
	onChoose,
	alreadyRequestedIds,
	requestees,
	itemResponsibility,
}: RequesteeDialogProps) {
	const { 'Contact/CreateContact': canCreateContact } = useAllowedMethods();

	const { clientId } = useProjectInfo(projectId).data;

	const { isOn: isResponsibilityAreaOn, handleCheckbox } = useToggle(!!itemResponsibility);

	const { list, refetchContacts } = useContactAndUserList({
		projectId,
		isResponsibilityAreaOn,
		responsibilityArea: itemResponsibility,
	});

	const mappedList = React.useMemo(() => {
		if (!list) return <Loading />;

		return list
			.filter(({ projectRole }) =>
				hasFlag(Roles.Client + Roles.ExecutiveClientRepresentative + Roles.Contact, projectRole),
			)
			.filter(
				({ id = '' }) =>
					alreadyRequestedIds?.indexOf(id) < 0 && requestees?.map((r) => r.id).indexOf(id) < 0,
			)
			.map(({ id, ...req }) => {
				const choose = req.email ? () => onChoose({ id, ...req }) : undefined;

				return (
					<ListItemButton key={id} onClick={choose}>
						<ListItemText primary={<RequesteeWithDesc id={id} {...req} />} />
					</ListItemButton>
				);
			});
	}, [list, alreadyRequestedIds, requestees, onChoose]);

	const { isOn, toggleOn, toggleOff } = useToggle();

	const onCreatedContact = React.useCallback(
		(response) => {
			if (!refetchContacts) return;
			refetchContacts().then(() => {
				onChoose({ ...response?.data, isUser: false });
				toggleOff();
				onClose();
			});
		},
		[onChoose, toggleOff, refetchContacts, onClose],
	);

	return (
		<>
			<Dialog open>
				<DialogTitle>Choose user / contact</DialogTitle>
				<DialogContent>
					{!!itemResponsibility && (
						<FormControlLabel
							control={
								<Checkbox
									checked={isResponsibilityAreaOn}
									onChange={handleCheckbox}
									color="primary"
								/>
							}
							label="Responsible for this area"
						/>
					)}
					<List>{mappedList}</List>
				</DialogContent>
				<DialogActions>
					{canCreateContact && (
						<Button onClick={toggleOn} color="secondary" startIcon={<ContactPage />}>
							New Contact
						</Button>
					)}
					<Button color="primary" onClick={onClose}>
						OK
					</Button>
				</DialogActions>
			</Dialog>
			<NewContactFlyOut
				isOpen={isOn}
				onClose={toggleOff}
				clientId={clientId}
				onSuccess={onCreatedContact}
			/>
		</>
	);
}
