import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import { css } from '@emotion/css/macro';
import { styles } from '../constants/styles';
import TableCell from './TableCell';
import { useReplicator } from '../hooks';
import { useReportInfoContext } from '../context/ReportContext';

export default function OneMultipleRow({
	children = [],
	viewMode,
	fetchAPI,
	path = [],
	itemId,
}: any) {
	const { version } = useReportInfoContext();
	const Replicator = useReplicator(viewMode);
	const columns = React.useMemo(
		() => ({ first: children[0], secondRows: children.filter((_, idx) => idx !== 0) }),
		[children],
	);

	const renderedSecondColumnRows = React.useMemo(
		() =>
			columns.secondRows.map(({ props }: any, idx) => (
				<Replicator
					{...props}
					key={props.itemId}
					id={props.itemId}
					fetchAPI={fetchAPI}
					viewMode={viewMode}
					path={[...path, `children[${idx + 1}]`]}
				/>
			)),
		[columns.secondRows, Replicator, fetchAPI, viewMode, path],
	);

	const calculatedRowSpan = React.useMemo(
		() => columns.secondRows.length + 1,
		[columns.secondRows.length],
	);

	return (
		<>
			<TableRow>
				<Replicator
					{...columns.first.props}
					fetchAPI={fetchAPI}
					viewMode={viewMode}
					className={
						version
							? undefined
							: css`
									${styles.rocLightGrey}
							  `
					}
					rowId={itemId}
					cellIndex={0}
					path={[...path, 'children[0]']}
					customAttributes={{ rowSpan: calculatedRowSpan }}
					wrapperComponent={TableCell}
				/>
			</TableRow>
			{viewMode ? renderedSecondColumnRows : <div>{renderedSecondColumnRows}</div>}
		</>
	);
}
