import Card from '@material-ui/core/Card';
import { css } from '@emotion/css/macro';
import { Heading } from '../core/components';
import { convertToHumanTime } from '../core/helpers';

const headerClass = css`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

type TextHistoryItemProps = {
	author?: string;
	changeDateTime?: string;
	text?: string;
};

export default function TextHistoryItem({ author, changeDateTime, text }: TextHistoryItemProps) {
	return (
		<Card elevation={3} style={{ padding: '8px 16px', marginBottom: '7px' }}>
			<div className={headerClass}>
				<Heading variant="subtitle1">
					<b>{author}</b>
				</Heading>
				<Heading variant="subtitle2" color="textSecondary">
					{convertToHumanTime(changeDateTime)}
				</Heading>
			</div>
			{/* eslint-disable-next-line react/no-danger */}
			<div dangerouslySetInnerHTML={{ __html: text }} />
		</Card>
	);
}
