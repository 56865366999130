import { css } from '@emotion/css/macro';
import Fab from '@material-ui/core/Fab';
import { ArrowBack, Home } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useNavigation } from '../hooks';

const messageClass = css`
	margin-top: 2rem;
	button {
		margin: 0 0.5rem;
	}
`;

export default function PageNotFound() {
	const { goBack, goToPortal } = useNavigation();

	return (
		<Box display="flex" flexDirection="column" alignItems="center">
			<h2>Page not found</h2>
			<div className={messageClass}>
				<Fab color="secondary" onClick={goBack}>
					<ArrowBack />
				</Fab>
				<Fab color="primary" onClick={goToPortal}>
					<Home />
				</Fab>
			</div>
		</Box>
	);
}
