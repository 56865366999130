import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { css } from '@emotion/css/macro';
import { Close } from '@mui/icons-material';
import { Button, IconButton } from '../core/components';
import { darkness } from '../ui/Core/stylesheets/colors';
import { ROUTER_PATHS } from '../core/constants/paths';
import { brandTitle } from '../branding/constants';

const linkClass = css`
	color: white;
`;

enum Action {
	Accepted,
	Refused,
	Closed,
}

const COOKIES_ACCEPTED_KEY = 'cookiesAccepted';

function writeStorage(action: Action) {
	localStorage.setItem(COOKIES_ACCEPTED_KEY, String(action));
}

export default function CookieNotification() {
	const [closed, setClosed] = React.useState(
		!!localStorage.getItem(COOKIES_ACCEPTED_KEY) ||
			window.location.pathname === ROUTER_PATHS.POLICIES,
	);

	const close = React.useCallback(() => {
		setClosed(true);
		writeStorage(Action.Closed);
	}, [setClosed]);

	if (closed) return null;

	return (
		<Snackbar
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open
			message={
				<>
					{brandTitle} uses cookies to store certain information on your web browser. These cookies
					allow for the application to function correctly.
					<br />
					<a
						className={linkClass}
						target="_blank"
						rel="noopener noreferrer"
						href={ROUTER_PATHS.POLICIES}
					>
						View our Cookie Policy here
					</a>{' '}
					to learn more. To use the {brandTitle} application you must accept our cookie policy.
				</>
			}
			ContentProps={{ role: 'banner', style: { backgroundColor: darkness } }}
			style={{ bottom: '10%' }}
			action={
				<>
					<Button color="primary" onClick={close} size="small" style={{ marginRight: '0.5rem' }}>
						Accept
					</Button>
					<IconButton onClick={close}>
						<Close htmlColor="white" />
					</IconButton>
				</>
			}
		/>
	);
}
