import React from 'react';
import { cx } from '@emotion/css/macro';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import { TableStickyCell } from '../../../shared/components';
import { BaseAttachmentProps } from '../types';
import { attachmentHighlightClass } from '../styles';
import SampleSetData from '../sampleSets/SampleSetData';

type SystemEvidenceProps = any & BaseAttachmentProps;

export default function SystemEvidence({
	description,
	children,
	referenceNumber,
	highlighted,
	componentsReviewed,
	sampleSets = [],
}: SystemEvidenceProps) {
	const renderSampleSets = React.useMemo(
		() =>
			sampleSets.map((sampleSet: any, idx: number) => <SampleSetData {...sampleSet} key={idx} />),
		[sampleSets],
	);

	return (
		<TableRow>
			<TableStickyCell className={cx(highlighted ? attachmentHighlightClass : null)}>
				{referenceNumber}
			</TableStickyCell>
			<TableStickyCell>
				{componentsReviewed && <p>{componentsReviewed}</p>}
				{componentsReviewed && sampleSets.length > 0 && (
					<p>
						<Divider />
					</p>
				)}
				{renderSampleSets}
			</TableStickyCell>
			<TableStickyCell>{description}</TableStickyCell>
			{children}
		</TableRow>
	);
}
