import React from 'react';
import { css, cx } from '@emotion/css/macro';

const baseClass = css`
	margin: 0;
	margin-top: 0.25rem;
	color: #999;
	font-size: 90%;
`;

type HelperTextProps = {
	children?: React.ReactNode;
	className?: string;
};

export default function HelperText({ children, className }: HelperTextProps) {
	return <div className={cx(baseClass, className)}>{children}</div>;
}
