import { Box } from '@mui/material';
import { InfoOutlined, TimerOutlined } from '@mui/icons-material';
import SimplifiedFrame from '../../simplifiedControls/SimplifiedFrame';

type OscalParameterProps = any;

export default function OscalParameter({
	label,
	guideline,
	constraint,
	children,
}: OscalParameterProps) {
	return (
		<SimplifiedFrame>
			{label && (
				<Box fontWeight="bold" fontSize="14px">
					{label}
				</Box>
			)}
			{guideline && (
				<Box marginTop="4px" display="flex" alignItems="center">
					<InfoOutlined fontSize="small" />
					<Box marginLeft="8px">{guideline}</Box>
				</Box>
			)}
			{constraint && (
				<Box marginTop="4px" display="flex" alignItems="center">
					<TimerOutlined fontSize="small" />
					<Box marginLeft="8px">{constraint}</Box>
				</Box>
			)}
			{children}
		</SimplifiedFrame>
	);
}
