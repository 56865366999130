export {
	NodeType,
	RocItemType,
	RocDataType,
	RocDataAction,
	SummaryOfAssessmentFindingsState as SummaryOfAssessmentState,
	ContainerRocItemType,
} from '../core/types/api';

export enum RocItemSubType {
	Section = 'section', // For containers only. this will display children items as contents (tiles) on client
	Section6 = 's6', // For table containers in section 6. displays header on top of table (8 columns in total)
	DocumentationReviewed = 'documentationReviewed', // A container in section 4.9 to display file upload function
	ProjectSampleSets = 'projectSampleSets', // A container in 4.6 that is replaced with dynamic Sample Sets functionality
	InterviewLink = 'InterviewLink', // Render link in ROC v4 Interviews Table
	TargetedRiskAnalysis = 'TRA', // TRA table in Customized approach
}

export enum SummaryOfRequirementState {
	None = 0,
	InPlace = 1,
	NotApplicable = 2,
	NotTested = 4,
	NotInPlace = 8,
}

export enum AocSummaryOfRequirementsState {
	Compliant,
	NotCompliant,
}
