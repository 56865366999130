import { PlaylistAddCheck } from '@mui/icons-material';
import { css, cx } from '@emotion/css/macro';
import { ItemStatus } from '../../project/enums';
import Counter from '../../core/components/Counter';
import WorkflowList from './WorkflowList';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { useToggle } from '../../core/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { hover } from '../../ui/Core/stylesheets/keyframes';
import SimplifiedButton from '../../rocTemplates/simplifiedControls/SimplifiedButton';
import { useWorkflowOpenQS } from '../../rocTemplates/hooks';

const workflowAttentionClass = css`
	animation: ${hover} 2s infinite ease-in-out !important;
	box-shadow: 0 0 8px 8px red !important;
	color: red !important;
`;

type WorkflowButtonProps = {
	channels?: any[];
	itemId: string;
	list: any[];
	itemResponsibility?: number;
};

export default function WorkflowButton({
	channels = [],
	list = [],
	itemId,
	itemResponsibility,
	...rest
}: WorkflowButtonProps) {
	const { status } = useItemContext();
	const { projectId, refresh, isTokenPage } = useReportContext();
	const initiallyOpen = useWorkflowOpenQS(itemId);
	const {
		isOn: isWorkflowOn,
		toggleOn: workflowOn,
		toggleOff: workflowOff,
	} = useToggle(initiallyOpen);

	return (
		<>
			<SimplifiedButton
				startIcon={<PlaylistAddCheck />}
				onClick={workflowOn}
				tabIndex={-1}
				data-testid={`UserControls_${itemId}__workflow`}
				className={cx(
					isTokenPage && status === ItemStatus.WaitingForCustomer && workflowAttentionClass,
				)}
				{...rest}
			>
				<Counter secondary count={list.length}>
					Collaborate
				</Counter>
			</SimplifiedButton>
			<MaterialFlyOut open={isWorkflowOn} onClose={workflowOff} title="Collaborate">
				<WorkflowList
					modelId={projectId}
					itemId={itemId}
					refresh={refresh}
					availableChannelOptions={channels}
					itemResponsibility={itemResponsibility}
				/>
			</MaterialFlyOut>
		</>
	);
}
