import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@mui/icons-material';
import { HelpMessage } from '../../../graphql/typescript';
import Counter from '../../core/components/Counter';
import { sortHints } from '../helpers';
import SimplifiedButton from '../../rocTemplates/simplifiedControls/SimplifiedButton';
import { highlight } from '../../ui/Core/stylesheets/colors';

type HoverNotesWrapper = { onClick: () => void; list: HelpMessage[] };

function HoverNotesWrapper({ onClick, list = [], ...rest }: HoverNotesWrapper) {
	const titleMem = React.useMemo(
		() =>
			list.length === 0
				? ''
				: sortHints(list).map(({ message, id }, idx) => (
					<div key={idx + String(id)}>- {message}</div>
				  )),
		[list],
	);

	return (
		<Tooltip title={titleMem}>
			<SimplifiedButton startIcon={<Info />} tabIndex={-1} onClick={onClick} {...rest}>
				<Counter count={list.length} color={highlight}>
					Hints
				</Counter>
			</SimplifiedButton>
		</Tooltip>
	);
}

export default React.memo(HoverNotesWrapper);
