import React from 'react';
import SimplifiedChoiceWrapper, { SimplifiedChoiceWrapperProps } from './SimplifiedChoiceWrapper';

function RadioInput(props: any) {
	return <input type="radio" {...props} />;
}

function checkedFunc(value: any, optValue: any) {
	return String(value) === String(optValue);
}

function SimplifiedRadioField({
	...rest
}: SimplifiedChoiceWrapperProps & {
	options: { label: string; value?: string | number; disabled?: boolean }[];
}) {
	return <SimplifiedChoiceWrapper {...rest} Input={RadioInput} checkedFunc={checkedFunc} />;
}

export default React.memo(SimplifiedRadioField);
