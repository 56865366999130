import React from 'react';
import { Chip } from '@mui/material';
import { css } from '@emotion/css/macro';
import Data from '../../UserData';
import DateInput from './DateInput';
import { getValueByInputType, getValueInToLocalTime } from '../../../core/helpers';

function DateRangeError({ value = {} }: any) {
	if (!value.start || !value.end || new Date(value.start) <= new Date(value.end)) return null;

	return (
		<Chip
			label="The Start Date must be earlier than the End"
			color="error"
			size="small"
			className={css`
				margin: 5px;
			`}
		/>
	);
}

const DateRangeErrorWrapper = React.memo(DateRangeError);

function DateRange({ value = {}, additional, onChange, type = 'date', ...rest }: any) {
	const changeValue = React.useCallback(
		(e: any) => {
			const oldData = {
				start: value.value?.start,
				end: value.value?.end,
			};

			onChange({
				value: {
					...oldData,
					[e.target.name]:
						type === 'date'
							? getValueInToLocalTime(getValueByInputType(e))
							: getValueByInputType(e),
				},
			});
		},
		[value.value?.start, value.value?.end, type, onChange],
	);
	return (
		<>
			<DateInput
				name="start"
				type={type}
				value={value}
				onChange={changeValue}
				additinal={additional}
				{...rest}
			/>
			<span> &ndash; </span>
			<DateInput
				name="end"
				type={type}
				value={value}
				onChange={changeValue}
				additinal={additional}
				{...rest}
			/>
			<DateRangeErrorWrapper value={value?.value} />
		</>
	);
}

export default function DateTime({ id, label, ...rest }: any) {
	return (
		<Data
			retrievedValue={(e: any) => e}
			elemId={id}
			inputElement={DateRange}
			additional={{ label }}
			{...rest}
		/>
	);
}
