import { css } from '@emotion/css/macro';

export const fieldBaseClass = css`
	margin: 8px 8px 8px 0 !important;
`;

export const formClass = css`
	display: flex;
	align-items: center;
	margin-bottom: 5px;
`;
