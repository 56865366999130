import { Tag } from '@mui/icons-material';
import React from 'react';
import { Box } from '@mui/material';
import { LinkButton } from './LinkButton';
import { ModelItemIdProps } from './types';
import { MediaFile } from '../../graphql/typescript';
import { isDevelopment } from '../core/constants/config';
import { clipboardCopy } from '../core/helpers';
import SimplifiedIconButton from './simplifiedControls/SimplifiedIconButton';

export type OptionalControlsSharedProps = {
	useDifferentValuesPerPaymentChannels?: boolean;
	attachments?: MediaFile[];
	refresh: () => void;
};

type OptionalControlsProps = OptionalControlsSharedProps &
	ModelItemIdProps & {
		showCopyLink?: boolean;
		show?: boolean;
		hasEvidence?: boolean;
	};

export default function OptionalControls({
	itemId,
	showCopyLink,
	show,
	hasEvidence,
}: OptionalControlsProps) {
	const copyItemId = React.useCallback(() => clipboardCopy(itemId), [itemId]);

	if (!show && !hasEvidence) return null;

	return (
		<Box display="flex">
			{showCopyLink && show && <LinkButton itemId={itemId} showLink />}
			{isDevelopment && show && (
				<SimplifiedIconButton tabIndex={-1} size="small" onClick={copyItemId}>
					<Tag />
				</SimplifiedIconButton>
			)}
		</Box>
	);
}
