import gql from 'graphql-tag';

export default gql`
	query getProjectInfo($projectId: String!) {
		view: get_api_Project_ViewInfo_projectId(projectId: $projectId)
			@rest(path: "Project/ViewInfo/{args.projectId}") {
			data {
				projectName
				type
				version
				roles
				startDate
				endDate
				clientName
				clientId
				workflowRole
				paymentChannels {
					id
					name
					description
					type
					saq {
						basedOn
						notApplicableReason
						customSaqId
					}
				}
				customSaq {
					id
					name
					summaryIds {
						status
						summaryId
					}
				}
				appliedScopeInfo {
					projectId
					name
					dateTime
				}
			}
		}
	}
`;
