import React from 'react';
import { Box, Button } from '@mui/material';
import { css } from '@emotion/css/macro';
import { HiddenFileInput } from '../files/DragDropComponents';
import { useFieldHandler } from '../core/helpers/stateActionHelpers';
import { PureTextField } from '../formaggio';

const fieldClass = css`
	margin: 0 8px !important;
`;

const ACCEPT = 'image/png, image/jpeg';

type DraftQuickImageProps = {
	isOpen?: boolean;
	sendImageOrUrl: (arg: string) => void;
};

export default function DraftQuickImage({ isOpen, sendImageOrUrl }: DraftQuickImageProps) {
	const [imageUrl, setImageUrl] = React.useState('');
	const handleUrl = useFieldHandler(setImageUrl);
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const openFileDialog = React.useCallback(() => {
		if (fileInputRef.current !== null) fileInputRef.current.click();
	}, [fileInputRef]);
	const sendLocalBlob = React.useCallback(
		(e) => {
			const files: File[] = Array.from(e.target.files);
			if (files.length < 1) return;

			const reader = new FileReader();
			reader.readAsDataURL(files[0]);
			// eslint-disable-next-line func-names
			reader.onload = function () {
				sendImageOrUrl(reader.result.toString());
			};
		},
		[sendImageOrUrl],
	);
	const sendUrl = React.useCallback(() => {
		sendImageOrUrl(imageUrl);
		setImageUrl('');
	}, [imageUrl, sendImageOrUrl]);

	if (!isOpen) return null;

	return (
		<Box display="flex" margin="8px 0" alignItems="center">
			<HiddenFileInput ref={fileInputRef} onChange={sendLocalBlob} accept={ACCEPT} />
			<Button variant="outlined" size="small" onClick={openFileDialog}>
				Choose local image
			</Button>
			<PureTextField
				variant="outlined"
				label="Or paste URL"
				onChange={handleUrl}
				className={fieldClass}
				value={imageUrl}
			/>
			<Button variant="outlined" size="small" onClick={sendUrl}>
				Ok
			</Button>
		</Box>
	);
}
