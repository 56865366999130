import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../modules/auth/AuthReducers';
import popupReducer from '../modules/popup/reducers';
import loadingReducer from '../modules/loading/reducers';

const reducers = {
	authReducer,
	popupReducer,
	loadingReducer,
};

function createRootReducer() {
	return combineReducers({
		...reducers,
	});
}

const store = configureStore({ reducer: createRootReducer() });

export default store;
