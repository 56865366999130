import React from 'react';
import SimplifiedChoiceWrapper, { SimplifiedChoiceWrapperProps } from './SimplifiedChoiceWrapper';
import { hasFlag } from '../../core/helpers';

function FlagInput(props: any) {
	return <input type="checkbox" {...props} />;
}

function checkedFunc(value = 0, optValue = 0) {
	return hasFlag(Number(value), Number(optValue));
}

function SimplifiedFlagsField({
	onChange,
	value = 0,
	options = [],
	name,
	useOwnFlags,
	...rest
}: SimplifiedChoiceWrapperProps & {
	options: { label: string; disabled?: boolean; value?: number }[];
	useOwnFlags?: boolean;
}) {
	const handleChange = React.useCallback(
		(e: any) =>
			onChange({
				...e,
				target: {
					...e.target,
					// Transforming the original event to +/- the values
					name,
					value: Number(value) + Number(e.target.value) * (e.target.checked ? 1 : -1),
				},
			}),
		[onChange, name, value],
	);

	const flagOptions = React.useMemo(
		() => (useOwnFlags ? options.map((o, i) => ({ ...o, value: 2 ** i })) : options),
		[options, useOwnFlags],
	);

	return (
		<SimplifiedChoiceWrapper
			{...rest}
			Input={FlagInput}
			checkedFunc={checkedFunc}
			onChange={handleChange}
			options={flagOptions}
			value={value}
		/>
	);
}

export default React.memo(SimplifiedFlagsField);
