import { RestLink } from 'apollo-link-rest';
import { parse, stringify } from 'query-string';
import { ApolloClient, ApolloLink } from '@apollo/client';
import globalFetchHandler from '../modules/core/actions/globalFetchHandler';
import { ReduxDispatch } from '../modules/core/actions/types';
import { FetchOptions } from '../modules/core/actions/fetchAPI';
import { AUTH_TOKEN_KEY } from '../modules/auth/constants';
import { setLoading } from '../modules/loading/reducers';
import { persistStoredCache } from '../modules/apollo/persistency';
import { inMemoryCache } from '../modules/apollo/cache';

function transformPath(path: RequestInfo) {
	const transformed = String(path);
	const splitPath = transformed.split('?');
	if (!splitPath[1]) return transformed;

	return `${splitPath[0]}?${stringify(
		parse(decodeURIComponent(splitPath[1]), { arrayFormat: 'index' }),
	)}`;
}

/**
Custom fetch as workaround for a problem where a whole 15-20mb chunk was reloaded
if server returned a 400 or 401 error to apollo-link-rest's mutation handler
*/
function fetchApollo(
	path: RequestInfo,
	params: RequestInit,
	dispatch: ReduxDispatch,
	fetchOptions: FetchOptions,
): Promise<Response> {
	dispatch(setLoading(true));
	return new Promise((resolve, reject) => {
		fetch(transformPath(path), params)
			.then((result) =>
				result
					.clone()
					.json()
					.then((json) => {
						const { errorHappened } = globalFetchHandler({
							dispatch,
							result: { status: result.status, ...json },
							fetchOptions: { ...fetchOptions, url: String(path) },
						});
						if (!errorHappened) {
							if (params.method === 'GET') setTimeout(() => persistStoredCache(), 100);
							resolve(result);
						}
						//Not sending errored response to Apollo
						else reject(new Error());
					}),
			)
			.catch(() => reject(new Error()));
	});
}

const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers. retrieve token on each request
	const auth = localStorage.getItem(AUTH_TOKEN_KEY);
	if (auth) {
		operation.setContext(({ headers = {} }: { headers: Record<string, unknown> }) => ({
			headers: {
				...headers,
				authorization: `Bearer ${auth}`,
			},
		}));
	}
	return forward(operation);
});

export function GraphQLClient(dispatch: ReduxDispatch) {
	let fetchOptions = {};

	const fetchOptionsMiddleWare = new ApolloLink((operation, forward) => {
		const context = operation.getContext();
		fetchOptions = context?.fetchOptions || {};
		return forward(operation);
	});

	const restLink = new RestLink({
		uri: 'api/',
		customFetch: (path, params) => fetchApollo(path, params, dispatch, fetchOptions),
	});

	const links = [authMiddleware, fetchOptionsMiddleWare, restLink];

	return new ApolloClient({
		cache: inMemoryCache,
		link: ApolloLink.from(links),
		assumeImmutableResults: true,
		defaultOptions: {
			query: { fetchPolicy: 'network-only' },
			watchQuery: { fetchPolicy: 'network-only' },
		},
	});
}
