import { css, cx } from '@emotion/css/macro';
import React from 'react';
import { mainDark } from '../../ui/Core/stylesheets/colors';

const simpleIconButtonClass = css`
	border: 0;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	padding: 4px;
	background: none;
	cursor: pointer;
	color: ${mainDark};

	&:hover {
		opacity: 0.75;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

type SimplifiedIconButtonProps = any;

const SimplifiedIconButton = React.forwardRef(
	({ children, className, ...rest }: SimplifiedIconButtonProps, ref) => (
		<button className={cx(simpleIconButtonClass, className)} type="button" {...rest} ref={ref}>
			<span>{children}</span>
		</button>
	),
);

export default React.memo(SimplifiedIconButton);
