import React from 'react';
import { css } from '@emotion/css/macro';
import { useFormikContext } from 'formik';
import { Add, Delete } from '@mui/icons-material';
import Button from '@material-ui/core/Button';
import { IconButton, SimpleLink } from '../../core/components';
import { NewEvidenceUrl } from '../../files/types';
import { editByIndex, removeOneByIndex, useAddOne } from '../../core/helpers/stateActionHelpers';
import { PureTextField } from '../../formaggio';
import { urlRegexp } from '../../formaggio/components/TextField';
import { truncateString } from '../../core/helpers';

export function validateWebUrl(url?: string) {
	const trimmedUrl = url?.trim();
	return trimmedUrl && trimmedUrl.match(urlRegexp);
}

type WebUrlProps = {
	url: string;
	label?: string;
};

export function WebUrl({ url, label }: WebUrlProps) {
	const result = React.useMemo(
		() => ({ parsed: url.indexOf('://') > 0 ? url : `//${url}`, truncated: truncateString(url) }),
		[url],
	);
	return <SimpleLink href={result.parsed}>{label || result.truncated}</SimpleLink>;
}

type WebUrlFieldProps = {
	deleteUrl: () => void;
	editUrl: (e: React.ChangeEvent<HTMLInputElement>) => void;
	values: NewEvidenceUrl;
};

function WebUrlField({ deleteUrl, editUrl, values = {} }: WebUrlFieldProps) {
	return (
		<div>
			<PureTextField
				fullWidth
				label="Url"
				name="webUrl"
				onChange={editUrl}
				value={values.webUrl}
				errorMessage={!validateWebUrl(values.webUrl)}
			/>
			<PureTextField
				fullWidth
				label="Description"
				name="description"
				onChange={editUrl}
				value={values.description}
				errorMessage={!String(values.description || '').trim()}
			/>
			<PureTextField
				fullWidth
				label="Evidence Location"
				name="evidenceLocation"
				onChange={editUrl}
				value={values.evidenceLocation}
			/>
			<IconButton onClick={deleteUrl} size="small">
				<Delete />
			</IconButton>
		</div>
	);
}

const addButtonClass = css`
	margin: 1rem 0 !important;
`;

export default function WebUrlList() {
	const { setFieldValue } = useFormikContext<any>();

	const [webUrls, setWebUrls] = React.useState<NewEvidenceUrl[]>([]);
	React.useEffect(() => {
		setFieldValue('webUrls', webUrls);
	}, [setFieldValue, webUrls]);

	const addUrl = useAddOne(setWebUrls, {});
	const editUrl = React.useCallback(
		(idx: number, e: React.ChangeEvent<HTMLInputElement>) => editByIndex(setWebUrls, idx, e),
		[setWebUrls],
	);
	const deleteUrl = React.useCallback(
		(idx: number) => removeOneByIndex(setWebUrls, idx),
		[setWebUrls],
	);

	const renderUrls = React.useMemo(
		() =>
			webUrls.map((values, idx: number) => {
				const handleDelete = () => deleteUrl(idx);
				const handleEdit = (e: React.ChangeEvent<HTMLInputElement>) => editUrl(idx, e);
				return (
					<WebUrlField deleteUrl={handleDelete} editUrl={handleEdit} values={values} key={idx} />
				);
			}),
		[webUrls, editUrl, deleteUrl],
	);

	return (
		<div>
			{renderUrls}
			<Button
				startIcon={<Add />}
				onClick={addUrl}
				size="small"
				color="primary"
				className={addButtonClass}
			>
				Add Web Url
			</Button>
		</div>
	);
}
