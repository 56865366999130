import { Archive, Article, Description, PictureAsPdf, Link } from '@mui/icons-material';
import { useAPI } from '../core/hooks';
import { RocDataType } from '../core/types/api';

export function useProjectAttachments(
	projectId: string,
	type: RocDataType,
	skip?: boolean,
): { list: any[]; refetch: () => void } {
	const { state, initialFetch } = useAPI({
		props: { query: `Attachments/List/${projectId}`, qs: { type } },
		autoLoad: !skip,
	});
	return { list: state?.autodata?.data, refetch: initialFetch };
}

export function extractAttachmentName(path = '') {
	return path.split(/[/\\]/).pop() || '';
}

export function FileExtensionIcon({ fileName, isUrl, ...rest }: any) {
	if (isUrl) return <Link {...rest} />;

	const splitName = fileName.split('.');
	const extension = splitName[splitName.length - 1];

	switch (extension) {
		case 'pdf':
			return <PictureAsPdf {...rest} />;
		case 'docx':
			return <Article {...rest} />;
		case 'zip':
			return <Archive {...rest} />;
		case 'rar':
			return <Archive {...rest} />;
		default:
			return <Description {...rest} />;
	}
}
