import AttachmentInput from './AttachmentInput';
import ProjectSystemEvidence from '../../../attachments/systemEvidence/ProjectSystemEvidence';
import SystemEvidenceData from '../../../attachments/systemEvidence/SystemEvidenceData';

type SystemEvidenceInputProps = any;

export default function SystemEvidenceInput({ ...props }: SystemEvidenceInputProps) {
	return (
		<AttachmentInput
			ProjectAttachmentsComponent={ProjectSystemEvidence}
			AttachmentDataComponent={SystemEvidenceData}
			attachmentName="system evidence"
			attachmentPluralName="system evidence"
			{...props}
		/>
	);
}
