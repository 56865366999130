import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Add } from '@mui/icons-material';
import { IconButton } from '../core/components';
import { useToggle } from '../core/hooks';
import { useReportContext } from '../rocTemplates/context/ReportContext';
import { useItemContext } from '../rocTemplates/context/ItemContext';
import { useProjectAttachments } from '../evidence/helpers';
import { RocDataType } from '../rocTemplates/enums';
import AddOrEditAttachment from './AddOrEditAttachment';
import AttachmentsList from './AttachmentsList';
import { AttachmentSharedProps } from './types';
import { useReportAccess } from '../auth/hooks';

type ProjectAttachmentsProps = Omit<
	AttachmentSharedProps,
	'modelId' | 'itemId' | 'refreshReport' | 'refreshProjectAttachments'
> & {
	pluralName: string;
	type: RocDataType;
	AddUpdateForm: React.ElementType;
	headerCells: React.ReactNode;
};

export default function ProjectAttachments({
	attachmentName = 'attachment',
	pluralName = 'attachments',
	isForReference,
	additional,
	highlight,
	type,
	AddUpdateForm,
	headerCells,
	AttachmentComponent,
	AdditionalRowControls,
	prefix,
	getRowSpan,
	onSelected,
	quickSnippets,
}: ProjectAttachmentsProps) {
	const { projectId } = useReportContext();
	const { isAQSA } = useReportAccess();
	const { isItemApproved } = useItemContext();

	const [editedAttachment, setEditedAttachment] = React.useState<any>();

	const { toggleOn, toggleOff, isOn } = useToggle();

	const { list, refetch } = useProjectAttachments(projectId, type);

	const onCreated = React.useCallback(() => {
		toggleOff();
		refetch();
	}, [refetch, toggleOff]);

	const onCreateStart = React.useCallback(() => {
		setEditedAttachment(undefined);
		toggleOn();
	}, [toggleOn, setEditedAttachment]);

	const onEditStart = React.useCallback(
		(arg: any) => {
			setEditedAttachment(arg);
			toggleOn();
		},
		[toggleOn, setEditedAttachment],
	);

	return (
		<>
			<AttachmentsList
				attachments={list}
				isForReference={isForReference}
				onEditStart={onEditStart}
				refreshProjectAttachments={refetch}
				isItemApproved={isItemApproved}
				additional={additional}
				highlight={highlight}
				headerCells={headerCells}
				pluralName={pluralName}
				AttachmentComponent={AttachmentComponent}
				AdditionalRowControls={AdditionalRowControls}
				type={type}
				attachmentName={attachmentName}
				getRowSpan={getRowSpan}
				onSelected={onSelected}
			/>
			{isAQSA && (
				<Tooltip title={'New ' + attachmentName}>
					<IconButton onClick={onCreateStart}>
						<Add color="primary" />
					</IconButton>
				</Tooltip>
			)}
			{isOn && (
				<AddOrEditAttachment
					attachmentName={attachmentName}
					prefix={prefix}
					initialValues={editedAttachment}
					onCreated={onCreated}
					onClose={toggleOff}
					isOpen={isOn}
					type={type}
					AddUpdateForm={AddUpdateForm}
					quickSnippets={quickSnippets}
				/>
			)}
		</>
	);
}
