import React from 'react';
import { ContentPaste } from '@mui/icons-material';
import { CompensatingControlTable } from '../CompensatingControl';
import { Heading, RouterLink } from '../../core/components';
import { mainDark } from '../../ui/Core/stylesheets/colors';
import { idsToProjectPath } from '../../project/helpers';
import SimplifiedHTML from '../../core/components/SimplifiedHTML';

export default function CompensatingControl({
	projectId,
	requirementDef,
	containerId,
	rowId,
	...rest
}: any) {
	const readColumn = React.useCallback(({ key }) => rest[key], [rest]);

	const itemPath = idsToProjectPath(projectId, containerId, rowId);

	return (
		<div style={{ marginTop: '1rem' }}>
			<div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
				<ContentPaste style={{ marginRight: '1rem' }} htmlColor={mainDark} />
				<div>
					<Heading variant="body2">Requirement definition:</Heading>
					<RouterLink to={itemPath}>
						<SimplifiedHTML textOrHtml={requirementDef} />
					</RouterLink>
				</div>
			</div>
			<CompensatingControlTable lastColumn={readColumn} />
		</div>
	);
}
