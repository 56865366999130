import React from 'react';
import { useAPI } from '../core/hooks';

const PAGE_SIZE = 20;

export function useSearch(
	config: Record<string, unknown> = {},
	filter: Record<string, unknown> = {},
) {
	const [fetchConfig] = React.useState<any>(config);
	const [initialFilter] = React.useState(filter);

	const [searchState, setSearchState] = React.useState({
		filter: initialFilter,
		page: 0,
		loading: true,
	});

	const { state, fetchAPI } = useAPI({});

	const search = React.useCallback(
		(values = {}) =>
			fetchAPI({
				...fetchConfig,
				qs: {
					...values,
					...fetchConfig.qs,
					pageSize: fetchConfig.pageSize || PAGE_SIZE,
					page: 0,
				},
				onSuccess: () =>
					setSearchState((prevState) => ({
						...prevState,
						filter: values,
						page: 0,
					})),
			}),
		[fetchConfig, fetchAPI, setSearchState],
	);

	const selectPage = React.useCallback(
		(page) =>
			fetchAPI({
				...fetchConfig,
				qs: {
					...searchState.filter,
					...fetchConfig.qs,
					page,
					pageSize: fetchConfig.pageSize || PAGE_SIZE,
				},
				onSuccess: () => setSearchState((prevState) => ({ ...prevState, page })),
			}),
		[setSearchState, fetchAPI, fetchConfig, searchState],
	);

	const reset = React.useCallback(
		() =>
			fetchAPI({
				...fetchConfig,
				qs: {
					...initialFilter,
					...fetchConfig.qs,
					pageSize: fetchConfig.pageSize || PAGE_SIZE,
					page: 0,
				},
				onSuccess: () =>
					setSearchState((prevState) => ({ ...prevState, filter: initialFilter, page: 0 })),
			}),
		[setSearchState, fetchAPI, fetchConfig, initialFilter],
	);

	const refresh = React.useCallback(
		() =>
			fetchAPI({
				...fetchConfig,
				qs: {
					...searchState.filter,
					...fetchConfig.qs,
					page: searchState.page,
					pageSize: fetchConfig.pageSize || PAGE_SIZE,
				},
			}),
		[fetchAPI, fetchConfig, searchState],
	);

	return { state, searchState, search, selectPage, reset, refresh };
}
