import React from 'react';
import { css } from '@emotion/css/macro';
import Data from '../UserData';
import { PureCheckboxField, PureTextField } from '../../formaggio';
import { getValueByInputType } from '../../core/helpers';

const attendeeClass = css`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

function InterviewAttendeeInput({ onFocus, onChange, value = {}, disabled }: any) {
	const changeValue = React.useCallback(
		(e: any) => {
			const oldData = {
				name: value.name,
				jobTitle: value.jobTitle,
				email: value.email,
				phone: value.phone,
				organization: value.organization,
				isISA: value.isISA,
			};
			onChange({
				...oldData,
				[e.target.name]: getValueByInputType(e),
			});
		},
		[
			value.name,
			value.jobTitle,
			value.email,
			value.phone,
			value.organization,
			value.isISA,
			onChange,
		],
	);

	return (
		<div onMouseEnter={onFocus} className={attendeeClass}>
			<PureTextField
				fullWidth
				name="name"
				label="Name"
				size="small"
				value={value.name || ''}
				onChange={changeValue}
				disabled={disabled}
			/>
			<PureTextField
				fullWidth
				name="jobTitle"
				label="Job Title"
				size="small"
				value={value.jobTitle || ''}
				onChange={changeValue}
				disabled={disabled}
			/>
			<PureTextField
				fullWidth
				name="email"
				label="E-mail"
				size="small"
				value={value.email || ''}
				onChange={changeValue}
				disabled={disabled}
			/>
			<PureTextField
				fullWidth
				name="phone"
				label="Phone"
				size="small"
				value={value.phone || ''}
				onChange={changeValue}
				disabled={disabled}
			/>
			<PureTextField
				fullWidth
				name="organization"
				label="Organization"
				size="small"
				value={value.organization || ''}
				onChange={changeValue}
				disabled={disabled}
			/>
			<PureCheckboxField
				name="isISA"
				label="Is ISA"
				size="small"
				checked={value.isISA || false}
				onChange={changeValue}
				disabled={disabled}
			/>
		</div>
	);
}

export default function InterviewAttendee({ itemId, label, ...rest }: any) {
	return (
		<Data
			emptyValue=""
			retrievedValue={(e: any) => e}
			elemId={itemId}
			inputElement={InterviewAttendeeInput}
			additional={{ label }}
			getData={({ ...values }: any) => ({
				...values,
			})}
			manualSave
			{...rest}
		/>
	);
}
