import React from 'react';
import DiffMatchPatch from 'diff-match-patch';
import { Heading } from '../core/components';
import TextHistoryItem from './TextHistoryItem';
import { useAPI } from '../core/hooks';
import { useReportContext } from '../rocTemplates/context/ReportContext';
import { useItemContext } from '../rocTemplates/context/ItemContext';

type TextHistoryProps = { channelId?: string };

export default function TextHistory({ channelId }: TextHistoryProps) {
	const { projectId } = useReportContext();
	const { itemId } = useItemContext();

	const { state } = useAPI({
		props: {
			query: `RocItemControllerNew/textHistory/${projectId}`,
			qs: { rocItemId: itemId, paymentChannelId: channelId },
		},
		autoLoad: true,
	});

	const { data: textHistory = null } = state?.autodata || {};

	const renderList = React.useMemo(() => {
		if (textHistory === null || textHistory.textChanges.length === 0)
			return <Heading>No changes</Heading>;
		const dmp = new DiffMatchPatch();
		let { currentText = '' } = textHistory;

		return textHistory.textChanges.map(({ authorName, changeDateTime, textDelta }: any) => {
			try {
				const delta = dmp.diff_fromDelta(currentText, textDelta);
				const prevText = dmp.diff_text2(delta);
				const diff = dmp.diff_main(prevText, currentText);
				const diffPretty = dmp.diff_prettyHtml(diff);
				currentText = prevText;

				return (
					<TextHistoryItem
						key={changeDateTime}
						author={authorName}
						changeDateTime={changeDateTime}
						text={diffPretty}
					/>
				);
			} catch (e) {
				return (
					<TextHistoryItem
						key={changeDateTime}
						author={authorName}
						changeDateTime={changeDateTime}
						text="Text Diff is not available"
					/>
				);
			}
		});
	}, [textHistory]);

	return <div style={{ padding: 8 }}>{renderList}</div>;
}
