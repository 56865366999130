import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Data from '../UserData';

function reportValueToRadio(value?: boolean) {
	switch (value) {
		case true:
			return '1';
		case false:
			return '0';
		default:
			return '';
	}
}

function radioValueToReport(value: string) {
	switch (value) {
		case '0':
			return false;
		case '1':
			return true;
		default:
			return undefined;
	}
}

const processLabel = (label = '') => ({ dangerouslySetInnerHTML: { __html: label } });

function RadioBool({ value, onChange, onFocus, disabled }: any) {
	return (
		<RadioGroup
			style={{ display: 'flex', flexDirection: 'row' }}
			value={reportValueToRadio(value?.value)}
			onFocus={onFocus}
			onMouseEnter={onFocus}
		>
			<FormControlLabel
				onChange={onChange}
				value="1"
				control={<Radio />}
				label="Yes"
				disabled={disabled}
			/>
			<FormControlLabel
				onChange={onChange}
				value="0"
				control={<Radio />}
				label="No"
				disabled={disabled}
			/>
		</RadioGroup>
	);
}

function CheckboxBool({ additional, onChange, value, onFocus, disabled }: any) {
	const processedProps = React.useMemo(
		() => (additional?.label ? processLabel(additional?.label) : {}),
		[additional?.label],
	);

	return (
		<FormControlLabel
			onFocus={onFocus}
			onMouseEnter={onFocus}
			style={{ marginLeft: '0.5rem' }}
			control={<Checkbox checked={value?.value} onChange={onChange} color="primary" />}
			label={<span {...processedProps} />}
			disabled={disabled}
		/>
	);
}

export default function Bool({ itemId, renderType, label, ...rest }: any) {
	return (
		<Data
			retrievedValue={(e: any) => ({
				value: renderType === 1 ? e.target.checked : radioValueToReport(e.target.value),
			})}
			elemId={itemId}
			inputElement={renderType === 1 ? CheckboxBool : RadioBool}
			instantSave
			additional={{ label }}
			{...rest}
		/>
	);
}
