import React from 'react';
import { BasicForm, TextField } from '../formaggio';
import { Button } from '../core/components';
import MaterialFlyOut from '../flyout/components/MaterialFlyOut';
import { AttachmentSharedProps } from './types';
import { useReportContext } from '../rocTemplates/context/ReportContext';

type AddOrEditAttachmentProps = Pick<
	AttachmentSharedProps,
	'attachmentName' | 'type' | 'prefix' | 'quickSnippets'
> & {
	initialValues?: any;
	onCreated: () => void;
	onClose: () => void;
	isOpen?: boolean;
	AddUpdateForm: React.ElementType;
};

export default function AddOrEditAttachment({
	attachmentName = 'attachment',
	initialValues,
	onCreated,
	onClose,
	isOpen,
	type,
	AddUpdateForm,
	prefix,
	quickSnippets,
}: AddOrEditAttachmentProps) {
	const { projectId } = useReportContext();

	return (
		<MaterialFlyOut
			onClose={onClose}
			title={`${initialValues ? 'Edit' : 'New'} project ${attachmentName}`}
			open={isOpen}
			style={{ maxWidth: '800px' }}
		>
			<BasicForm
				fetchConfig={{
					query: `Attachments/${initialValues ? 'Update' : 'Create'}`,
					method: initialValues ? 'PUT' : 'POST',
					onSuccess: onCreated,
					successMessage: true,
				}}
				initialValues={initialValues || { referenceNumber: prefix }}
				additional={{ type, projectId }}
			>
				<TextField
					fullWidth
					multiline
					label={initialValues ? 'Ref #' : 'Ref # prefix'}
					helperText={
						initialValues ? undefined : 'Number will be auto-assigned according to prefix'
					}
					name="referenceNumber"
					disabled={!!initialValues}
					quickSnippets={quickSnippets}
				/>
				<AddUpdateForm {...initialValues} />
				<Button color="primary" type="submit">
					{initialValues ? 'Save' : 'Add'}
				</Button>
			</BasicForm>
		</MaterialFlyOut>
	);
}
