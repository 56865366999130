import { LinearProgress, LinearProgressProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { css } from '@emotion/css/macro';

const rootClass = css`
	display: flex;
	align-items: center;
	padding: 4px 4px 4px 0;
	position: sticky;
	bottom: 0;
	background: white;
`;

const progressClass = css`
	margin-right: 8px;
	flex: 1 1;
`;

type ProgressWithLabelProps = LinearProgressProps;

export default function ProgressWithLabel({ value = 0 }: ProgressWithLabelProps) {
	return (
		<div className={rootClass}>
			<LinearProgress variant="determinate" value={value} className={progressClass} />
			<Typography variant="body2" color="textSecondary">
				{value}%
			</Typography>
		</div>
	);
}
