import React from 'react';
import { css, cx } from '@emotion/css/macro';

const BASE_SPACING = 16;

const baseClass = css`
	width: 100%;
	display: flex;
`;

const mainClass = css`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`;

const expandClass = css`
	flex-grow: 1;
`;

const toolbarClass = css`
	display: flex;
	flex-wrap: wrap;
`;

const asideClass = css`
	margin-left: ${BASE_SPACING}px;
	height: 100%;
	top: 0;
	position: sticky;
`;

const footerClass = css`
	margin-top: ${BASE_SPACING}px;
`;

export type ItemControlLayoutProps = {
	toolbar?: React.ReactNode;
	aside?: React.ReactNode;
	children?: React.ReactNode;
	footer?: React.ReactNode;
	className?: string;
};

export default function ItemControlLayout({
	toolbar,
	aside,
	children,
	footer,
	className,
}: ItemControlLayoutProps) {
	return (
		<section className={cx(baseClass, className)}>
			<div className={mainClass}>
				<section className={toolbarClass}>{toolbar}</section>
				<section className={expandClass}>{children}</section>
				<section className={footerClass}>{footer}</section>
			</div>
			<section className={asideClass}>{aside}</section>
		</section>
	);
}
