import { useAPI } from '../hooks';
import { FetchAPIProps } from './fetchAPI';

export function useQuickFetch<ResT, ReqT = any>(
	config: Partial<FetchAPIProps<any, ReqT>>,
	skip?: boolean,
): {
	data: ResT;
	loading?: boolean;
	refetch: (config?: Partial<FetchAPIProps<any, ReqT>>) => void;
	fetchAPI: (config?: Partial<FetchAPIProps<any, ReqT>>) => void;
} {
	const { state, initialFetch, fetchAPI } = useAPI({ props: config, autoLoad: !skip });

	return { data: state?.autodata, loading: state?.loading, refetch: initialFetch, fetchAPI };
}
