import React from 'react';
import { css } from '@emotion/css';
import { grass, error, lightgrey } from '../../ui/Core/stylesheets/colors';

function passwordCheckResult(password = '') {
	return {
		isMinLength: password.length >= 6,
		hasLetters: !!password.match('[a-z]') && !!password.match('[A-Z]'),
		hasNumbers: !!password.match('\\d+'),
		hasSpecialChars: !!password.match('[!@#$%^&*]'),
	};
}

export function isPasswordSafe(password = '') {
	const { isMinLength, hasLetters, hasNumbers, hasSpecialChars } = passwordCheckResult(password);
	return isMinLength && hasLetters && hasNumbers && hasSpecialChars;
}

type PasswordStrengthTypeProps = { isValid?: boolean; children: React.ReactNode };

function PasswordStrengthType({ isValid, children }: PasswordStrengthTypeProps) {
	return (
		<p
			className={css`
				color: ${isValid ? grass : error};
			`}
		>
			{children}
		</p>
	);
}

const rootClass = css`
	padding: 0.5rem;
	border-radius: 5px;
	background: ${lightgrey};
	margin-top: 0.5rem;
	text-align: left;
`;

type PasswordStrengthProps = { password: string };

export default function PasswordStrength({ password }: PasswordStrengthProps) {
	const status = React.useMemo(() => passwordCheckResult(password), [password]);

	return (
		<div className={rootClass}>
			<PasswordStrengthType isValid={status.isMinLength}>
				At least 6 characters long
			</PasswordStrengthType>
			<PasswordStrengthType isValid={status.hasLetters}>
				Contains uppercase and lowercase letters
			</PasswordStrengthType>
			<PasswordStrengthType isValid={status.hasNumbers}>Contains numbers</PasswordStrengthType>
			<PasswordStrengthType isValid={status.hasSpecialChars}>
				Contains special characters (!, @, #, $, %, ^, &, *)
			</PasswordStrengthType>
		</div>
	);
}
