import React from 'react';
import { css } from '@emotion/css/macro';
import HintControls from './HintControls';
import HintItem from './HintItem';
import { HelpMessage } from '../../../graphql/typescript';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import { sortHints } from '../helpers';

const rootClass = css`
	padding: 0.75rem 0.75rem 0 0.75rem;
`;

export type HintsSharedProps = {
	list: HelpMessage[];
	isAQSA: boolean;
	isGlobalAdmin: boolean;
	itemId: string;
};

export default function Hints({ list = [], isAQSA, isGlobalAdmin, itemId }: HintsSharedProps) {
	const { refresh, projectId } = useReportContext();

	const renderHints = React.useMemo(
		() =>
			sortHints(list).map(({ id, ...rest }: any, idx) => (
				<HintItem
					{...rest}
					refresh={refresh}
					id={id}
					key={idx + String(id)}
					isAQSA={isAQSA}
					isGlobalAdmin={isGlobalAdmin}
					itemId={itemId}
				/>
			)),
		[list, refresh, isAQSA, isGlobalAdmin, itemId],
	);

	if (!projectId) return null;

	return (
		<>
			<div className={rootClass}>{renderHints}</div>
			{isAQSA && <HintControls refresh={refresh} modelId={projectId} itemId={itemId} />}
		</>
	);
}
