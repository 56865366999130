import { TextField } from '../../formaggio';

export default function ObservationEvidenceAddUpdateForm() {
	return (
		<>
			<TextField fullWidth multiline label="Observed process" name="process" />
			<TextField
				fullWidth
				multiline
				label="Title of Workpaper with Observation Notes"
				name="workpaperTitle"
			/>
			<TextField fullWidth multiline label="Brief description of the process" name="description" />
		</>
	);
}
