import React from 'react';
import { FormType } from '../../formaggio/constants';
import { BasicForm, FileForm } from '../../formaggio';

export type MaterialFormProps = { formType?: FormType } & any;

export default function MaterialForm({
	children,
	formType = FormType.None,
	url,
	...rest
}: MaterialFormProps) {
	const Form = React.useMemo(() => {
		switch (formType) {
			case FormType.Basic:
				return BasicForm;
			case FormType.File:
				return FileForm;
			default:
				return React.Fragment;
		}
	}, [formType]);
	return <Form {...(formType === FormType.None ? {} : { url, ...rest })}>{children}</Form>;
}
