import gql from 'graphql-tag';

export default gql`
	query getProjectsOfTemplate($templateVersion: Int) {
		list: get_api_Project_ViewTemplateProjects(templateVersion: $templateVersion)
			@rest(path: "Project/ViewTemplateProjects?{args}") {
			data {
				id
				name
				createdDate
			}
		}
	}
`;
