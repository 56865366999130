import React from 'react';
import { css, cx } from '@emotion/css/macro';
import TextField from '@material-ui/core/TextField';
import { fieldBaseClass } from '../../../formaggio/stylesheets';
import HelperText from '../../../formaggio/components/HelperText';
import { error as errorColor } from '../../../ui/Core/stylesheets/colors';

const errorClass = css`
	color: ${errorColor};
`;

type JSONFieldProps = {
	onChange: (e: any) => void;
	label: string;
	name: string;
	helperText?: string | React.ReactNode;
	storage: any;
	className?: string;
};

export default function JSONField({
	onChange,
	label,
	name,
	storage,
	helperText,
	className,
}: JSONFieldProps) {
	const json = JSON.stringify(storage[name]) || '';
	const [error, setError] = React.useState(false);
	const [tempJson, setTempJson] = React.useState(json);
	const handleChange = React.useCallback(
		(e) => {
			let value = '';
			setTempJson(e.target.value);
			try {
				value = JSON.parse(e.target.value);
				setError(false);
				onChange({ value, name });
			} catch (err) {
				setError(!!err);
			}
		},
		[onChange, setTempJson, name],
	);
	return (
		<div className={cx(fieldBaseClass, className)}>
			<TextField
				label={`${label} (JSON)`}
				variant="outlined"
				style={{ marginTop: '0.5rem' }}
				onChange={handleChange}
				value={error ? tempJson : json}
				multiline
				fullWidth
			/>
			{error && (
				<small className={errorClass}>
					<b>{error}</b>
				</small>
			)}
			{helperText && <HelperText>{helperText}</HelperText>}
		</div>
	);
}
