import { css, cx } from '@emotion/css/macro';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { kveld, skyblue, grass, darkgrass } from '../../ui/Core/stylesheets/colors';
import { roleTitles } from '../../auth/constants';
import { getProjectSubRoleNames } from '../../auth/helpers';

type UserTypeNamingsProp = Record<number, string>;

export const roleColors: UserTypeNamingsProp = {
	1: 'none',
	2: kveld,
	4: skyblue,
	8: 'brown',
	16: grass,
	32: darkgrass,
	64: 'lightslategrey',
	128: 'darkcyan',
	256: 'cadetblue',
	512: 'darkslateblue',
	1024: 'darkorange',
};

const userTypeClass = css`
	font-weight: 200;
	color: white !important;
`;

const userProjectSubRoleClass = css`
	margin-right: 8px;
`;

type UserTypeBadgeProps = {
	userRole?: number;
	projectSubRole?: number;
	className?: string;
} & ChipProps;

export function UserTypeBadge({
	userRole,
	projectSubRole,
	className,
	...rest
}: UserTypeBadgeProps) {
	if (!userRole) return null;

	const typeColorClass = css`
		background: ${roleColors[userRole]} !important;
	`;

	return (
		<Chip
			size="small"
			color="primary"
			className={cx(
				typeColorClass,
				userTypeClass,
				projectSubRole ? userProjectSubRoleClass : null,
				className,
			)}
			label={
				<>
					{projectSubRole ? getProjectSubRoleNames(projectSubRole) + ' ' : null}
					{roleTitles[userRole]}
				</>
			}
			{...rest}
		/>
	);
}
