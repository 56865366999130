import gql from 'graphql-tag';

export default gql`
	query getCompaniesOfUser($userId: String) {
		list: get_api_Company_ViewCompaniesOfUser(userId: $userId)
			@rest(path: "Company/ViewCompaniesOfUser?{args}") {
			data {
				id
				companyName
			}
		}
	}
`;
