import { ContactModel } from '../../../graphql/typescript';
import { getResponsibilityByFlag } from '../helpers';

export type Contact = Omit<ContactModel, 'id'> & { id: string };

//Can also turn into enum when needed
export const contactSpecializations = [
	{
		title: 'Antivirus Software Configuration and Management',
	},
	{ title: 'ASV Scans' },
	{
		title: 'Database Administrators & Owners',
		description: 'Provisioning, managing and maintenance of company databases',
	},
	{ title: 'Encryption of Data in Transit', description: '(including Wireless)' },
	{
		title: 'Encryption Standards and Implementation',
		description: 'for storage of cardholder data (not required if they do not store any CHD)',
	},
	{ title: 'Firewall and Routers Configuration and Management' },
	{ title: 'Human Resources and Training Coordination' },
	{ title: 'Incident Response and Log Monitoring', description: '(policy, IR plan)' },
	{
		title: 'Information Security Management',
		description:
			'(risk assessments, security policies, service provider management, security awareness program, background checks, legal)',
	},
	{ title: 'Internal and External Vulnerability Scanning' },
	{ title: 'Network Architecture Operations and Management' },
	{
		title: 'Patch Installation & Management',
		description: 'Testing, configuration, installation and maintenance',
	},
	{ title: 'Penetration Testing' },
	{
		title: 'Physical Access Control',
		description: '(video cameras, locks, keys, visitors, security of media)',
	},
	{ title: 'Servers and Workstations Configuration and Management' },
	{
		title: 'Software Development Processes',
		description: '(coders, managers, approvers, change control)',
	},
	{
		title: 'User Account Management',
		description:
			'(manages new users, maintaining user accounts, ensures no shared account use, terminating users, multi-factor auth)',
	},
	{
		title: 'Users Privilege Management',
		description: '(DBs, filesystems, logins etc - defines access restrictions and policies)',
	},
	{ title: 'Wireless Configuration and Management' },
	{ title: 'System and Cloud Architecture' },
];

export function responsibilitiesArrayToSelect(areas: number[]) {
	return areas.map((r) => ({ value: r, title: getResponsibilityByFlag(r).title }));
}
