//Apollo localStorage persistency cache

import { NormalizedCacheObject } from '@apollo/client';
import { inMemoryCache } from './cache';
import { isAuthenticated } from '../auth/helpers';

export const PERSISTENCY_KEY = 'persistencyCache';

//Helpers
export function writePersistency(cache: Record<string, any>) {
	localStorage.setItem(PERSISTENCY_KEY, JSON.stringify(cache));
}

export function readPersistency(): NormalizedCacheObject | undefined {
	const cache = localStorage.getItem(PERSISTENCY_KEY);
	return cache ? JSON.parse(cache) : undefined;
}

export function removePersistency() {
	localStorage.removeItem(PERSISTENCY_KEY);
	inMemoryCache.reset();
}

export function persistMemoryCache() {
	if (!isAuthenticated()) {
		removePersistency();
		return;
	}

	const storedCache = readPersistency();
	if (storedCache) {
		const memoryCacheRoot = inMemoryCache.extract().ROOT_QUERY || {};
		const storedCacheRoot = storedCache.ROOT_QUERY || {};
		let isCacheChanged = false;

		Object.keys(storedCacheRoot).forEach((key) => {
			if (JSON.stringify(memoryCacheRoot[key]) !== JSON.stringify(storedCacheRoot[key])) {
				inMemoryCache.modify({ fields: { [key]: () => storedCacheRoot[key] } });
				isCacheChanged = true;
			}
		});

		Object.keys(memoryCacheRoot).forEach((key) => {
			if (!storedCacheRoot.hasOwnProperty(key)) {
				inMemoryCache.modify({ fields: { [key]: (_, { DELETE }) => DELETE } });
				isCacheChanged = true;
			}
		});

		if (isCacheChanged) inMemoryCache.restore(storedCache);
	}
}

export function persistStoredCache() {
	if (!isAuthenticated()) {
		removePersistency();
		return;
	}

	const storedCache = readPersistency();
	const memoryCache = inMemoryCache.extract();
	let isCacheChanged = false;

	if (storedCache) {
		const memoryCacheRoot = memoryCache.ROOT_QUERY || {};
		const storedCacheRoot = storedCache.ROOT_QUERY || {};

		Object.keys(memoryCacheRoot).forEach((key) => {
			const memoryCacheEntry = memoryCacheRoot[key];
			if (!storedCacheRoot.hasOwnProperty(key)) {
				storedCacheRoot[key] = memoryCacheEntry;
				isCacheChanged = true;
			} else if (JSON.stringify(storedCacheRoot[key]) !== JSON.stringify(memoryCacheEntry)) {
				storedCacheRoot[key] = memoryCacheEntry;
				isCacheChanged = true;
			}
		});

		if (isCacheChanged) {
			storedCache.ROOT_QUERY = storedCacheRoot;
			writePersistency(storedCache);
		}

		isCacheChanged = false;
	} else writePersistency(memoryCache);
}
