import React from 'react';
import { Box } from '@mui/material';
import { removeHtml } from '../helpers';
import { highlight as highlightColor } from '../../ui/Core/stylesheets/colors';
import { getContrastText } from '../../ui/Core/helpers';

function getHighlightedText(text = '', highlight = '') {
	return text
		.split(new RegExp(`(${highlight})`, 'gi'))
		.map((part, index) => (
			<React.Fragment key={index}>
				{part.toLowerCase() === highlight.toLowerCase() ? (
					<span style={{ backgroundColor: highlightColor, color: getContrastText(highlightColor) }}>
						{part}
					</span>
				) : (
					part
				)}
			</React.Fragment>
		));
}

type SimplifiedHTMLProps = { textOrHtml?: string; useHighlight?: boolean; highlight?: string };

function SimplifiedHTML({ textOrHtml = '', useHighlight, highlight }: SimplifiedHTMLProps) {
	if (useHighlight && !highlight) return null;

	return (
		<Box margin="0 8px" maxWidth="600px">
			{textOrHtml.split(/(<li>|<p>)/).map((part, idx) => {
				const cleanHtml = removeHtml(part);

				return (
					<p key={idx}>
						{part.indexOf('</li>') >= 0 && '- '}
						{useHighlight ? getHighlightedText(cleanHtml, highlight) : cleanHtml}
					</p>
				);
			})}
		</Box>
	);
}

export default React.memo(SimplifiedHTML);
