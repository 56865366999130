import { CalendarMonth } from '@mui/icons-material';
import { css } from '@emotion/css/macro';
import { Button } from '../../modules/core/components';
import MaterialFlyOut from '../../modules/flyout/components/MaterialFlyOut';
import { FileField, FileForm } from '../../modules/formaggio';
import { MAX_ICS_IMPORT_SIZE_MB } from '../../modules/evidence/constants';
import { useToggle } from '../../modules/core/hooks';
import { useReportContext } from '../../modules/rocTemplates/context/ReportContext';
import SimplifiedButton from '../../modules/rocTemplates/simplifiedControls/SimplifiedButton';

const submitBtnClass = css`
	margin-top: 5px !important;
`;

const importLabelClass = css`
	text-transform: initial !important;
`;

type InterviewImportProps = {
	projectId: string;
	itemId?: string;
	refreshProjectInterviews?: () => void;
};

export default function InterviewImport({
	projectId,
	itemId,
	refreshProjectInterviews,
	...rest
}: InterviewImportProps) {
	const { isOn, toggleOff, toggleOn } = useToggle();
	const { refresh } = useReportContext();

	return (
		<>
			<SimplifiedButton startIcon={<CalendarMonth />} onClick={toggleOn} {...rest}>
				<span className={importLabelClass}>Import ICalendar (.ics)</span>
			</SimplifiedButton>
			<MaterialFlyOut open={isOn} onClose={toggleOff} title="Import ICalendar (.ics)">
				<FileForm
					onUploadCompleted={() => {
						toggleOff();
						if (refresh) refresh();
						if (refreshProjectInterviews) refreshProjectInterviews();
					}}
					url="Interview/Import"
					initialValues={{
						ProjectId: projectId,
						RocItemId: itemId,
					}}
					maxUploadSizeMb={MAX_ICS_IMPORT_SIZE_MB}
				>
					<FileField
						noDragInstruction
						name="files"
						accept=".ics"
						instruction={`ICalendar (.ics) files only, ${MAX_ICS_IMPORT_SIZE_MB} MB max`}
					/>
					<Button type="submit" color="primary" className={submitBtnClass}>
						Submit
					</Button>
				</FileForm>
			</MaterialFlyOut>
		</>
	);
}
