import React from 'react';
import { SelectField, toSelectOptions } from '../../formaggio';
import { getAvailableRoles, getMyMaxRole, givenRolesToSelectOptions } from '../../auth/helpers';
import { useMyClients } from '../../client/hooks';
import { RolesEnum } from '../../auth/enums';
import CompanySelect from '../../company/components/CompanySelect';
import { useAuthContext } from '../../auth/context';
import SubRoleSelector from '../../project/invitation/SubRoleSelector';

type CompanyClientRoleSelectorProps = {
	selectableRoles: number[];
	isProjectInvite?: boolean;
};

export default function CompanyClientRoleSelector({
	selectableRoles,
	isProjectInvite,
}: CompanyClientRoleSelectorProps) {
	const { roles } = useAuthContext();

	//Company
	const [companyId, setCompanyId] = React.useState<string | undefined>(undefined);
	const onSelectCompany = React.useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => setCompanyId(e.target.value),
		[setCompanyId],
	);

	//Clients
	const { list } = useMyClients();
	const clientOptions = React.useMemo(
		() =>
			toSelectOptions(
				companyId ? list?.filter((c) => c.companyId === companyId) : list,
				'id',
				'name',
			),
		[companyId, list],
	);

	//Role
	const [role, setRole] = React.useState<RolesEnum | null>(null);
	const onSelectRole = React.useCallback((e: any) => setRole(Number(e.target.value)), [setRole]);
	const myRole = getMyMaxRole(roles);

	return (
		<>
			{!!myRole && (
				<SelectField
					label="Role"
					name="role"
					id="roleSelect"
					options={givenRolesToSelectOptions(getAvailableRoles(selectableRoles, myRole))}
					onChange={onSelectRole}
				/>
			)}
			{isProjectInvite ? (
				<SubRoleSelector />
			) : (
				<div>
					<CompanySelect id="companySelector" onSelect={onSelectCompany} />
				</div>
			)}
			{(role === RolesEnum.Client || role === RolesEnum.ExecutiveClientRepresentative) &&
				!isProjectInvite && (
					<SelectField
						fullWidth
						label="Client"
						name="clientId"
						options={clientOptions}
						searchable
					/>
				)}
		</>
	);
}
