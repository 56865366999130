import { HubConnectionBuilder } from '@microsoft/signalr';
import { AccessToken } from './types';
import { AUTH_TOKEN_KEY } from '../auth/constants';

//IUniversalHub.cs - what client receives
export enum HubTriggers {
	ReceiveClearHub = 'ReceiveClearHub',
	ReceiveBroadcastMessage = 'ReceiveBroadcastMessage',
	ReceiveReadMessageId = 'ReceiveReadMessageId',
	ReceiveUnreadMessageIds = 'ReceiveUnreadMessageIds',
	ReceiveRocItemStatusUpdateMessage = 'ReceiveRocItemStatusUpdateMessage',
	ReceiveRocWarningsUpdateMessage = 'ReceiveRocWarningsUpdateMessage',
	ReceiveProjectStatusUpdateMessage = 'ReceiveProjectStatusUpdateMessage',
	ReceiveUnreadUserMessage = 'ReceiveUnreadUserMessage',
	ReceiveLicenseStatus = 'ReceiveLicenseStatus',
	ReceiveArrayImportStatus = 'ReceiveArrayImportStatus',
	ReceiveSupportClientEvent = 'ReceiveSupportClientEvent',
	ReceiveApprovalRequestsCount = 'ReceiveApprovalRequestsCount',
}

//UniversalHub.cs - what client can send
export enum HubInvokers {
	Broadcast = 'Broadcast',
	SendReadMessageId = 'SendReadMessageId',
}

export const hub = new HubConnectionBuilder()
	.withUrl('/hub', {
		accessTokenFactory(): AccessToken {
			return localStorage.getItem(AUTH_TOKEN_KEY) || '';
		},
	})
	.withAutomaticReconnect({
		nextRetryDelayInMilliseconds: () => 1000 * 10,
	})
	.build();
