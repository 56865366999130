import React from 'react';
import { css, cx } from '@emotion/css/macro';
import Tooltip from '@material-ui/core/Tooltip';
import { PushPin, Visibility } from '@mui/icons-material';
import PostControls from './PostControls';
import { IconButton } from '../../core/components';
import { darkgrey, middlegrey, error, mainDark, mainLight } from '../../ui/Core/stylesheets/colors';
import { useToggle, useAPI } from '../../core/hooks';
import Reply from './HintControls';
import { HintsSharedProps } from './Hints';
import { useReportAccess } from '../../auth/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';

const rootClass = css`
	padding: 0.75rem;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.75rem;
	border: 1px solid ${middlegrey};
	border-radius: 5px;
	background: white;
	align-items: flex-start;
`;

const globalHintClass = css`
	border: 1px solid ${mainLight};
`;

const hintClass = css`
	flex: 1 1 auto;
	padding-right: 0.75rem;
`;

const pinButtonIconClass = css`
	transform: rotate(45deg);
	font-size: 1rem;
`;

const customerVisibleClass = css`
	margin-top: 0.75rem;
	font-size: 1.2rem;
	width: 24px;
`;

export type HintItemProps = {
	id: string;
	message: string;
	pinned?: boolean;
	editAllowed?: boolean;
	refresh: () => void;
	isGlobal?: boolean;
	isVisibleToCustomer?: boolean;
} & HintsSharedProps;

export default function HintItem({
	message,
	pinned,
	id,
	refresh,
	isVisibleToCustomer,
	isAQSA,
	isGlobalAdmin,
	isGlobal,
	itemId,
}: HintItemProps) {
	const { fetchAPI } = useAPI({});
	const { isAdmin } = useReportAccess();
	const { projectId: modelId } = useReportContext();

	const { isOn, toggleOn, toggleOff } = useToggle(); //isEditMode

	const updatePinHint = React.useCallback(
		(isPinned: boolean) =>
			fetchAPI({
				query: `Hints/update/${modelId}/${itemId}/${id}`,
				method: 'PUT',
				params: {
					message,
					isVisibleToCustomer,
					pinned: isPinned,
				},
				onSuccess: () => {
					refresh();
				},
			}),
		[fetchAPI, modelId, itemId, id, refresh, message, isVisibleToCustomer],
	);
	const pinHint = React.useCallback(() => updatePinHint(true), [updatePinHint]);
	const unpinHint = React.useCallback(() => updatePinHint(false), [updatePinHint]);

	const isEditable = (isAQSA && !isGlobal) || (isGlobalAdmin && isGlobal);

	return (
		<div className={cx(rootClass, isGlobal && globalHintClass)}>
			<div className={hintClass}>
				{isOn ? (
					<Reply
						itemId={itemId}
						modelId={modelId}
						id={id}
						refresh={refresh}
						text={message}
						isEditMode
						onSaved={toggleOff}
						onCancel={toggleOff}
						initialFlag={isVisibleToCustomer}
					/>
				) : (
					<span>{message}</span>
				)}
				{isAQSA && isVisibleToCustomer && (
					<Tooltip title="Visible to customers">
						<div className={customerVisibleClass}>
							<Visibility htmlColor={darkgrey} />
						</div>
					</Tooltip>
				)}
			</div>
			{id && !isOn && isEditable && (
				<PostControls
					query={`Hints/delete/${modelId}/${itemId}/${id}`}
					refresh={refresh}
					onClickEdit={toggleOn}
				>
					{isAdmin && (
						<Tooltip title={pinned ? 'Unpin' : 'Pin'}>
							<IconButton onClick={pinned ? unpinHint : pinHint}>
								<PushPin htmlColor={pinned ? error : mainDark} className={pinButtonIconClass} />
							</IconButton>
						</Tooltip>
					)}
				</PostControls>
			)}
			{pinned && <PushPin htmlColor={darkgrey} />}
		</div>
	);
}
