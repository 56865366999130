import { css, cx } from '@emotion/css/macro';
import { progressStatuses } from '../project/progressCalculation';

const progressCellClass = css`
	color: white;
	white-space: nowrap;
	font-weight: bold;
	font-size: 12px;
	border-width: 1px;
	border-style: solid;
	border-radius: 4px;
	display: inline-block;
	padding: 2px 6px;
	margin-bottom: 4px;
`;

const testId = 'TableCell_CellStatus';

export default function ItemProgressStatus(status) {
	if (!status) return null;

	return (
		<div
			className={cx(
				progressCellClass,
				css`
					color: ${progressStatuses[status].color};
					border-color: ${progressStatuses[status].color};
				`,
			)}
			data-testid={testId}
		>
			{progressStatuses[status].title}
		</div>
	);
}
