import { Search } from '@mui/icons-material';
import { Button, RouterLink } from '../core/components';
import { idsToProjectPath } from '../project/helpers';

type ItemLinkProps = { chapter?: string; projectId: string; rocItemId: string; chapterId: string };

export default function ItemLink({
	chapter = '',
	projectId = '',
	chapterId,
	rocItemId,
}: ItemLinkProps) {
	if (!chapter) return null;

	return (
		<RouterLink to={idsToProjectPath(projectId, chapterId, rocItemId)}>
			<Button startIcon={<Search />} size="small" color="primary" variant="outlined">
				{chapter}
			</Button>
		</RouterLink>
	);
}
