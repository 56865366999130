import { Edit, FileUpload } from '@mui/icons-material';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import EvidenceCreate from './EvidenceCreate';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { useHistoryPush, useToggle } from '../../core/hooks';
import { useAllowedMethods } from '../../auth/hooks';
import SimplifiedButtonGroup from '../../rocTemplates/simplifiedControls/SimplifiedButtonGroup';
import SimplifiedButton from '../../rocTemplates/simplifiedControls/SimplifiedButton';

export default function ProjectEvidenceUploader() {
	const { projectId, refresh } = useReportContext();
	const { isOn, toggleOff, toggleOn } = useToggle();
	const goToEvidences = useHistoryPush(`/portal/evidence/${projectId}`);
	const { 'Evidences/UpdateAttachment': canEdit } = useAllowedMethods();

	return (
		<>
			<SimplifiedButtonGroup style={{ marginBottom: '5px' }}>
				<SimplifiedButton startIcon={<FileUpload />} onClick={toggleOn}>
					Attach file
				</SimplifiedButton>
				{canEdit && (
					<SimplifiedButton startIcon={<Edit />} onClick={goToEvidences}>
						Manage attachments
					</SimplifiedButton>
				)}
			</SimplifiedButtonGroup>
			<MaterialFlyOut open={isOn} onClose={toggleOff} title="Attachment">
				<EvidenceCreate
					onCompleted={() => {
						toggleOff();
						refresh();
					}}
					modelId={projectId}
				/>
			</MaterialFlyOut>
		</>
	);
}
