import React from 'react';
import { connect } from 'formik';
import {
	DatePicker,
	DateTimePicker,
	DateTimePickerProps,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateUtils from '@date-io/date-fns';
import { FormaggioInputProps } from '../types';
import { ErrorMessage } from '../../core/components';
import { TIME_FORMAT } from '../../core/constants/time';

type DateTimeFieldProps = DateTimePickerProps & FormaggioInputProps & any;

export function PureDateTimeField({
	name,
	className,
	errorMessage,
	onChange,
	dateOnly,
	...rest
}: DateTimeFieldProps) {
	const dateChange = React.useCallback(
		(date) => {
			onChange({ target: { name, value: date.toISOString() } });
		},
		[name, onChange],
	);

	const Component = React.useMemo(() => (dateOnly ? DatePicker : DateTimePicker), [dateOnly]);

	return (
		<div className={className}>
			<MuiPickersUtilsProvider utils={DateUtils}>
				<Component
					format={dateOnly ? TIME_FORMAT.DATE_ONLY : TIME_FORMAT.FULL}
					name={name}
					error={!!errorMessage}
					onChange={dateChange}
					{...rest}
					autoOk
					disableToolbar
					variant="inline"
				/>
			</MuiPickersUtilsProvider>
			<ErrorMessage message={errorMessage} />
		</div>
	);
}

function DateTimeFieldConnected({ formik, name, ...rest }: DateTimeFieldProps) {
	return (
		<PureDateTimeField
			onChange={formik.handleChange}
			value={formik.values ? formik.values[name] || '' : ''}
			onBlur={formik.handleBlur}
			errorMessage={formik.errors[name]}
			name={name}
			{...rest}
		/>
	);
}

export default connect(DateTimeFieldConnected);
