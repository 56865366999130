import gql from 'graphql-tag';

export default gql`
	query getMyProjects(
		$isActive: Boolean
		$createdByMe: Boolean
		$clientId: String
		$createdAfter: String
		$version: Number
	) {
		list: get_api_Project_ViewProjects(
			IsActive: $isActive
			CreatedByMe: $createdByMe
			ClientId: $clientId
			CreatedAfter: $createdAfter
			Version: $version
		) @rest(path: "Project/ViewProjects?{args}") {
			data {
				companyName
				clientName
				userName
				statusName
				progress
				id
				role
				name
				companyId
				clientId
				createdById
				statusId
				projectType
				startDate
				createdDate
				modifiedDate
				endDate
				projectSubRoles
				individualsInterviewedPrefix
				documentationReviewedPrefix
				version
			}
		}
	}
`;
