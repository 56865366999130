import React from 'react';
import { useFormikContext } from 'formik';
import { CheckboxGroupField } from '../../formaggio';
import { ProjectSubRole, RolesEnum } from '../../auth/enums';
import { useAuthContext } from '../../auth/context';
import { subRoleDetails } from '../../auth/constants';

const getSubRoleOptions = (isQaEnabled?: boolean) =>
	Object.entries(subRoleDetails)
		.filter(([k]) => (isQaEnabled ? true : k !== String(ProjectSubRole.QA)))
		.map(([k, v]) => ({
			label: v.title,
			value: Number(k),
		}));

function SubRoleSelector() {
	const { globalOptions = {} } = useAuthContext();
	const formContext = useFormikContext<any>();
	const { role } = formContext.values;

	if (role !== RolesEnum.Qsa) return null;

	return (
		<CheckboxGroupField
			name="projectSubRoles"
			options={getSubRoleOptions(globalOptions.workflowQAEnabled)}
		/>
	);
}

export default React.memo(SubRoleSelector);
