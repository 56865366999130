export function getUniqueListBy(arr, key): any[] {
	return [...new Map(arr.map((item) => [item[key], item])).values()];
}

//Text search
export function searchTextFilter(text = '', query = '', exact = false) {
	const cleanQuery = query.trim().toLocaleUpperCase();

	if (!cleanQuery) return true;

	const result = text.trim().toLocaleUpperCase();

	return exact ? result === cleanQuery : result.toLocaleUpperCase().indexOf(cleanQuery) >= 0;
}
