import Editor, { PluginEditorProps } from '@draft-js-plugins/editor';
import React from 'react';
import { Box } from '@mui/material';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import { mentionDraftPluginConfig } from './constants';
import { draftBlockRenderer, draftDecorators } from './helpers';

export default function PureDraftEditor({
	mentionsConfig,
	...props
}: PluginEditorProps & { mentionsConfig?: MentionSuggestionsPubProps }) {
	const ref = React.useRef<Editor>(null);

	// This is the only working way of initializing for mentions to display properly
	const { MentionSuggestions, plugins } = React.useMemo(() => {
		const mentionPlugin = createMentionPlugin(mentionDraftPluginConfig);
		const { MentionSuggestions } = mentionPlugin;
		const plugins = [mentionPlugin, createLinkifyPlugin()];
		return { plugins, MentionSuggestions };
	}, []);

	return (
		<Box onClick={() => ref.current?.focus()}>
			<Editor
				{...props}
				plugins={plugins}
				decorators={[draftDecorators]}
				blockRendererFn={draftBlockRenderer}
				ref={ref}
			/>
			{mentionsConfig && <MentionSuggestions {...mentionsConfig} />}
		</Box>
	);
}
