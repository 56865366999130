import { cx } from '@emotion/css/macro';
import TableRow from '@material-ui/core/TableRow';
import { TableStickyCell } from '../../../shared/components';
import { attachmentHighlightClass } from '../styles';

type ObservationEvidenceProps = any;

export default function ObservationEvidence({
	referenceNumber,
	process,
	description,
	highlighted,
	workpaperTitle,
	children,
}: ObservationEvidenceProps) {
	return (
		<TableRow>
			<TableStickyCell className={cx(highlighted ? attachmentHighlightClass : null)}>
				{referenceNumber}
			</TableStickyCell>
			<TableStickyCell>{process}</TableStickyCell>
			<TableStickyCell>{description}</TableStickyCell>
			<TableStickyCell>{workpaperTitle}</TableStickyCell>
			{children}
		</TableRow>
	);
}
