import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css, cx } from '@emotion/css/macro';
import { Add, Delete, Edit, Lock } from '@mui/icons-material';
import Tooltip from '@material-ui/core/Tooltip';
import { Chip } from '@mui/material';
import { useAllowedMethods, useReportAccess } from '../../../auth/hooks';
import { usePermalinkHighlightQS, useReplicator, useScrollToReportItem } from '../../hooks';
import { useReportContext } from '../../context/ReportContext';
import useNotApplicableControl from '../../useNotApplicableControl';
import { highlightCellClass } from '../../LinkButton';
import CellStatus from '../../CellStatus';
import WorkflowButton from '../../../workflow/components/WorkflowButton';
import { useItemContext } from '../../context/ItemContext';
import WorkflowQuickApprove from '../../../workflow/components/WorkflowQuickApprove';
import { EditableRowContext, useEditableRowContext } from '../../context/EditableRowContext';
import type { ContextValue } from '../../context/EditableRowContext';
import SimplifiedButtonGroup from '../../simplifiedControls/SimplifiedButtonGroup';
import SimplifiedButton from '../../simplifiedControls/SimplifiedButton';
import SimplifiedIconButton from '../../simplifiedControls/SimplifiedIconButton';
import ArrayImport, { useArrayImportStatus } from './ArrayImport';
import { hasFlag } from '../../../core/helpers';

const buttonGroupClass = css`
	margin-left: 16px;
`;
const importContainerClass = css`
	font-size: 16px;
	padding: 5px;
`;
const importCircularClass = css`
	width: 16px !important;
	height: 16px !important;
`;

function ArrayItem({ removeItem, forceProgress, children }: any) {
	const { itemId, lists } = useItemContext();
	const {
		'RocItemControllerNew/report/deleteArrayItem': canDelete,
		'RocItemControllerNew/report/addArrayItem': canAddOrEdit,
	} = useAllowedMethods();
	const removeThisItem = React.useCallback(() => removeItem(itemId), [itemId, removeItem]);
	const { setCurrentId } = useEditableRowContext();

	if (!itemId) return null;

	return (
		<>
			<tr>
				<td colSpan={Number.MAX_SAFE_INTEGER}>
					<Box display="flex" alignItems="center">
						{forceProgress && (
							<>
								<CellStatus />
								<WorkflowQuickApprove />
							</>
						)}
						<SimplifiedButtonGroup className={buttonGroupClass}>
							{forceProgress && <WorkflowButton itemId={itemId} list={lists.workflows} />}
							{canAddOrEdit && (
								<SimplifiedIconButton onClick={() => setCurrentId(itemId)}>
									<Edit />
								</SimplifiedIconButton>
							)}
							{canDelete && (
								<SimplifiedIconButton onClick={removeThisItem}>
									<Delete />
								</SimplifiedIconButton>
							)}
						</SimplifiedButtonGroup>
					</Box>
				</td>
			</tr>
			{children}
		</>
	);
}

export default function Array({
	viewMode,
	path = [],
	itemId,
	fetchAPI,
	notRecommended,
	importDisabled,
	notApplicable,
	notApplicableReasons,
	isImporting,
	importStatus,
	writePermissionMask,
	children = [],
}: any) {
	const { projectId, refresh } = useReportContext();
	const { isAQSA, role } = useReportAccess();
	const [idToEdit, setIdToEdit] = React.useState(null);

	const contextValue = React.useMemo<ContextValue>(
		() => ({
			currentId: idToEdit,
			setCurrentId: setIdToEdit,
			isArrayRow: true,
		}),
		[idToEdit, setIdToEdit],
	);

	const {
		'RocItemControllerNew/report/addArrayItem': canAddArrayItem,
		'RocItemControllerNew/SetApplicable': canSetApplicable,
	} = useAllowedMethods();

	const canAddItem = React.useMemo(
		() =>
			isAQSA
				? canAddArrayItem
				: canAddArrayItem && !!role && writePermissionMask && hasFlag(writePermissionMask, role),
		[canAddArrayItem, isAQSA, role, writePermissionMask],
	);

	const removeItem = React.useCallback(
		(itemId: string) =>
			fetchAPI({
				query: `RocItemControllerNew/report/deleteArrayItem/${projectId}/${itemId}`,
				method: 'DELETE',
				onSuccess: () => refresh(),
				confirmation: { title: 'Are you sure you want to delete this row?' },
			}),
		[fetchAPI, projectId, refresh],
	);

	const Replicator = useReplicator(viewMode);

	const renderItems = React.useMemo(
		() =>
			children
				.filter(({ isHiddenInReport }) => !isHiddenInReport)
				.map(({ props }: any, idx: number) => (
					<Replicator
						{...props}
						removeItem={removeItem}
						itemId={props.itemId}
						key={props.itemId}
						idx={idx}
						viewMode={viewMode}
						path={[...path, `items[${idx}]`]}
						fetchAPI={fetchAPI}
						wrapperComponent={ArrayItem}
						isArrayChild
					/>
				)),
		[Replicator, fetchAPI, children, path, removeItem, viewMode],
	);

	const addItem = React.useCallback(
		() =>
			fetchAPI({
				query: `RocItemControllerNew/report/addArrayItem/${projectId}/${itemId}`,
				method: 'POST',
				onSuccess: ({ data }) => {
					refresh();
					setIdToEdit(data.itemId);
				},
			}),
		[fetchAPI, projectId, itemId, refresh],
	);

	// const approve = React.useCallback(
	// 	() =>
	// 		fetchAPI({
	// 			query: `Workflow/ApproveArray/${projectId}/${id}`,
	// 			method: 'POST',
	// 			onSuccess: refresh,
	// 		}),
	// 	[fetchAPI, projectId, id, refresh],
	// );

	const arrayImportStatus = useArrayImportStatus({ id: itemId, isImporting, importStatus });

	//Not applicable handling
	const { notApplicableReasonsComponent, clickNotApplicable } = useNotApplicableControl({
		itemId,
		notApplicable,
		notApplicableReasons,
	});

	//Highlight
	const highlightQS = usePermalinkHighlightQS();
	const ref = useScrollToReportItem(itemId);
	const isHighlighted = !!highlightQS && highlightQS === itemId;

	const hasItems = children.length > 0;
	const canImport = !importDisabled && !notApplicable && canAddItem;

	if (arrayImportStatus && arrayImportStatus.isImporting)
		return (
			<tr>
				<td colSpan={Number.MAX_SAFE_INTEGER} className={importContainerClass}>
					<CircularProgress className={importCircularClass} />
					<span style={{ paddingLeft: '5px' }}>Table is importing, please wait...</span>
				</td>
			</tr>
		);

	const showImportError =
		!importDisabled &&
		arrayImportStatus &&
		!arrayImportStatus.isImporting &&
		arrayImportStatus.status &&
		arrayImportStatus.status !== 'success';

	return (
		<EditableRowContext.Provider value={contextValue}>
			{showImportError && (
				<tr>
					<td colSpan={Number.MAX_SAFE_INTEGER}>
						<Chip label={arrayImportStatus.status} color="error" />
					</td>
				</tr>
			)}
			{notApplicable ? (
				<tr>
					<td colSpan={Number.MAX_SAFE_INTEGER}>
						<Chip label="Table not applicable" />
					</td>
				</tr>
			) : (
				renderItems
			)}
			<tr>
				<td
					colSpan={Number.MAX_SAFE_INTEGER}
					ref={ref as any}
					className={cx(isHighlighted && highlightCellClass)}
				>
					{canAddItem && !notApplicable && (
						<>
							<Tooltip title="Add manual entry">
								<SimplifiedIconButton onClick={addItem}>
									<Add />
								</SimplifiedIconButton>
							</Tooltip>
							{notRecommended && (
								<Box margin="4px 0 8px 0" fontSize="13px">
									Manual entries here are not recommended. Use the functionality in related Findings
									and Observations controls so the system will automate filling of this table and
									other tasks.
								</Box>
							)}
						</>
					)}
					{canSetApplicable && (
						<>
							{!notApplicable && !hasItems && (
								<SimplifiedButton startIcon={<Lock />} onClick={clickNotApplicable}>
									Mark table N/A
								</SimplifiedButton>
							)}
							{notApplicableReasonsComponent}
						</>
					)}
					{!canSetApplicable && !canAddArrayItem && isHighlighted && <div>See table above</div>}
				</td>
			</tr>
			{canImport && <ArrayImport arrayId={itemId} />}
		</EditableRowContext.Provider>
	);
}
