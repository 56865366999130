import React from 'react';
import {
	ProjectInfoAccess,
	InterviewModel,
	ReportContact,
	ReportUser,
	Workflow,
	Channel,
	HelpMessage,
} from '../../core/types/api';
import { BaseAttachment } from '../../attachments/types';

type ReportContextType = {
	attachments?: BaseAttachment[];
	interviews?: InterviewModel[];
	workflows?: Workflow[];
	hints?: HelpMessage[];
	users?: ReportUser[];
	contacts?: ReportContact[];
	filteredItemIds?: string[];
	showCopyLink?: boolean;
	projectId: string;
	refresh: () => void;
	projectInfo: ProjectInfoAccess;
	isTokenPage?: boolean;
	getChannelById: (id: string) => Channel;
};

const reportContextInitial: ReportContextType = {
	refresh: () => undefined,
	projectId: '',
	projectInfo: {},
	getChannelById: () => ({ name: '' }),
};

const ReportContext = React.createContext<ReportContextType>(reportContextInitial);

type ReportContextWrapperProps = Omit<ReportContextType, 'getChannelById'> & {
	children: React.ReactNode;
};

export default function ReportContextWrapper({
	attachments,
	interviews,
	workflows,
	hints,
	users,
	contacts,
	showCopyLink,
	children,
	filteredItemIds,
	projectId,
	refresh,
	projectInfo,
	isTokenPage,
}: ReportContextWrapperProps) {
	//Controls context to avoid passing these through all replicator props
	const [context, setContext] = React.useState<ReportContextType>({
		isTokenPage,
		...reportContextInitial,
	});

	React.useEffect(() => {
		setContext((prevState) => ({
			...prevState,
			attachments: attachments || [],
			interviews: interviews || [],
			workflows: workflows || [],
			hints: hints || [],
			users: users || [],
			contacts: contacts || [],
			showCopyLink,
			filteredItemIds: filteredItemIds || [],
			projectId,
			refresh,
			projectInfo,
			getChannelById: (cid) =>
				(projectInfo.paymentChannels || []).filter(({ id }) => cid === id)[0],
		}));
	}, [
		setContext,
		attachments,
		interviews,
		workflows,
		hints,
		users,
		contacts,
		showCopyLink,
		filteredItemIds,
		projectId,
		refresh,
		projectInfo,
	]);

	return <ReportContext.Provider value={context}>{children}</ReportContext.Provider>;
}

export function useReportContext(): ReportContextType {
	return React.useContext(ReportContext);
}

export function useReportInfoContext(): ProjectInfoAccess {
	const { projectInfo } = useReportContext();

	return projectInfo;
}
