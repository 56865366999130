import React from 'react';
import { css } from '@emotion/css/macro';
import Data from '../UserData';
import { RocDataType } from '../enums';
import { Heading } from '../../core/components';
import { useReportAccess } from '../../auth/hooks';
import { useToggle } from '../../core/hooks';
import SimplifiedTextField from '../simplifiedControls/SimplifiedTextField';
import SimplifiedButton from '../simplifiedControls/SimplifiedButton';

const textFieldClass = css`
	white-space: pre-wrap;
`;

export function TextAreaPresentation({ value, onFocus }: any) {
	return (
		<span className={textFieldClass} onMouseOver={onFocus} onFocus={onFocus}>
			{value?.value}
		</span>
	);
}

export const TextAreaInput = React.forwardRef(({ additional, value, ...rest }: any, ref) => (
	<SimplifiedTextField
		inputRef={ref}
		label={additional?.label}
		value={value?.value || ''}
		{...rest}
		isTextArea
	/>
));

const NAME_PLACEHOLDER = 'name';

function AssessorNamePresentation({ value, onFocus }: any) {
	return (
		<Heading gutterBottom onMouseOver={onFocus} onFocus={onFocus}>
			{value.value}
		</Heading>
	);
}

function AssessorNameInput({ onChange, value, onFocus }: any) {
	const { isOn, toggleOn } = useToggle();
	const click = React.useCallback(() => {
		onChange({ target: { value: NAME_PLACEHOLDER } });
		toggleOn();
	}, [onChange, toggleOn]);
	const { isAdmin, isQSA } = useReportAccess();
	const allowedToClick = isAdmin || isQSA;
	const { value: innerValue } = value;

	if (innerValue === NAME_PLACEHOLDER)
		return <Heading gutterBottom>Will be set as your name</Heading>;

	return (
		<div onMouseEnter={onFocus}>
			{innerValue && innerValue !== NAME_PLACEHOLDER && (
				<Heading gutterBottom>{innerValue}</Heading>
			)}
			<SimplifiedButton disabled={!allowedToClick || isOn} onClick={click}>
				{allowedToClick ? 'Set as me' : 'For assessor only'}
			</SimplifiedButton>
		</div>
	);
}

const retrieveValue = (e: any) => ({
	value: e.target.value,
});

const validation = ({ value }: any) => (!String(value || '').trim() ? 'Required field' : undefined);

function getSubTypeProps(subType) {
	if (subType === 'ResponsibleRole') return { additional: { label: 'Responsible role' } };

	return {};
}

export default function TextField({ rocDataType, label, ...rest }: any) {
	const isAssessorName = rocDataType === RocDataType.AssessorName;

	const subTypeProps = React.useMemo(() => getSubTypeProps(rest.subType), [rest.subType]);

	return (
		<Data
			retrievedValue={retrieveValue}
			inputElement={isAssessorName ? AssessorNameInput : TextAreaInput}
			presentationElement={isAssessorName ? AssessorNamePresentation : TextAreaPresentation}
			rocDataType={rocDataType}
			additional={{ label }}
			{...rest}
			validation={validation}
			instantSave={isAssessorName}
			{...subTypeProps}
		/>
	);
}
