import TableCell from '@material-ui/core/TableCell';
import { ProjectAttachmentsSharedProps } from '../types';
import ProjectAttachments from '../ProjectAttachments';
import { RocDataType } from '../../rocTemplates/enums';
import ObservationEvidence from './ObservationEvidence';
import ObservationEvidenceAddUpdateForm from './ObservationEvidenceAddUpdateForm';

export default function ProjectObservationEvidence(props: ProjectAttachmentsSharedProps) {
	return (
		<ProjectAttachments
			{...props}
			attachmentName="observation"
			pluralName="observations"
			prefix="Proc"
			AttachmentComponent={ObservationEvidence}
			AddUpdateForm={ObservationEvidenceAddUpdateForm}
			type={RocDataType.ObservationEvidence}
			headerCells={
				<>
					<TableCell>Observed Process</TableCell>
					<TableCell>Brief Description of the Process</TableCell>
					<TableCell>Title of Workpaper with Observation Notes</TableCell>
					<TableCell />
					<TableCell>Referenced In</TableCell>
				</>
			}
		/>
	);
}
