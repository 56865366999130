import React from 'react';
import { css } from '@emotion/css/macro';
import { Box } from '@mui/material';
import MaterialDialog from '../flyout/components/MaterialDialog';
import { Button } from '../core/components';
import { useToggle } from '../core/hooks';
import { PureTextField } from '../formaggio';
import { phrases } from '../core/constants/phrases';
import { useFieldHandler } from '../core/helpers/stateActionHelpers';
import { useKeyListener } from '../core/helpers/keyListener';

const noSelectClass = css`
	user-select: none;
`;

export type ConfirmationParams = {
	keyToConfirm?: string;
	title?: string;
	message?: any;
};

type ConfirmationState = { action: any } & ConfirmationParams;

type ConfirmationContextType = (action: any, params: ConfirmationParams) => void;

const confirmationInitial = { action: () => undefined };

const ConfirmationContext = React.createContext<ConfirmationContextType>(() => undefined);

function ConfirmationActions({ doAction, isOn, reset }) {
	useKeyListener('keyup', (a: KeyboardEvent) => {
		if (a.key === 'Enter') doAction();
	});

	if (!isOn) return null;

	return (
		<>
			<Button color="primary" onClick={doAction}>
				Confirm [Enter]
			</Button>
			<Button color="secondary" onClick={reset} disabled={!isOn}>
				Cancel [Esc]
			</Button>
		</>
	);
}

export function ConfirmationContextProvider({ children }: { children: React.ReactNode }) {
	const [context, setContext] = React.useState<ConfirmationState>(confirmationInitial);
	const { isOn, toggleOn, toggleOff } = useToggle();
	const { action, title, keyToConfirm, message } = context;

	const [keyValue, setKeyValue] = React.useState<string>('');
	const handleKeyField = useFieldHandler(setKeyValue);

	const showConfirmation = React.useCallback(
		(action: any, params: ConfirmationParams = {}) => {
			setContext({ action, ...params });
			toggleOn();
		},
		[setContext, toggleOn],
	);

	const reset = React.useCallback(() => {
		toggleOff();
		setKeyValue('');
	}, [toggleOff, setKeyValue]);

	const doAction = React.useCallback(() => {
		if (!!keyToConfirm && keyValue !== keyToConfirm) return;

		reset();
		action();
	}, [action, reset, keyValue, keyToConfirm]);

	return (
		<ConfirmationContext.Provider value={showConfirmation}>
			<MaterialDialog
				title={title || phrases.yesOrNo}
				isOpen={isOn}
				actions={isOn && <ConfirmationActions doAction={doAction} isOn={isOn} reset={reset} />}
				onClose={reset}
			>
				{message}
				{keyToConfirm && (
					<Box marginTop="16px">
						<span className={noSelectClass}>
							Enter <b>{keyToConfirm}</b> in this field to confirm
						</span>
						<PureTextField
							errorMessage={keyValue !== keyToConfirm}
							onChange={handleKeyField}
							value={keyValue}
						/>
					</Box>
				)}
			</MaterialDialog>
			{children}
		</ConfirmationContext.Provider>
	);
}

export function useConfirmation(initialAction?: any, initialParams: ConfirmationParams = {}) {
	const context = React.useContext(ConfirmationContext);
	const [initialState] = React.useState(initialParams);

	return React.useCallback(
		(action: any, state: ConfirmationParams = {}) =>
			context(initialAction || action, { ...initialState, ...state }),
		[context, initialAction, initialState],
	);
}
