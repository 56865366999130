import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { connect } from 'react-redux';
import { Button } from '../../core/components';
import { popUpClose } from '../reducers';
import { darkness } from '../../ui/Core/stylesheets/colors';

const popUpOuter = css`
	overflow: auto;
	display: flex;
	justify-content: space-between;
	z-index: 9999;
	box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
	position: fixed;
	width: 100%;
	max-height: 5rem;
	left: 0;
	padding: 8px 16px;
	transition: top 0.3s, background 0.3s;
`;

const messageClass = css`
	color: white;
	margin: auto 0;
	word-break: break-all;
`;

const buttonClass = css`
	color: white !important;
	margin-left: 16px !important;
	border-color: white !important;
	position: sticky !important;
	top: 0 !important;
`;

export type PopUpProps = {
	text?: React.ReactNode;
	color?: string;
	onClose?: () => void;
};

function PopUp({ text, color = darkness, onClose }: PopUpProps) {
	const popUpVisible = css`
		top: ${text ? 0 : -6}rem;
	`;
	const popUpColor = css`
		background: ${color};
	`;
	return (
		<div className={cx(popUpOuter, popUpVisible, popUpColor)}>
			<div className={messageClass}>{text}</div>
			<Button className={buttonClass} variant="outlined" onClick={onClose}>
				Close
			</Button>
		</div>
	);
}

export default connect(
	(state: any) => state.popupReducer,
	(dispatch) => ({
		onClose: () => {
			dispatch<any>(popUpClose());
		},
	}),
)(PopUp);
