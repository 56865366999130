//Other version of presets without RowsFragment
import { middlegrey } from '../../ui/Core/stylesheets/colors';

const OneColumnHeader = [{ type: 8 }];

const OneColumnData = [{ type: 0 }];

const TwoColumnsData = [{ type: 0 }, { type: 0 }];

const TwoColumnsHeader = [{ type: 8 }, { type: 8 }];

const TwoColumnsHeaderAndData = [{ type: 8 }, { type: 0 }];

const ThreeColumnsData = [{ type: 0 }, { type: 0 }, { type: 0 }];

const ThreeColumnsHeader = [{ type: 8 }, { type: 8 }, { type: 8 }];

const ThreeColumnsHeaderHeaderData = [{ type: 8 }, { type: 8 }, { type: 0 }];

const FourColumnsHeader = [{ type: 8 }, { type: 8 }, { type: 8 }, { type: 8 }];

const FourColumnsData = [{ type: 0 }, { type: 0 }, { type: 0 }, { type: 0 }];

const FiveColumnsHeader = [{ type: 8 }, { type: 8 }, { type: 8 }, { type: 8 }, { type: 8 }];

const FiveColumnsData = [{ type: 0 }, { type: 0 }, { type: 0 }, { type: 0 }, { type: 0 }];

const SixColumnsData = [
	{ type: 0 },
	{ type: 0 },
	{ type: 0 },
	{ type: 0 },
	{ type: 0 },
	{ type: 0 },
];

const OneColumnHeaderWithSOA = [{ type: 8 }, { type: 11 }];

export const columnStyles: Record<string, any> = {
	S6_1: [{ classNames: ['rocLightGrey'], attributes: { colSpan: 8 } }],
	S6_1_HD: [
		{ classNames: ['rocLightGrey'] },
		{ classNames: ['rocGreen'] },
		{ attributes: { colSpan: 2 } },
	],
	S6_1_HD_2: [
		{ classNames: ['rocGreen'], attributes: { colSpan: 2 } },
		{ attributes: { colSpan: 2 } },
	],
	S6_1_SOA: [
		{ classNames: ['rocLightGrey'], attributes: { colSpan: 3 } },
		{ attributes: { colSpan: 5 } },
	],
	S6_1_SOA_G: [
		{ classNames: ['rocGreen'], attributes: { colSpan: 3 } },
		{ attributes: { colSpan: 5 } },
	],
	S6_2_H: [{ classNames: ['rocGreen'], attributes: { colSpan: 7 } }],
	S6_2_D: [{ attributes: { colSpan: 7 } }],
	S6_2_HD: [{ classNames: ['rocGreen'] }, { attributes: { colSpan: 6 } }],
	HD: [{ attributes: { style: 'width: 50%;' } }],
	AOC_H_1: [
		{
			attributes: {
				style: `background: ${middlegrey}; font-size: 18px !important; font-weight: 600 !important;`,
				colSpan: 10,
			},
		},
	],
	AOC_H_2: [
		{
			attributes: {
				style: `background: ${middlegrey}; font-size: 14px !important; font-weight: 600 !important;`,
				colSpan: 10,
			},
		},
	],
};

export const rowPresets: any = [
	{
		preset: OneColumnHeader,
		name: 'S6_1',
		title: 'Section 6 - Table Row - One Column Header',
	},
	{
		preset: ThreeColumnsHeaderHeaderData,
		name: 'S6_1_HD',
		title: 'Section 6 - Table Row - Header, Header & Data',
	},
	{
		preset: OneColumnHeaderWithSOA,
		name: 'S6_1_SOA',
		title: 'Section 6 - Table Row - One Column Header with SOA',
	},
	{
		preset: OneColumnHeaderWithSOA,
		name: 'S6_1_SOA_G',
		title: 'Section 6 - Table Row - One Column Header with SOA (Green)',
	},
	{
		preset: OneColumnHeader,
		name: 'S6_2_H',
		title: 'Section 6 - One Multiple Row - Header',
	},
	{
		preset: OneColumnData,
		name: 'S6_2_D',
		title: 'Section 6 - One Multiple Row - Data',
	},
	{
		preset: TwoColumnsHeaderAndData,
		name: 'S6_2_HD',
		title: 'Section 6 - One Multiple Row - Header And Data',
	},
	{ preset: OneColumnHeader, name: '1H', title: '1 Column Header' },
	{ preset: OneColumnData, name: '1D', title: '1 Column Data' },
	{ preset: TwoColumnsData, name: '2D', title: '2 Columns Data' },
	{ preset: TwoColumnsHeader, name: '2H', title: '2 Columns Header' },
	{ preset: TwoColumnsHeaderAndData, name: 'HD', title: '2 Columns Header And Data' },
	{ preset: ThreeColumnsData, name: '3D', title: '3 Columns Data' },
	{ preset: ThreeColumnsHeader, name: '3H', title: '3 Columns Header' },
	{ preset: FourColumnsHeader, name: '4H', title: '4 Columns Header' },
	{ preset: FourColumnsData, name: '4D', title: '4 Columns Data' },
	{ preset: FiveColumnsHeader, name: '5H', title: '5 Columns Header' },
	{ preset: FiveColumnsData, name: '5D', title: '5 Columns Data' },
	{ preset: SixColumnsData, name: '6D', title: '6 Columns Data' },
];

export const presetToSelectOptions = (preset: any) =>
	preset.map(({ title }: any, idx: number) => ({ title, value: idx }));

export const rowPresetsSelect = presetToSelectOptions(rowPresets);
