import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Data from '../UserData';
import { retrieveSummaryValue } from './helper';

function SummaryOfAssessmentInput({ id, value, onFocus }: any) {
	return (
		<RadioGroup
			style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}
			name={id}
			value={String(value?.value === undefined ? '' : value.value)}
			onFocus={onFocus}
			onMouseEnter={onFocus}
		>
			<FormControlLabel disabled value="0" control={<Radio />} label="Compliant" />
			<FormControlLabel disabled value="1" control={<Radio />} label="Not Compliant" />
			<FormControlLabel disabled value="2" control={<Radio />} label="Not Applicable" />
			<FormControlLabel disabled value="3" control={<Radio />} label="Not Tested" />
		</RadioGroup>
	);
}

export default function SummaryOfAssessment({ ...rest }: any) {
	return (
		<Data
			retrievedValue={retrieveSummaryValue}
			inputElement={SummaryOfAssessmentInput}
			instantSave
			{...rest}
		/>
	);
}
