import ContactForm, { SuccessNewOrUpdatedContactHandler } from './ContactForm';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';

type NewContactFlyOutProps = {
	isOpen: boolean;
	onClose: () => void;
	clientId?: string;
	onSuccess: SuccessNewOrUpdatedContactHandler;
};

export default function NewContactFlyOut({
	isOpen,
	onClose,
	clientId,
	onSuccess,
}: NewContactFlyOutProps) {
	return (
		<MaterialFlyOut open={isOpen} onClose={onClose} title="New Contact">
			<ContactForm clientId={clientId} onSuccess={onSuccess} />
		</MaterialFlyOut>
	);
}
