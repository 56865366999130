import React from 'react';
import { css } from '@emotion/css/macro';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from '@mui/icons-material';
import { useToggle } from '../core/hooks';
import { mainLight } from '../ui/Core/stylesheets/colors';
import { fade } from '../ui/Core/stylesheets/keyframes';
import { clipboardCopy } from '../core/helpers';
import SimplifiedIconButton from './simplifiedControls/SimplifiedIconButton';

const COPIED_MESSAGE_HIDE_DELAY = 3000;
export const ITEM_HIGHLIGHT_QS_KEY = 'id';

export const relativeCellClass = css`
	position: relative;
`;

export const highlightCellClass = css`
	box-shadow: inset 0 0 0 4px ${mainLight};
	animation: ${fade} 0.3s;
`;

const textRootClass = css`
	width: 0;
	height: 0;
`;

const textClass = css`
	opacity: 0;
	display: fixed;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	resize: none;
	user-select: none;
`;

type LinkButtonType = { itemId: string; showLink: boolean };

export function LinkButton({ itemId, showLink }: LinkButtonType) {
	const ref = React.useRef<HTMLInputElement>(null);
	const { isOn, toggleOn, toggleOff } = useToggle();

	const itemLink = `${window.location.origin}${window.location.pathname}?${ITEM_HIGHLIGHT_QS_KEY}=${itemId}`;

	const copyLink = React.useCallback(() => {
		if (ref && ref.current) {
			ref.current.select();
			clipboardCopy(itemLink);
			toggleOn();
		}
	}, [toggleOn, itemLink]);
	return (
		<>
			{showLink && (
				<>
					<Tooltip title="Copy Link">
						<SimplifiedIconButton tabIndex={-1} onClick={copyLink}>
							<Link />
						</SimplifiedIconButton>
					</Tooltip>
					<div className={textRootClass}>
						<input
							tabIndex={-1}
							className={textClass}
							type="text"
							ref={ref}
							defaultValue={itemLink}
						/>
					</div>
				</>
			)}
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={isOn}
				autoHideDuration={COPIED_MESSAGE_HIDE_DELAY}
				onClose={toggleOff}
				message="Item link copied"
			/>
		</>
	);
}
