import { useAllowedMethods } from '../auth/hooks';
import { useQuickFetch } from '../core/actions/useQuickFetch';
import {
	ClientGetMyClientsListParams,
	ClientModelArraySuccessResultResponse,
} from '../core/types/api';

export function useMyClients(skip?: boolean, filter?: ClientGetMyClientsListParams) {
	const { 'Client/GetMyClients': canViewClients } = useAllowedMethods();
	const { data, refetch, loading } = useQuickFetch<
		ClientModelArraySuccessResultResponse,
		ClientGetMyClientsListParams
	>(
		{
			query: 'Client/GetMyClients',
			qs: filter,
		},
		!canViewClients || skip,
	);

	return { list: data?.data, refetch, loading };
}
