import React from 'react';
import { useFormikContext } from 'formik';
import Alert from '@mui/material/Alert';
import { SelectField } from '../../formaggio';
import { ItemStatus } from '../../project/enums';
import { useAuthContext } from '../../auth/context';
import { useReportAccess } from '../../auth/hooks';
import { progressStatuses } from '../../project/progressCalculation';
import { flagSumToArray } from '../../core/helpers';
import { useItemStatus } from '../../rocTemplates/hooks';
import { WorkflowRole } from '../../core/types/api';

const allStatusOptions = (includeAny?: boolean) => {
	const statuses = Object.keys(progressStatuses).reduce((memo: any[], curr: any) => {
		const { title } = progressStatuses[curr];
		return title ? [...memo, { title, value: curr }] : memo;
	}, []);

	return includeAny ? [{ value: 0, title: 'Any status' }, ...statuses] : statuses;
};

function getStatusOptions(
	workflowStatusMatrix: Record<string, Record<string, ItemStatus>>,
	currentWorkflowRole: WorkflowRole,
	currentStatus?: number,
) {
	if (!currentWorkflowRole || currentStatus === undefined) return [];

	if (currentStatus === 0) return allStatusOptions();

	if (!currentStatus) return [];

	const nextAvailableStatuses = workflowStatusMatrix[progressStatuses[currentStatus].name];
	if (!nextAvailableStatuses) return [];

	return Object.keys(nextAvailableStatuses)
		.reduce(
			(memo: any, nextStatusRole) =>
				nextStatusRole === '0' ||
				nextStatusRole.toLocaleLowerCase() === WorkflowRole[currentWorkflowRole].toLocaleLowerCase()
					? [...memo, ...flagSumToArray(nextAvailableStatuses[nextStatusRole])]
					: memo,
			[],
		)
		.map((value: any) => ({
			title: progressStatuses[Number(value)].title,
			value,
		}));
}

type BulkStatusSelectorProps = { isBulk?: boolean };

export default function WorkflowStatusSelector({ isBulk }: BulkStatusSelectorProps) {
	const { workflowStatusMatrix } = useAuthContext();
	const status = useItemStatus();
	const { workflowRole } = useReportAccess();
	const { values, setFieldValue } = useFormikContext<any>();
	const { bulkStatus, status: selectedStatus } = values;
	const statusOptions = React.useMemo(
		() =>
			getStatusOptions(
				workflowStatusMatrix,
				workflowRole,
				isBulk ? bulkStatus : status || ItemStatus.NotStarted,
			),
		[workflowStatusMatrix, isBulk, bulkStatus, status, workflowRole],
	);
	React.useEffect(() => {
		setFieldValue('status', undefined);
	}, [bulkStatus, setFieldValue]);

	if (!isBulk && statusOptions.length === 0) return null;

	return (
		<>
			{isBulk && (
				<SelectField name="bulkStatus" label="Change all in" options={allStatusOptions(true)} />
			)}
			<SelectField name="status" label={isBulk ? 'To' : 'Status'} options={statusOptions} />
			{isBulk && bulkStatus === 0 && !!selectedStatus && (
				<Alert severity="info">
					Setting certain status on all items might
					<br />
					skip some of them due to flow restrictions
				</Alert>
			)}
		</>
	);
}
