import TableCell from '@material-ui/core/TableCell';
import { ProjectAttachmentsSharedProps } from '../types';
import ProjectAttachments from '../ProjectAttachments';
import { RocDataType } from '../../rocTemplates/enums';
import SystemEvidenceAddUpdateForm from './SystemEvidenceAddUpdateForm';
import SystemEvidence from './SystemEvidence';
import { useItemContext } from '../../rocTemplates/context/ItemContext';

const quickSnippets = {
	presets: ['Record', 'Policy', 'Conf', 'Source', 'Storage', 'Repository', 'Location'],
	replaceMode: true,
};

export default function ProjectSystemEvidence(props: ProjectAttachmentsSharedProps) {
	const { attachmentPrefix } = useItemContext();

	return (
		<ProjectAttachments
			{...props}
			attachmentName="system evidence"
			pluralName="system evidence"
			prefix={attachmentPrefix || 'Conf'}
			AttachmentComponent={SystemEvidence}
			AddUpdateForm={SystemEvidenceAddUpdateForm}
			type={RocDataType.SystemEvidence}
			quickSnippets={quickSnippets}
			headerCells={
				<>
					<TableCell>
						System Components Reviewed
						<br />
						<small>(Uniquely identify system components or reference a sample set)</small>
					</TableCell>
					<TableCell>Brief Description of the Evidence</TableCell>
					<TableCell />
					<TableCell>Referenced In</TableCell>
				</>
			}
		/>
	);
}
