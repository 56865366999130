import Hints from './Hints';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import HoverNotes from './HoverNotes';
import { useToggle } from '../../core/hooks';

type HintsFlyOutProps = {
	list?: any[];
	isGlobalAdmin?: boolean;
	isAQSA?: boolean;
	itemId?: string;
};

export default function HintsFlyOut({ list, isGlobalAdmin, isAQSA, itemId }: HintsFlyOutProps) {
	const { isOn, toggleOn: on, toggleOff: off } = useToggle();

	if (!itemId) return null;

	return (
		<>
			<HoverNotes list={list} onClick={on} />
			<MaterialFlyOut open={isOn} onClose={off} title="Hints">
				<Hints list={list} isAQSA={isAQSA} isGlobalAdmin={isGlobalAdmin} itemId={itemId} />
			</MaterialFlyOut>
		</>
	);
}
