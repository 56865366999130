import React from 'react';
import { css } from '@emotion/css/macro';
import { BasicForm } from '../../../formaggio';
import { RocItem } from '../../types';
import ItemGeneralFields from './ItemGeneralFields';
import { Button, Heading } from '../../../core/components';
import { ClientReplicator } from '../../Replicator';
import { spacing } from '../../../ui/Core/stylesheets/spacing';
import { mainLight } from '../../../ui/Core/stylesheets/colors';
import { NodeType, RocItemType } from '../../enums';

const rootClass = css`
	display: flex;
`;

const previewClass = css`
	padding: ${spacing.small};
	border: 1px solid ${mainLight};
	margin-bottom: ${spacing.medium};
`;

type ItemAddOrEditProps = {
	lastNumber?: number;
	templateId: string;
	isEditMode: boolean;
	closeModal: () => void;
	refresh: any;
} & RocItem;

export default function ItemAddOrEdit({
	itemId,
	lastNumber,
	templateId,
	isEditMode,
	closeModal,
	refresh,
	parentsIds,
	...rest
}: ItemAddOrEditProps) {
	const [DTO, setDTO] = React.useState<any>(
		isEditMode
			? { id: { itemId }, editorParentIds: parentsIds, ...rest }
			: {
					type: '',
					order: lastNumber ? (lastNumber * 100).toString() : 10,
					nodeType: NodeType.Template,
					editorParentIds: [...parentsIds, itemId],
			  },
	);

	return (
		<div className={rootClass}>
			<BasicForm
				fetchConfig={{
					method: isEditMode ? 'POST' : 'PUT',
					query: isEditMode
						? `RocItemEditor/edit/${templateId}/${itemId}`
						: `RocItemEditor/add/${templateId}`,
					successMessage: true,
					onSuccess: () => {
						closeModal();
						refresh();
					},
				}}
				additional={DTO}
			>
				<ItemGeneralFields
					DTO={DTO}
					setDTO={setDTO}
					isEditMode={isEditMode}
					submitButton={
						<>
							<div className={previewClass}>
								<Heading size={1.5}>Preview:</Heading>
								<ClientReplicator
									fetchAPI={() => undefined}
									{...DTO}
									type={DTO.type || RocItemType.Container}
									{...(DTO.type === RocItemType.Array ? DTO.itemTemplate : {})}
								>
									{DTO && DTO.children}
								</ClientReplicator>
							</div>
							<Button color="secondary" type="submit">
								{isEditMode ? 'Update' : 'Insert'}
							</Button>
						</>
					}
					path={[]}
				/>
			</BasicForm>
		</div>
	);
}
