import React from 'react';
import { useQuery } from '@apollo/client';
import { BasicForm, SelectField, toSelectOptions } from '../../formaggio';
import { Button, Dropdown } from '../../core/components';
import { userDetailsFormClass } from './index';
import {
	GetCompaniesOfUserQuery,
	GetCompaniesOfUserQueryVariables,
} from '../../../graphql/typescript';
import { GET_COMPANIES_OF_USER_QUERY } from '../../../graphql/domains/company/queryList';
import { useUserCompanies } from '../../company/hooks';
import { filterObjectsWithKeyValueFromOthers } from '../../core/helpers';

type CompanyAssignProps = { refresh: () => void; userDetails: any };

export function CompanyAssign({ refresh, userDetails }: CompanyAssignProps) {
	const [isOn, setOn] = React.useState(false);

	//User company list
	const { data: userCompaniesData, refetch } = useQuery<
		GetCompaniesOfUserQuery,
		GetCompaniesOfUserQueryVariables
	>(GET_COMPANIES_OF_USER_QUERY, { skip: !isOn, variables: { userId: userDetails.id } });
	const userCompanies = React.useMemo(
		() => userCompaniesData?.list?.data || [],
		[userCompaniesData?.list?.data],
	);
	const userCompaniesOptions = React.useMemo(
		() => toSelectOptions(userCompanies, 'id', 'companyName'),
		[userCompanies],
	);
	const [myCompanies] = useUserCompanies();
	const userFilteredCompaniesOptions = React.useMemo(
		() =>
			toSelectOptions(
				filterObjectsWithKeyValueFromOthers(myCompanies || [], userCompanies, 'id'),
				'id',
				'companyName',
			),
		[myCompanies, userCompanies],
	);

	return (
		<Dropdown
			header="Assign to/Detach from QSA company"
			onChange={(_, expanded) => setOn(expanded)}
		>
			<BasicForm
				fetchConfig={{
					query: 'company/CreateCompanyUser',
					method: 'PUT',
					successMessage: true,
					onSuccess: () => refetch().then(() => refresh()),
				}}
				additional={{ userId: userDetails.id }}
				className={userDetailsFormClass}
			>
				<SelectField
					label="Company"
					name="companyId"
					options={userFilteredCompaniesOptions}
					searchable
				/>
				<Button type="submit" color="primary">
					Assign
				</Button>
			</BasicForm>
			{userCompanies.length > 0 && (
				<BasicForm
					fetchConfig={{
						query: 'company/DeleteCompanyUser',
						method: 'PUT',
						successMessage: true,
						confirmation: true,
						onSuccess: () => refetch().then(() => refresh()),
					}}
					additional={{ userId: userDetails.id }}
					className={userDetailsFormClass}
				>
					<SelectField label="Company" name="companyId" options={userCompaniesOptions} />
					<Button type="submit" color="secondary">
						Detach
					</Button>
				</BasicForm>
			)}
		</Dropdown>
	);
}
