import React from 'react';
import { css } from '@emotion/css/macro';
import Data from '../UserData';
import { PureTextField } from '../../formaggio';
import { ResponsibilityAreaSelector } from '../../contact/components/ResponsibilityAreaSelector';
import { getValueByInputType } from '../../core/helpers';

const attendeeClass = css`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

export function InterviewEventInput({ onFocus, onChange, value = {}, disabled }: any) {
	const changeValue = React.useCallback(
		(e: any) => {
			const oldData = {
				description: value.description,
				areas: value.areas,
				jobTitle: value.jobTitle,
			};
			onChange({
				...oldData,
				[e.target.name]: getValueByInputType(e),
			});
		},
		[value.description, value.areas, value.jobTitle, onChange],
	);

	const changeArea = React.useCallback(
		(flags) => changeValue({ target: { name: 'areas', value: flags } }),
		[changeValue],
	);

	return (
		<div onMouseEnter={onFocus} className={attendeeClass}>
			<PureTextField
				fullWidth
				multiline
				name="description"
				label="Topics Covered"
				size="small"
				value={value.description || ''}
				onChange={changeValue}
				disabled={disabled}
				variant="outlined"
			/>
			<PureTextField
				fullWidth
				multiline
				name="jobTitle"
				label="Job Title(s)"
				size="small"
				value={value.jobTitle || ''}
				onChange={changeValue}
				disabled={disabled}
				variant="outlined"
			/>
			<ResponsibilityAreaSelector
				setActualAreas={changeArea}
				initialAreas={value.areas || 0}
				currentAreas={value.areas || 0}
				label="Role(s) of Interviewee(s)"
				disabled={disabled}
			/>
		</div>
	);
}

export default function InterviewEvent({ itemId, label, ...rest }: any) {
	return (
		<>
			<p>
				<small>
					If interview was not scheduled through the system, write it down in the fields below.
					<br />
					Workpaper notes can be added through workflow or interview, title is auto-assigned.
				</small>
			</p>
			<Data
				emptyValue=""
				retrievedValue={(e: any) => e}
				elemId={itemId}
				inputElement={InterviewEventInput}
				additional={{ label }}
				getData={({ ...values }: any) => ({
					...values,
				})}
				manualSave
				{...rest}
			/>
		</>
	);
}
