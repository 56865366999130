import OscalParameter from './OscalParameter';
import { RocDataType } from '../../enums';
import Data from '../../UserData';
import SimplifiedRadioField from '../../simplifiedControls/SimplifiedRadioField';

function ChoiceInput({ additional = {}, value, onChange }: any) {
	const { options = [] } = additional;

	return (
		<SimplifiedRadioField
			options={options.map(({ label }, idx) => ({ label, value: String(idx) }))}
			value={value && String(value.value)}
			onChange={onChange}
		/>
	);
}

const retrieveValue = (e: any) => ({
	value: e.target.value,
});

type OscalChoiceFieldProps = any;

export default function OscalChoiceField({ ...rest }: OscalChoiceFieldProps) {
	return (
		<OscalParameter {...rest}>
			<Data
				{...rest}
				retrievedValue={retrieveValue}
				inputElement={ChoiceInput}
				additional={{ options: rest.options }}
				rocDataType={RocDataType.RadioFlag}
				instantSave
			/>
		</OscalParameter>
	);
}
