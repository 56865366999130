import { css } from '@emotion/css/macro';
import { Warning } from '@mui/icons-material';
import { Box } from '@mui/material';
import { error } from '../../ui/Core/stylesheets/colors';

const iconClass = css`
	margin-right: 0.25rem;
`;

type ErrorMessageProps = { message?: string | boolean };

export default function ErrorMessage({ message }: ErrorMessageProps) {
	if (!message || typeof message === 'boolean') {
		return null;
	}

	return (
		<Box color={error} marginTop="4px" display="flex" alignItems="center">
			<Warning className={iconClass} />
			{message}
		</Box>
	);
}
