import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import AttachmentRow from './AttachmentRow';
import { AttachmentSharedProps } from './types';
import useAttachmentFilter from './AttachmentFilter';
import { searchTextFilter } from '../../shared/helpers';

export type AttachmentsListProps = Omit<AttachmentSharedProps, 'prefix'> & {
	attachments: any[];
	headerCells: React.ReactNode;
	pluralName: string;
};

export default function AttachmentsList({
	attachments = [],
	isForReference,
	onEditStart,
	refreshProjectAttachments,
	isItemApproved,
	additional = {},
	highlight,
	headerCells,
	pluralName = 'attachments',
	AttachmentComponent,
	AdditionalRowControls,
	type,
	attachmentName,
	getRowSpan,
	onSelected,
}: AttachmentsListProps) {
	const { filter, filterComponent } = useAttachmentFilter();

	const renderList = React.useMemo(
		() =>
			attachments
				.filter((att) =>
					filter.filter ? searchTextFilter(JSON.stringify(att), filter.filter) : true,
				)
				.map((attachment: any, idx: number) => (
					<AttachmentRow
						key={idx}
						attachment={attachment}
						PCId={additional?.PCId}
						isForReference={isForReference}
						isItemApproved={isItemApproved}
						onEditStart={onEditStart}
						refreshProjectAttachments={refreshProjectAttachments}
						highlight={highlight}
						AttachmentComponent={AttachmentComponent}
						AdditionalRowControls={AdditionalRowControls}
						type={type}
						attachmentName={attachmentName}
						getRowSpan={getRowSpan}
						onSelected={onSelected}
					/>
				)),
		[
			attachments,
			filter.filter,
			additional?.PCId,
			isForReference,
			isItemApproved,
			onEditStart,
			refreshProjectAttachments,
			highlight,
			AttachmentComponent,
			AdditionalRowControls,
			type,
			attachmentName,
			getRowSpan,
			onSelected,
		],
	);

	if (attachments.length === 0) return <div>No {pluralName} defined</div>;

	return (
		<div>
			{attachments.length > 1 && filterComponent}
			<Table size="small">
				<TableBody>
					<TableRow>
						<TableCell>Reference Number</TableCell>
						{headerCells}
					</TableRow>
					{renderList}
				</TableBody>
			</Table>
		</div>
	);
}
