import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { darkness, mainLight } from '../../ui/Core/stylesheets/colors';
import { getContrastText } from '../../ui/Core/helpers';

type TextOutlineProps = {
	children: React.ReactNode;
	secondary?: boolean;
	color?: string;
} & BoxProps;

function TextOutline({ children, secondary, color = mainLight, ...rest }: TextOutlineProps) {
	return (
		<Box
			fontWeight="300"
			color={secondary ? darkness : getContrastText(color)}
			fontSize="13px"
			padding="4px 6px"
			borderRadius="16px"
			bgcolor={secondary ? 'white' : color}
			marginLeft="8px"
			lineHeight="10px"
			border={secondary ? `1px solid ${darkness}` : undefined}
			{...rest}
		>
			{children}
		</Box>
	);
}

export default React.memo(TextOutline);
