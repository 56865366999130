import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { ContainerRocItemType, RocItemSubType } from '../../enums';
import { styles } from '../../constants/styles';
import ProjectEvidenceUploader from '../../../evidence/components/ProjectEvidenceUploader';
import { useReportContext } from '../../context/ReportContext';
import ProjectSampleSets from '../../../attachments/sampleSets/ProjectSampleSets';
import { ApproachContainerControls } from '../Requirement';
import { RequirementApproach } from '../../../core/types/api';
import { ReportType } from '../../constants';
import ContainerExport from './ContainerExport';
import { TableRow } from '../index';

const containerClass = css`
	flex: 1 1 auto;
`;

const cellClass = css`
	width: 25% !important;
	font-weight: bold !important;
	padding: 4px 16px;
`;

const tableFullWidthClass = css`
	width: 100%;
`;

function Section6Header() {
	return (
		<tr>
			<td
				className={cx(
					cellClass,
					css`
						${styles.rocLightGrey}
					`,
				)}
			>
				PCI DSS Requirements and Testing Procedures
			</td>
			<td
				className={cx(
					cellClass,
					css`
						${styles.rocGreen}
					`,
				)}
			>
				Reporting Instruction
			</td>
			<td
				className={cx(
					cellClass,
					css`
						${styles.rocLightGrey}
					`,
				)}
			>
				Reporting Details:
				<br />
				Assessor&apos;s Response
			</td>
			<td
				className={cx(
					cellClass,
					css`
						${styles.rocLightGrey}
					`,
				)}
			>
				Summary of Assessment Findings:
			</td>
		</tr>
	);
}

type ContainerProps = {
	itemId: string;
	projectId: string;
	children?: React.ReactNode;
	containerType?: ContainerRocItemType;
	subType: string;
	viewMode: boolean;
	requirementApproach: RequirementApproach;
	columnWidths: string;
};

function Container({
	itemId,
	projectId,
	children,
	containerType,
	subType,
	viewMode,
	columnWidths,
}: ContainerProps) {
	const { projectInfo = {} } = useReportContext();

	const colgroup = React.useMemo(() => {
		if (columnWidths !== undefined) {
			const widthArr = columnWidths.split(',').map((v) => +v);
			const totalWidth = widthArr.reduce((a, b) => a + b);
			const columns = widthArr.map((v, i) => (
				<col key={i} style={{ width: Math.floor((100 * v) / totalWidth) + '%' }} />
			));
			return <colgroup>{columns}</colgroup>;
		}

		return null;
	}, [columnWidths]);

	const tableClass = columnWidths !== undefined ? tableFullWidthClass : undefined;

	if (subType === RocItemSubType.ProjectSampleSets) return <ProjectSampleSets />;
	if (containerType === ContainerRocItemType.Empty) return <>{children}</>;
	if (containerType === ContainerRocItemType.Row) return <tr>{children}</tr>;
	if (containerType === ContainerRocItemType.Table)
		return (
			<>
				{subType === RocItemSubType.DocumentationReviewed && viewMode && (
					<ProjectEvidenceUploader />
				)}
				{subType === RocItemSubType.TargetedRiskAnalysis && (
					<ContainerExport containerId={itemId} projectId={projectId} />
				)}
				<table className={tableClass}>
					{colgroup}
					<tbody>
						{subType === RocItemSubType.Section6 &&
							(projectInfo.version === ReportType.PCI_DSS_v321 ||
								projectInfo.version === ReportType.PCI_DSS_v321r2) &&
							viewMode && <Section6Header />}
						{children}
					</tbody>
				</table>
			</>
		);
	return <div className={containerClass}>{children}</div>;
}

export default function ContainerWrapper({ requirementApproach, ...rest }: ContainerProps) {
	if (rest.containerType === ContainerRocItemType.Row) return <TableRow {...(rest as any)} />;

	return (
		<>
			{!!requirementApproach?.requirementId && (
				<ApproachContainerControls {...requirementApproach} />
			)}
			<Container requirementApproach={requirementApproach} {...rest} />
		</>
	);
}
